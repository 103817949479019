import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Theme } from '@material-ui/core/styles';
import { createRandomId } from '../../lib/randomId';
import { ICopiedBlocks, ISigner } from '../../store/senderBuild/types';
import { hex2rgba } from '../../lib/utils';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IRootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  copyLayer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 7,
  },
  block: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    zIndex: 8,
    opacity: 0.75,
    color: 'white',
  },
}));

const selector = (state: IRootState) => ({
  signers: state.senderBuild.signers,
  zoom: state.senderBuild.zoom,
  copiedBlocks: state.senderBuild.copiedBlocks,
  pageNumber: state.senderBuild.currentPage,
});

interface IState {
  signers: ISigner[];
  zoom: number;
  copiedBlocks: ICopiedBlocks;
  pageNumber: number;
}

interface IProps {
  documentId: string;
  pageIndex: number;
}

export default (props: IProps) => {
  const { signers, zoom, copiedBlocks, pageNumber }: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const layerRef = React.useRef<HTMLDivElement>(null);
  const [mousePos, setMousePos] = React.useState<{ x: number; y: number }>({ x: NaN, y: NaN });

  if (!copiedBlocks?.blocks) {
    return null;
  }

  const updateMousePosition = (e: React.MouseEvent<HTMLDivElement>) => {
    const bounds = layerRef.current!.getBoundingClientRect();
    setMousePos({
      x: e.clientX - bounds.left,
      y: e.clientY - bounds.top,
    });
  };

  const pasteBlocks = (e: React.MouseEvent<HTMLDivElement>) => {
    // TODO: make work for multiple blocks
    const blocks = copiedBlocks.blocks!.map((block) => {
      return {
        ...block,
        blockId: createRandomId(),
        pageNumber: pageNumber,
        x: mousePos.x / zoom,
        y: mousePos.y / zoom,
      };
    });
    dispatch(actions.pasteBlocks(props.documentId, props.pageIndex, blocks));
  };

  return (
    <div className={classes.copyLayer} onMouseMove={updateMousePosition} onClick={pasteBlocks} ref={layerRef}>
      {copiedBlocks.blocks!.map((block) => {
        const signer = signers.find((s) => s.signerId === block.assignedTo)!;
        return (
          <div
            key={signer ? signer!.signerId : ''}
            className={classes.block}
            style={{
              left: mousePos.x,
              top: mousePos.y,
              width: block.width! * zoom,
              height: block.height! * zoom,
              backgroundColor: signer ? hex2rgba(signer!.color!, 0.2) : '#000000',
              border: signer ? `1px solid ${signer!.color}` : 'none',
            }}
          />
        );
      })}
    </div>
  );
};
