import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as pageFrameActions from '../../pageFrame/actions';
import { primeApi } from '../../../lib/api/prime';
import { envelopeApi } from '../../../lib/api/envelope';
import { IRootState } from '../..';
import { IDocument } from './types';
import { IEnvelopeMetadata } from '../envelopes/types';
import { errorToast, setLoading } from '../../pageFrame/actions';
import { progressPercentageForDocuments } from '../../../lib/api/common/progressPercentage';

const ActionTypes = actions.ActionTypes;

const getEnvelopeId = (state: IRootState) => state.dtm.documents.envelopeId;

export function* handleFetch(action: ReturnType<typeof actions.fetch>) {
  const { envelopeId, disableLoading } = action.payload;
  try {
    if (!disableLoading) {
      yield put(setLoading(true));
    }
    const metadata: IEnvelopeMetadata = yield call(envelopeApi.getEnvelopeMetadata, envelopeId);
    yield put(pageFrameActions.updateRecipientColorCounter(metadata.recipientColorCounter));
    yield put(actions.setDocuments(metadata.documents));
    const fileDocuments = yield call(
      primeApi.getFileDocuments,
      metadata.fileType,
      metadata.listingGuid ?? metadata.saleGuid
    );
    const file: IFile = yield call(primeApi.getFile, metadata.fileType, metadata.listingGuid ?? metadata.saleGuid);
    yield put(actions.fetchDone(file, fileDocuments, metadata.documents));
  } catch (err) {
    yield put(errorToast('Failed to fetch documents, please refresh!'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleUploadDocument(action: ReturnType<typeof actions.uploadDocument>) {
  const { tempId: temporaryDocumentId, file } = action.payload;
  try {
    const envelopeId = yield select(getEnvelopeId);

    const res = yield call(envelopeApi.uploadDocumentToEnvelope, envelopeId, temporaryDocumentId, file);
    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Envelope Document POST: ${res.status}`);
    }
    const documents = yield call(envelopeApi.getEnvelopeDocumentsDTM, envelopeId);
    const addedDocument = documents.find((doc: IDocument) => doc.documentId === res.result.id);

    yield put(actions.uploadDocumentDone(action.payload.tempId, addedDocument!));
  } catch (err) {
    progressPercentageForDocuments[temporaryDocumentId].error = 'A problem occured, please try uploading again';
    yield put(actions.apiError('Error uploading Document'));
  }
}

export function* handleUploadPrimeDocument(action: ReturnType<typeof actions.uploadPrimeDocument>) {
  const { tempId: temporaryDocumentId, fileName, url } = action.payload;
  try {
    const envelopeId = yield select(getEnvelopeId);
    const res = yield call(envelopeApi.uploadPrimeDocumentToEnvelope, envelopeId, temporaryDocumentId, fileName, url);
    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Envelope Document POST: ${res.status}`);
    }

    const documents = yield call(envelopeApi.getEnvelopeDocumentsDTM, envelopeId);
    const addedDocument = documents.find((doc: IDocument) => doc.documentId === res.result.id);
    yield put(actions.uploadDocumentDone(action.payload.tempId, addedDocument!));
  } catch (err) {
    progressPercentageForDocuments[temporaryDocumentId].error = 'A problem occured, please try uploading again';
    yield put(actions.apiError('Error uploading Document'));
  }
}

export function* handleReorderDocuments(action: ReturnType<typeof actions.reorderDocuments>) {
  try {
    const documentIds = action.payload.map((document: IDocument) => document.documentId!);

    const envelopeId = yield select(getEnvelopeId);
    const envelopeConfig = {
      documentOrder: documentIds,
    };
    const res = yield call(envelopeApi.reorderDocumentsInEnvelope, envelopeId, envelopeConfig);

    if (res.status !== 200) {
      throw Error(`Non-201 Status Code of Envelope PATCH: ${res.status}`);
    }
  } catch (err) {
    yield put(actions.apiError('Error updating Document'));
  }
}

export function* handleDeleteDocument(action: ReturnType<typeof actions.deleteDocument>) {
  try {
    const envelopeId = yield select(getEnvelopeId);
    const res = yield call(envelopeApi.deleteDocument, envelopeId, action.payload);

    if (res.status !== 204) {
      throw Error(`Non-204 Status Code of Template Document DELETE: ${res.status}`);
    }
  } catch (err) {
    yield put(actions.apiError('Error deleting Document'));
  }
}

export function* handleFetchEnvelopeData(action: ReturnType<typeof actions.fetchEnvelopeData>) {
  try {
    const metadata: IEnvelopeMetadata = yield call(envelopeApi.getEnvelopeMetadata, action.payload.envelopeId);
    yield put(actions.setDocuments(metadata.documents));
  } catch (err) {
    yield put(errorToast('Failed to fetch documents, please refresh!'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH, handleFetch),
    takeEvery(ActionTypes.UPLOAD_DOCUMENT, handleUploadDocument),
    takeEvery(ActionTypes.UPLOAD_PRIME_DOCUMENT, handleUploadPrimeDocument),
    takeEvery(ActionTypes.REORDER_DOCUMENTS, handleReorderDocuments),
    takeEvery(ActionTypes.DELETE_DOCUMENT, handleDeleteDocument),
    takeEvery(ActionTypes.FETCH_ENVELOPE_DATA, handleFetchEnvelopeData),
  ]);
}
