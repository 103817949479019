import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import { IEnvelopeHistoryBody, IRecipientBody, IAuditHistoryBody } from '../../store/envelopeHistory/types';
import { IRootState } from '../../store';
import { getTimezoneCode, generateFileName } from '../../lib/utils';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { StyledMenu, StyledMenuItem } from '../common/StyledMenu';
import * as actions from '../../store/envelopeHistory/actions';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  summary: {
    flexGrow: 1,
  },
  divider: {
    borderLeft: '1px solid #D8D9DB',
    margin: '0px 15px 0px 15px',
    height: '40px',
  },
  gridItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  checkCircleIcon: {
    fontSize: '28px',
    paddingRight: theme.spacing(1),
  },
  listItem: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
  icon: {
    margin: '-4px 0 -4px 4px',
  },
}));

interface IState {
  isFetching: boolean;
  envelope: IEnvelopeHistoryBody;
  auditHistory: IAuditHistoryBody[];
}

const selector = (state: IRootState) => ({
  isFetching: state.envelopeHistory.isFetching,
  envelope: state.envelopeHistory.envelope,
  auditHistory: state.envelopeHistory.auditHistory,
});

export const RecipientList = () => {
  const store: IState = useShallowSelector(selector);
  const classes = useStyles();
  const { recipients } = store.envelope;

  const recipientsWithEnvelopeDownloadedEvents = store.auditHistory
    .filter((e) => e.eventName == 'EnvelopeDownloaded')
    .map((e) => e.userId);

  const handleStatusCheck = (r: IRecipientBody) => {
    return r.status === 'Complete' || (r.role == 'CarbonCopy' && recipientsWithEnvelopeDownloadedEvents.includes(r.id))
      ? '#47B676'
      : '#DEE5ED';
  };

  const handleSort = (firstRecipient: IRecipientBody, secondRecipient: IRecipientBody) => {
    const firstRole = firstRecipient.role;
    const secondRole = secondRecipient.role;

    if (firstRole > secondRole) return -1;
    if (firstRole < secondRole) return 1;
    return 0;
  };

  const sortedRecipients = [...recipients].sort(handleSort);

  return (
    <Container component="div" className={classes.container}>
      <Typography variant="body2">To</Typography>
      <List>
        {sortedRecipients.map((r: IRecipientBody) => (
          <ListItem key={r.id} className={classes.listItem}>
            <CheckCircleIcon
              data-spec="checkCircle"
              className={classes.checkCircleIcon}
              style={{ color: handleStatusCheck(r) }}
            />
            <Typography>
              {r.role === 'CarbonCopy' ? 'Cc: ' : ''}
              {r.firstName} {r.middleName} {r.lastName} ({r.email})
            </Typography>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export function formattedDateForHistory(dateString: string): string {
  try {
    const date = dayjs(dateString);
    return date.format('MMMM Do, YYYY h:mm A');
  } catch (e) {
    return '';
  }
}

export const SentDateAndSenderInfo = () => {
  const classes = useStyles();
  const store: IState = useShallowSelector(selector);
  const { senderFirstName, senderMiddleName, senderLastName, senderEmail, sentDate, completedDate } = store.envelope;
  const envelopeSentDate = formattedDateForHistory(sentDate);
  const envelopeCompletedDate = formattedDateForHistory(completedDate);
  const timeZone = getTimezoneCode();

  return (
    <>
      <Container component="div" className={classes.container}>
        {store.isFetching ? (
          ''
        ) : completedDate ? (
          <>
            <Typography variant="body2">Completed On</Typography>
            <Typography>
              {envelopeCompletedDate} {timeZone}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2">Sent On</Typography>
            <Typography>
              {envelopeSentDate} {timeZone}
            </Typography>
          </>
        )}
      </Container>
      <Container component="div" className={classes.container}>
        <Typography variant="body2">By</Typography>
        <Typography data-spec="senderName">
          {senderFirstName} {senderMiddleName} {senderLastName}
        </Typography>
        <Typography>({senderEmail})</Typography>
      </Container>
    </>
  );
};

export const ActionButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store: IState = useShallowSelector(selector);
  const params = useParams<{ id: string }>();
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null);
  const [printAnchorEl, setPrintAnchorEl] = React.useState(null);
  const canDownloadEnvelopeAndCert: string[] = ['sent', 'inprogress', 'completed'];
  const status = store.envelope.status.toLowerCase();
  const printFile: boolean = true;

  const handleDownloadClick = (event: any) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handlePrintClick = (event: any) => {
    setPrintAnchorEl(event.currentTarget);
  };

  const downloadEnvelope = (print?: boolean) => {
    const envelopeFileName = generateFileName(store.envelope.documents);
    dispatch(actions.downloadEnvelope(params.id, envelopeFileName, print));
  };

  const downloadCertificate = (print?: boolean) => {
    let certificateFileName = 'DigiSignAuditCertificate.pdf';
    dispatch(actions.downloadCertificate(params.id, certificateFileName, print));
  };

  return (
    <>
      <Button data-spec="print-button" variant="outlined" color="primary" onClick={handlePrintClick}>
        Print
        <ExpandMoreIcon className={classes.icon} />
      </Button>
      <StyledMenu
        anchorEl={printAnchorEl}
        open={Boolean(printAnchorEl)}
        onClose={() => setPrintAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
      >
        {canDownloadEnvelopeAndCert.includes(status) ? (
          <>
            <StyledMenuItem onClick={() => downloadEnvelope(printFile)}>All Documents</StyledMenuItem>
            <StyledMenuItem onClick={() => downloadCertificate(printFile)}>Certificate</StyledMenuItem>
          </>
        ) : (
          <StyledMenuItem onClick={() => downloadEnvelope(printFile)}>All Documents</StyledMenuItem>
        )}
      </StyledMenu>
      <div className={classes.divider}></div>
      <Button data-spec="download-button" variant="outlined" color="primary" onClick={handleDownloadClick}>
        Download
        <ExpandMoreIcon className={classes.icon} />
      </Button>
      <StyledMenu
        anchorEl={downloadAnchorEl}
        open={Boolean(downloadAnchorEl)}
        onClose={() => setDownloadAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
      >
        {canDownloadEnvelopeAndCert.includes(status) ? (
          <>
            <StyledMenuItem onClick={() => downloadEnvelope()}>All Documents</StyledMenuItem>
            <StyledMenuItem onClick={() => downloadCertificate()}>Certificate</StyledMenuItem>
          </>
        ) : (
          <StyledMenuItem onClick={() => downloadEnvelope()}>All Documents</StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
};

export const Summary = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.actionButtons}>
        <Typography className={classes.summary} variant="overline">
          Summary
        </Typography>
        <ActionButtons></ActionButtons>
      </div>
      <Paper square variant="outlined" className={classes.paper}>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <SentDateAndSenderInfo />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <RecipientList />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Summary;
