// This needs to eventually be moved into mache

import React from 'react';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const Styled = withStyles({
  root: {
    border: 0,
    paddingRight: 32,
    maxWidth: 400,
  },
  disabled: {
    backgroundColor: 'inherit !important',
  },
})(Select);

export default (props) => {
  const { children, onChangeCallback, ...rest } = props;

  const handleChange = (e) => {
    if (onChangeCallback) {
      onChangeCallback(e.target.value);
    }
  };

  return (
    <Styled onChange={handleChange} {...rest}>
      {props.children}
    </Styled>
  );
};
