import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '@skyslope/mache';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Table from '../common/Table';
import Spinner from '../common/Spinner';
import { IRootState } from '../../store';
import { concatObject, getRecipientGroup } from '../../lib/utils';
import { signingGroupOrder } from '../../lib/constants';
import { useShallowSelector } from '../../lib/reduxHooks';
import { hideAlert } from '../../store/pageFrame/actions';
import { sendEmail } from '../../store/envelopeHistory/actions';
import { fetchContacts } from '../../store/senderBuild/actions';
import EditRecipientForm from './EditRecipientForm';
import { IEnvelopeHistoryBody, IRecipientBody, IGroupDetails } from '../../store/envelopeHistory/types';
import { Alert } from '../common/Alert';
import { IAlert } from '../../store/pageFrame/types';
import { checkIfRecipientIsSender } from '../../common/utils';
import { Modal, ModalText, ModalTitle } from '../../common/Modal';
import { IContact } from '../../lib/types';
import { LaunchDarklyFlags } from '../../common/launchDarkly';

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    border: 'none',
    paddingBottom: '40px',
    paddingTop: '20px',
  },
  tableCell: {
    padding: '6px 0px',
  },
  recipientWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '500px',
  },
  recipientName: {
    paddingRight: '16px',
    fontWeight: 'bold',
  },
  signingGroup: {
    minWidth: '130px',
  },
  reminderAction: {
    textAlign: 'right',
    minWidth: '170px',
  },
}));

interface IState {
  isFetching: boolean;
  envelope: IEnvelopeHistoryBody;
  alert: IAlert;
  lastRecipient: string;
  contacts: IContact[];
}

const selector = (state: IRootState) => ({
  isFetching: state.envelopeHistory.isFetching,
  envelope: state.envelopeHistory.envelope,
  alert: state.pageFrame.alert,
  lastRecipient: state.envelopeHistory.lastRecipient,
  contacts: state.senderBuild.recipientsModal.contacts,
});

const handleGroup = (order?: number) => {
  return order ? signingGroupOrder[order] : signingGroupOrder[0];
};

type IProps = { close: () => void; flags: LaunchDarklyFlags };

const ResendModal = (props: IProps) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const { envelope, isFetching, lastRecipient, alert, contacts }: IState = useShallowSelector(selector);
  const [disabledReminders, setDisabledReminders] = React.useState<string[]>([]);
  const [recipientToEdit, setRecipientToEdit] = React.useState<null | IRecipientBody>(null);
  const [disableSenderInput, setDisableSenderInput] = React.useState(false);
  const { activeGroup } = envelope.signerDetails!;
  const { recipients } = envelope;
  useEffect(() => {
    if (!contacts.length) {
      dispatch(fetchContacts());
    }
    return () => {
      if (recipientToEdit) {
        setRecipientToEdit(null);
      }
    };
  }, []);

  React.useEffect(() => {
    if (alert.visible) {
      setDisabledReminders(disabledReminders.filter((r) => r != lastRecipient));
      document.getElementById('alert')!.focus();
      document.getElementById('alert')!.tabIndex = 1;
      document.getElementById('alert')!.style.outline = 'none';
      setTimeout(() => {
        dispatch(hideAlert());
      }, 4000);
    }
  }, [alert.visible]);

  const recipientsInProgress = recipients
    .filter((r: IRecipientBody) => r.status === 'InProgress')
    .map((r: IRecipientBody) => ({ ...r, order: getRecipientGroup(r, envelope)?.order || 0 }))
    .sort((a: IRecipientBody, b: IRecipientBody) => a.order! - b.order!);

  const truncateText = (text: string, length = 16) => (text.length > length ? `${text.substring(0, length)}...` : text);

  const sendReminder = (r: IRecipientBody) => {
    dispatch(
      sendEmail(envelope.id, {
        recipient: {
          recipientId: r.id,
        },
        emailType: 'ReviewAndSign',
        status: 'Sent',
        isResend: 'true',
      })
    );
    const disabledRemindersCopy = [...disabledReminders, r.id];
    setDisabledReminders(disabledRemindersCopy);
  };

  const handleEditRecipient = (r: IRecipientBody) => {
    const isSender = checkIfRecipientIsSender(r);
    if (isSender) {
      setDisableSenderInput(true);
    } else setDisableSenderInput(false);
    setRecipientToEdit(r);
  };

  const reminderButton = (r: IRecipientBody) => {
    const sentReminder = disabledReminders.includes(r.id);
    return (
      <Button
        classes={{ root: sentReminder ? 'py-[7px] px-[22px] rounded-[30px]' : 'py-[7px] px-5 rounded-[30px]' }}
        data-spec="reminderButton"
        disabled={sentReminder}
        color="primary"
        variant={sentReminder ? 'text' : 'outlined'}
        onClick={() => sendReminder(r)}
        startIcon={
          sentReminder ? <CheckCircleOutlineIcon className="text-2xl" /> : <NotificationsIcon className="text-2xl" />
        }
      >
        <span className="w-28">{sentReminder ? 'Reminder Sent' : 'Send Reminder'}</span>
      </Button>
    );
  };

  const checkIfActiveSigner = (r: IRecipientBody) => {
    const group = envelope.signerDetails?.details?.find((d: IGroupDetails) => d.value.signerId === r.id);
    return group?.value.signingGroupId === activeGroup;
  };

  const resendColumns = [
    {
      key: 'recipients',
      label: 'Recipients who have not yet signed',
      cell: (r: IRecipientBody) => (
        <Typography className={classes.recipientWrapper}>
          <span className={classes.recipientName}>
            {truncateText(concatObject(r))} {checkIfRecipientIsSender(r) ? '(Me)' : ''}
          </span>
          {truncateText(r.email ?? '', 28)}{' '}
          <IconButton
            data-spec={`edit-recipient`}
            onClick={() => handleEditRecipient(r)}
            style={{ color: colors.grey[500] }}
          >
            <EditIcon />
          </IconButton>
        </Typography>
      ),
    },
    {
      key: 'signingGroup',
      label: 'Signing group',
      cell: (r: IRecipientBody) => (
        <Typography className={classes.signingGroup}>{`${handleGroup(r.order || 0).toUpperCase()} GROUP`}</Typography>
      ),
    },
    {
      key: 'reminder',
      label: ' ',
      cell: (r: IRecipientBody) => (
        <Typography className={classes.reminderAction}>{checkIfActiveSigner(r) ? reminderButton(r) : ' '}</Typography>
      ),
    },
  ];

  const content = (
    <>
      <ModalText>Send a reminder or correct an email address for recipients who have not yet signed.</ModalText>
      {alert.visible ? <Alert id="alert" type={alert.type} message={alert.message} /> : null}
      {recipientToEdit ? (
        <EditRecipientForm
          recipient={recipientToEdit}
          setRecipientToEdit={setRecipientToEdit}
          disabled={disableSenderInput}
          contacts={contacts}
        />
      ) : (
        <Table
          className={classes.tableWrapper}
          cellClassName={classes.tableCell}
          idKey="id"
          columns={resendColumns}
          rows={recipientsInProgress}
        />
      )}
    </>
  );

  if (isFetching) {
    return (
      <Dialog open>
        <Spinner />
      </Dialog>
    );
  }

  return (
    <Modal
      modalStyles={{
        maxWidth: '880px',
        minWidth: '880px',
        maxHeight: 'none',
        padding: '8px 0',
        buttonColor: colors.grey[500],
        closeButtonStyle: { height: '40px', width: '40px', marginTop: '8px', marginRight: '16px' },
        actionButtonsStyle: { paddingBottom: '0' },
      }}
      toggleModal={props.close}
      title={<ModalTitle>Resend envelope</ModalTitle>}
      content={content}
    />
  );
};

export default ResendModal;
