import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { envelopeApi } from '../../lib/api/envelope/';
import { setLoading } from '../pageFrame/actions';
import { IEnvelope, IDocument, DocumentStatus } from '../senderBuild/types';
import * as actions from './actions';
import * as pageFrameActions from '../pageFrame/actions';
import pdfCache from '../../lib/pdfCache';
import { errorToast } from '../pageFrame/actions';
import { IRootState } from '../index';
import { progressPercentageForDocuments } from '../../lib/api/common/progressPercentage';

const ActionTypes = actions.ActionTypes;

const getEnvelopeId = (state: IRootState) =>
  state.envelopeDocuments.envelope ? state.envelopeDocuments.envelope.envelopeId : undefined;

export function* handleFetchEnvelope(action: ReturnType<typeof actions.fetchEnvelope>) {
  try {
    const { id } = action.payload;
    yield put(setLoading(true));
    const res: envelopeApi.IResponse<IEnvelope> = yield call(envelopeApi.getEnvelopeById, id);
    const envelope = res.result!;
    if (res.statusCode !== 200) {
      throw Error(`Non-200 Status Code of Envelope GET: ${res.statusCode}`);
    } else if (envelope.status !== 'Draft' && envelope.status !== 'Correcting') {
      throw Error('Envelope already sent');
    }

    yield put(pageFrameActions.updateRecipientColorCounter(envelope.recipientColorCounter));
    yield put(actions.fetchEnvelopeDone(envelope));
  } catch (err) {
    yield put(errorToast('Failed to fetch the envelope information, please refresh!'));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleUploadDocument(action: ReturnType<typeof actions.uploadDocument>) {
  const { tempId: temporaryDocumentId, file } = action.payload;
  try {
    const envelopeId = yield select(getEnvelopeId);

    const res = yield call(envelopeApi.uploadDocumentToEnvelope, envelopeId, temporaryDocumentId, file);
    // Future work - This is a subsequent request to get the updated documents. This should instead be the response of the request above.
    const getRes = yield call(envelopeApi.getEnvelopeById, envelopeId);
    const documents = getRes.result.documents;

    const addedDocument = documents.find((doc: { documentId: string }) => doc.documentId === res.result.id);

    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Envelope Document POST: ${res.status}`);
    }
    yield put(actions.uploadDocumentDone(action.payload.tempId, addedDocument!));
  } catch (err) {
    progressPercentageForDocuments[temporaryDocumentId].error = 'A problem occured, please try uploading again';
    yield put(actions.apiError('Error uploading Document'));
  }
}

export function* handleReorderDocuments(action: ReturnType<typeof actions.reorderDocuments>) {
  try {
    const documentIds = action.payload.map((document: IDocument) => document.documentId!);

    const envelopeId = yield select(getEnvelopeId);
    const envelopeConfig = {
      documentOrder: documentIds,
    };
    const res = yield call(envelopeApi.reorderDocumentsInEnvelope, envelopeId, envelopeConfig);

    if (res.status !== 201) {
      throw Error(`Non-201 Status Code of Envelope PATCH: ${res.status}`);
    }
  } catch (err) {
    yield put(actions.apiError('Error updating Document'));
  }
}

export function* handleDeleteDocument(action: ReturnType<typeof actions.deleteDocument>) {
  try {
    const envelopeId = yield select(getEnvelopeId);
    const res = yield call(envelopeApi.deleteDocument, envelopeId, action.payload);

    if (res.status !== 204) {
      throw Error(`Non-204 Status Code of Template Document DELETE: ${res.status}`);
    }
  } catch (err) {
    yield put(actions.apiError('Error deleting Document'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_ENVELOPE, handleFetchEnvelope),
    takeEvery(ActionTypes.UPLOAD_DOCUMENT, handleUploadDocument),
    takeEvery(ActionTypes.REORDER_DOCUMENTS, handleReorderDocuments),
    takeEvery(ActionTypes.DELETE_DOCUMENT, handleDeleteDocument),
  ]);
}
