import { action } from 'typesafe-actions';
import { OfficeUser, UserOffice } from '../../lib/api/prime/prime';
import { ITemplate } from '../templateCreate/types';
import { IShareRecord, ITemplateUser, SubGroup } from './types';

export enum ActionTypes {
  RESET = 'templateSharing/RESET',
  FETCH_USERS = 'templateSharing/FETCH_USERS',
  FETCH_USERS_DONE = 'templateSharing/FETCH_USERS_DONE',
  FETCH_USERS_GROUPS_DONE = 'templateSharing/FETCH_USERS_GROUPS_DONE',
  FETCH_USERS_ERROR = 'templateSharing/FETCH_USERS_ERROR',
  TOGGLE_TEMPLATE_SHARE_TO_USER = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_USER',
  TOGGLE_TEMPLATE_SHARE_TO_USER_DONE = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_USER_DONE',
  TOGGLE_TEMPLATE_SHARE_TO_USER_ERROR = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_USER_ERROR',
  TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP',
  TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP_DONE = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP_DONE',
  TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS',
  TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS_DONE = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS_DONE',
  FETCH_TEMPLATE_SHARE_INFORMATION = 'templatesharing/FETCH_TEMPLATE_SHARE_INFORMATION',
  FETCH_TEMPLATE_SHARE_INFORMATION_DONE = 'templateSharing/FETCH_TEMPLATE_SHARE_INFORMATION_DONE',
  TOGGLE_GROUPS_SORT = 'templateSharing/TOGGLE_GROUPS_SORT',
  TOGGLE_OFFICE_SORT = 'templateSharing/TOGGLE_OFFICE_SORT',
  FETCH_TEMPLATE_SHARE_OFFICES = 'templateSharing/FETCH_TEMPLATE_SHARE_OFFICES',
  FETCH_TEMPLATE_SHARE_OFFICES_DONE = 'templateSharing/FETCH_TEMPLATE_SHARE_OFFICES_DONE',
  TOGGLE_TEMPLATE_SHARE_TO_OFFICE = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_OFFICE',
  TOGGLE_TEMPLATE_SHARE_TO_OFFICE_DONE = 'templateSharing/TOGGLE_TEMPLATE_SHARE_TO_OFFICE_DONE',
  FETCHING_OFFICE_USERS = 'templateSharing/FETCHING_OFFICE_USERS',
  FETCH_OFFICE_USERS = 'templateSharing/FETCH_OFFICE_USERS',
  FETCH_OFFICE_USERS_DONE = ' templateSharing/FETCH_OFFICE_USERS_DONE',
  UPDATE_USERS = 'templateSharing/UPDATE_USERS',
}

export const reset = () => action(ActionTypes.RESET, {});
export const fetchUsers = (limit: number, skip: number, search: string, fromScroll?: boolean) =>
  action(ActionTypes.FETCH_USERS, { limit, skip, search, fromScroll });
export const fetchUsersDone = (
  users: ITemplateUser[],
  fromSearch?: boolean,
  totalCount?: number,
  search?: string,
  fromScroll?: boolean
) => action(ActionTypes.FETCH_USERS_DONE, { users, fromSearch, totalCount, search, fromScroll });
export const fetchUsersGroupsDone = (
  sharedToAllParentGroups: boolean,
  parentGroups: string[],
  subGroups: SubGroup[],
  error?: boolean
) => action(ActionTypes.FETCH_USERS_GROUPS_DONE, { sharedToAllParentGroups, parentGroups, subGroups, error });
export const fetchUsersError = (error: string) => action(ActionTypes.FETCH_USERS_ERROR, { error });
export const toggleTemplateShareToUser = (
  user: ITemplateUser,
  templateName: string,
  templateId: string,
  sharedToAllParentGroups: boolean,
  sharedToASubGroup: boolean,
  sharedToAnOffice: boolean
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_USER, {
    user,
    templateName,
    templateId,
    sharedToAllParentGroups,
    sharedToASubGroup,
    sharedToAnOffice,
  });
export const toggleTemplateShareToUserDone = (
  user: ITemplateUser,
  sharedToAllParentGroups: boolean,
  sharedToASubGroup: boolean,
  error?: boolean
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_USER_DONE, { user, sharedToAllParentGroups, sharedToASubGroup, error });
export const toggleTemplateShareToSubGroup = (
  template: ITemplate,
  group: SubGroup,
  alreadyShared: boolean,
  sharedToAllParentGroups: boolean,
  subGroups: SubGroup[],
  sharedIds: IShareRecord[]
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP, {
    template,
    group,
    alreadyShared,
    sharedToAllParentGroups,
    subGroups,
    sharedIds,
  });
export const toggleTemplateShareToSubGroupDone = (
  groupId: string,
  sharedToAllParentGroups: boolean,
  error: boolean,
  usersSharedByOffice?: { oktaUserID: string }[]
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP_DONE, {
    groupId,
    sharedToAllParentGroups,
    error,
    usersSharedByOffice,
  });
export const toggleTemplateShareToAllUsersGroups = (
  alreadyShared: boolean,
  templateId: string,
  officeSharingEnabled: boolean,
  offices: UserOffice[]
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS, {
    alreadyShared,
    templateId,
    officeSharingEnabled,
    offices,
  });
export const toggleShareTemplateToAllUsersGroupsDone = (alreadyShared: boolean, error?: boolean) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS_DONE, { alreadyShared, error });
export const fetchTemplateShareInformation = (
  limit: number,
  skip: number,
  search: string,
  templateId: string,
  oktaUserId: string,
  fetchOffices: boolean
) =>
  action(ActionTypes.FETCH_TEMPLATE_SHARE_INFORMATION, {
    limit,
    skip,
    search,
    templateId,
    oktaUserId,
    fetchOffices,
  });
export const fetchTemplateShareInformationDone = (
  users: ITemplateUser[],
  totalUsers: number,
  shares: IShareRecord[],
  userExclusions: string[],
  offices: UserOffice[],
  usersFromOffices: OfficeUser[]
) =>
  action(ActionTypes.FETCH_TEMPLATE_SHARE_INFORMATION_DONE, {
    users,
    totalUsers,
    shares,
    userExclusions,
    offices,
    usersFromOffices,
  });
export const toggleGroupsSort = () => action(ActionTypes.TOGGLE_GROUPS_SORT);
export const toggleOfficesSort = () => action(ActionTypes.TOGGLE_OFFICE_SORT);

export const fetchTemplateShareOffices = () => action(ActionTypes.FETCH_TEMPLATE_SHARE_OFFICES);
export const fetchTemplateShareOfficesDone = (offices: UserOffice[]) =>
  action(ActionTypes.FETCH_TEMPLATE_SHARE_OFFICES_DONE, { offices });
export const toggleTemplateShareToOffice = (
  officeGuid: string,
  officeName: string,
  templateId: string,
  templateName: string,
  alreadyShared: boolean,
  sharedIds: IShareRecord[]
) => {
  return action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_OFFICE, {
    officeGuid,
    officeName,
    templateId,
    templateName,
    alreadyShared,
    sharedIds,
  });
};
export const toggleTemplateShareToOfficeDone = (
  alreadyShared: boolean,
  officeGuid: string,
  usersInOffice: OfficeUser[],
  isAllOffice: boolean
) =>
  action(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_OFFICE_DONE, { alreadyShared, officeGuid, usersInOffice, isAllOffice });
export const fetchingOfficeUsers = () => action(ActionTypes.FETCHING_OFFICE_USERS);
export const fetchOfficeUsers = (officeGuids: string[]) => action(ActionTypes.FETCH_OFFICE_USERS, { officeGuids });
export const fetchOfficeUsersDone = (officeUsers: OfficeUser[]) =>
  action(ActionTypes.FETCH_OFFICE_USERS_DONE, { officeUsers });
export const updateUsers = (users: ITemplateUser[]) => action(ActionTypes.UPDATE_USERS, { users });
