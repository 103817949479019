import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@skyslope/mache';
import { useHistory } from 'react-router-dom';

const modalStyles = makeStyles((theme: Theme) => ({
  modal: (props: IProps) => ({
    display: 'flex',
    justifyContent: props.modalStyles?.justifyContent || 'space-around',
    overflow: 'hidden',
    minHeight: props.modalStyles?.minHeight || 'none',
    maxHeight: props.modalStyles?.maxHeight || '350px',
    maxWidth: props.modalStyles?.maxWidth || '600px',
    minWidth: props.modalStyles?.minWidth || '600px',
    ...props.modalStyles,
  }),
  modalTitle: (props: IProps) => ({
    textAlign: 'left',
    color: colors.grey[800],
    padding: props.modalStyles?.titlePadding || '0px 40px 15px 40px',
  }),
  closeButtonWrapper: (props: IProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  closeButton: (props: IProps) => ({
    color: props.modalStyles?.buttonColor || 'default',
    ...props.modalStyles?.closeButtonStyle,
  }),
  modalConfirmationButton: (props: IProps) => ({
    padding: '25px',
  }),
  actionButtons: (props: IProps) => ({
    justifyContent: 'flex-end',
    paddingBottom: '40px',
    paddingRight: '40px',
    ...props.modalStyles?.actionButtonsStyle,
  }),
  modalContent: (props: IProps) => ({
    textAlign: 'left',
    color: colors.grey[800],
    flex: '0 0 auto',
    padding: '0px 20px',
    ...props.modalStyles?.modalContentStyle,
  }),
}));

const useStyles = makeStyles((theme: Theme) => ({
  modalText: {
    padding: 0,
    margin: 0,
  },
  modalCancelButton: {
    marginRight: '24px',
  },
}));

export const ModalText = (props: { children: any }) => {
  const classes = useStyles();
  return <p className={classes.modalText}> {props.children} </p>;
};

export const ModalTitle = (props: { children: any; customStyle?: {} }) => {
  return (
    <Typography style={props.customStyle} variant="h3">
      {' '}
      {props.children}{' '}
    </Typography>
  );
};

export const ModalCancelButton = (props: {
  children: any;
  onClick: () => void;
  'aria-label': string;
  isMobileOrSmallScreen?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  id?: string;
}) => {
  const classes = useStyles();
  return (
    <Button
      className={props.isMobileOrSmallScreen ? '' : classes.modalCancelButton}
      onClick={props.onClick}
      color="primary"
      aria-label={props['aria-label']}
      variant={props.variant ?? 'text'}
      id={props.id}
    >
      {props.children}
    </Button>
  );
};

export const ModalConfirmationButton = (props: {
  children: any;
  onClick: () => void;
  'aria-label': string;
  buttonStyle?: {};
  disabled?: boolean;
  id?: string;
}) => {
  return (
    <Button
      style={props.buttonStyle}
      onClick={props.onClick}
      color="primary"
      variant="contained"
      data-spec="modal-confirmation-button"
      aria-label={props['aria-label']}
      disabled={props.disabled}
      id={props.id}
    >
      {props.children}
    </Button>
  );
};

type IProps = {
  toggleModal?: () => void;
  hideCloseButton?: boolean;
  title: any;
  content: any;
  actionButtons?: any;
  isMobileOrSmallScreen?: boolean;
  modalStyles?: any;
  backdropComponent?: any;
  modalContainerClassName?: string;
  dialogClassName?: string;
};

export const Modal = (props: IProps) => {
  const modalClass = modalStyles(props);
  const history = useHistory();
  const navigatedToSubscriptionsPage = history.location.state?.data?.navigatedToSubscriptions;

  const handleBackClick = () => {
    if (window.history.length >= 3 && !navigatedToSubscriptionsPage) {
      window.history.go(-2);
    } else {
      window.history.go(-1);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      data-spec="modal-common"
      open
      onClose={props.toggleModal}
      onClick={(e) => e.stopPropagation()}
      classes={{ paper: modalClass.modal }}
      fullScreen={props.isMobileOrSmallScreen}
      BackdropComponent={props.backdropComponent}
      BackdropProps={{ style: { backgroundColor: 'rgba(63, 91, 119, .5)' } }}
      className={props.dialogClassName}
    >
      <div className={`${props.modalContainerClassName}`}>
        {!!props.backdropComponent && (
          <Button
            color="primary"
            classes={{ root: 'max-w-[130px] mt-0 mr-0 ml-4 mb-6' }}
            onClick={handleBackClick}
            startIcon={<ChevronLeftIcon />}
          >
            Go Back
          </Button>
        )}
        {props.hideCloseButton ? (
          ''
        ) : (
          <div className={modalClass.closeButtonWrapper ?? ''}>
            <IconButton
              data-spec="close"
              onClick={props.toggleModal}
              aria-label="close dialog"
              className={modalClass.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <DialogTitle className={modalClass.modalTitle}>{props.title}</DialogTitle>
        <DialogContent className={modalClass.modalContent}>
          <DialogContentText className={modalClass.modalContent}>{props.content}</DialogContentText>
        </DialogContent>
        <DialogActions disableSpacing={props.isMobileOrSmallScreen} className={modalClass.actionButtons}>
          {props.actionButtons}
        </DialogActions>
      </div>
    </Dialog>
  );
};
