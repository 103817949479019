import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Label } from '@material-ui/icons';
import { colors } from '@skyslope/mache';

type Props = {
  firstName: string;
  lastName: string;
  color: string;
  isSender?: boolean;
  title?: string;
  isPlaceholder?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingRight: theme.spacing(2),
  },
  recipientContainer: {
    display: 'flex',
    flexFlow: 'row',
    flexGrow: 1,
  },
  title: {
    fontSize: '14px',
    color: `${colors.grey[800]}`,
  },
  spacer: {
    color: `${colors.grey[500]}`,
    margin: '0px 7px',
  },
}));

const Recipient = (props: Props) => {
  const { firstName, lastName, color, isSender, title, isPlaceholder } = props;
  const classes = useStyles();
  const recipientName = firstName ? firstName.concat(' ', lastName || '') : title;
  const truncateLongName = (name: string) => (name?.length > 22 ? `${name.substring(0, 22)}...` : name);

  return (
    <div className={classes.recipientContainer} data-spec="list-recipient" tabIndex={0}>
      <Label data-spec={`${firstName}-${lastName}-label`} className={classes.icon} style={{ color }} />
      <Typography
        component="span"
        aria-label={`${firstName || ''} ${lastName || ''}`}
        style={{ display: 'flex' }}
        variant="body1"
      >
        <span style={{ fontWeight: 'bold' }}>{truncateLongName(recipientName)}</span>
        {title || isSender ? (
          <>
            {!isPlaceholder ? (
              <>
                <span className={classes.spacer}>|</span>
                <span aria-label={`${title}`} className={classes.title}>
                  {isSender ? 'ME' : title.toUpperCase()}
                </span>{' '}
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </Typography>
    </div>
  );
};

export default Recipient;
