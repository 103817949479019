import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import Typography from '@material-ui/core/Typography';
import { BLOCK_TYPES, BLOCK_TYPE_KEYS, readOnlyKey } from '../../../lib/constants';
import Close from '@material-ui/icons/Close';
import BlockActions from './BlockActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextBox from '../TextBox';
import CheckboxGroupControls from '../CheckboxGroupControls';
import { createRandomId } from '../../../lib/randomId';
import { useShallowSelector, useDispatch } from '../../../lib/reduxHooks';
import * as actions from '../../../store/senderBuild/actions';
import { ReactComponent as AlignLeft } from '../../../images/align_left.svg';
import { ReactComponent as AlignRight } from '../../../images/align_right.svg';
import { ReactComponent as AlignTop } from '../../../images/align_top.svg';
import { ReactComponent as AlignBottom } from '../../../images/align_bottom.svg';
import { IRootState } from '../../../store';
import {
  IBlocks,
  ISelectedBlocks,
  ISigner,
  IGroup,
  IBlock,
  IPageDimensions,
  IGroups,
  ICopyCache,
} from '../../../store/senderBuild/types';
import BlockProperties from './BlockProperties';
import { getMultipleBlockProperties } from '../../../lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  sections: {
    overflowY: 'auto',
    flexGrow: 1,
  },
  close: {
    color: colors.grey[500],
    padding: theme.spacing(0.5),
    margin: theme.spacing(1),
  },
  section: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  properties: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3.5)}px`,
  },
  propertyLabel: {
    color: colors.blue[800],
    width: '100%',
    margin: `${theme.spacing(1.5)}px 0`,
  },
  text: {
    margin: 0,
  },
  signerSelectItem: {
    display: 'flex',
  },
  signerTag: {
    marginRight: theme.spacing(1),
  },
  footer: {
    padding: theme.spacing(3),
    borderTop: `1px solid ${colors.grey[400]}`,
    width: `calc(100% - ${theme.spacing(6)}px)`,
  },
  alignmentButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  creatingBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.75)',
  },
}));

interface IState {
  activeSigner: string;
  signers: ISigner[];
  blocks: IBlocks;
  groups: IGroups;
  copyCache: ICopyCache;
  selectedBlocks: ISelectedBlocks;
  pageDimensions: IPageDimensions;
  zoom: number;
  id: string;
  currentDocument: string;
  currentPage: number;
}

const blockShelfSelector = (state: IRootState) => {
  const { activeSigner, signers, blocks, groups, copyCache, selectedBlocks, pageDimensions, zoom, id } =
    state.senderBuild;
  return {
    activeSigner,
    signers,
    blocks,
    groups,
    copyCache,
    selectedBlocks,
    pageDimensions,
    zoom,
    id,
  };
};

const BlockShelf = () => {
  const [sameBlockTypes, setSameBlockTypes] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { id, activeSigner, signers, blocks, groups, selectedBlocks, pageDimensions, zoom, copyCache }: IState =
    useShallowSelector(blockShelfSelector);
  const selectedPageBlocks = blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!];
  const numberOfSelectedBlocks = selectedBlocks.blockIndices.length;
  const firstBlock = selectedPageBlocks[selectedBlocks.blockIndices[0]]!;
  const hasReadOnlyBlocks = selectedBlocks.blockIndices.map((i) => selectedPageBlocks[i]).some((b) => b.readOnly);
  const blockType = BLOCK_TYPES.find((bt) => bt.key === firstBlock.blockType)!;

  useEffect(() => {
    if (selectedBlocks.blockIndices.length <= 1) {
      setSameBlockTypes(false);
      return;
    }
    for (let i = 0; i < selectedBlocks.blockIndices.length; i++) {
      if (firstBlock.blockType !== selectedPageBlocks[selectedBlocks.blockIndices[i]].blockType) {
        setSameBlockTypes(false);
        return;
      }
    }
    setSameBlockTypes(true);
  }, [selectedBlocks, selectedPageBlocks, firstBlock]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    if (copyCache.blocks.length > 0) {
      setIsButtonDisabled(false);
      return;
    }
    setIsButtonDisabled(true);
  }, [copyCache.blocks]);

  const changeProperty = (key: string, value: any) => {
    selectedBlocks.blockIndices.forEach((index, i) => {
      const block = selectedPageBlocks[index];
      const update = {
        [key]: value,
      };
      if (key === readOnlyKey) {
        update.assignedTo = value ? null : block.previouslyAssignedTo || activeSigner;
        if (value) {
          update.required = false;
          update.previouslyAssignedTo = block.assignedTo;
        }
      }
      dispatch(
        actions.editBlock(selectedBlocks.documentId!, selectedBlocks.pageIndex!, selectedBlocks.blockIndices[i], update)
      );
    });
  };

  const changeProperties = (update: any) => {
    selectedBlocks.blockIndices.forEach((index, i) => {
      dispatch(
        actions.editBlock(selectedBlocks.documentId!, selectedBlocks.pageIndex!, selectedBlocks.blockIndices[i], update)
      );
    });
  };

  const stopEditing = () => {
    dispatch(actions.selectBlocks(selectedBlocks.documentId!, selectedBlocks.pageIndex!, []));
  };

  const addMoreCheckboxes = (checkboxesToAdd: number = 1) => {
    const { documentId, pageIndex } = selectedBlocks;
    const allSelectedBlocks = selectedBlocks.blockIndices.map((index) => blocks[documentId!][pageIndex!][index]);
    const allBlocksHaveTheSameSigner = allSelectedBlocks.every(
      (block) => block.assignedTo === allSelectedBlocks[0].assignedTo
    );
    const largestX = Math.max(...allSelectedBlocks.map((b) => b.x!));
    const largestY = Math.max(...allSelectedBlocks.map((b) => b.y!));
    const smallestFontSize = Math.min(...allSelectedBlocks.map((b) => b.fontSize));
    let x = largestX;
    let y = largestY;

    let newGroup = false;
    let groupId = getGroupId();
    if (!groupId) {
      newGroup = true;
      groupId = createRandomId();
    }

    const newBlocks: IBlock[] = [];
    const blockHeight = allSelectedBlocks[0].height!;
    for (let i = 0; i < checkboxesToAdd; i++) {
      y = y! + blockHeight + 6;
      if (y + blockHeight >= pageDimensions[documentId!][pageIndex!].pageHeight) {
        break;
      }
      const blockWidth = allSelectedBlocks[0].width;
      newBlocks.push({
        blockId: createRandomId(),
        pageNumber: pageIndex!,
        blockType: 'Checkbox',
        x,
        y,
        width: blockWidth,
        height: blockHeight,
        required: false,
        assignedTo: allBlocksHaveTheSameSigner ? allSelectedBlocks[0].assignedTo : activeSigner,
        groupId,
        isEditingDisabled: false,
        fontSize: smallestFontSize,
      });
    }
    if (newGroup) {
      const group: IGroup = {
        blockGroupType: 'CheckboxGroup',
        id: groupId,
        blockIds: [allSelectedBlocks[0].blockId!], // just the initial block, we'll add the new blocks after they're created
        documentId: selectedBlocks.documentId!,
        envelopeId: id,
        pageNumber: pageIndex!,
        validation: {
          rule: 'gte',
          value: 1,
        },
      };
      dispatch(actions.createGroup(group, newBlocks, allSelectedBlocks[0].blockId!));
    } else {
      if (newBlocks.length) {
        const group = groups[documentId!][pageIndex!][newBlocks[0].groupId!];
        dispatch(actions.addNewBlocksToGroup(group, newBlocks));
      }
    }
  };

  const removeCheckboxes = (checkboxesToRemove: number = 1) => {
    const { documentId, pageIndex } = selectedBlocks;
    const group = getGroup();
    if (!group) {
      return;
    }
    const blockIds = group.blockIds.slice(-1 * checkboxesToRemove);
    dispatch(actions.deleteBlocks(documentId!, pageIndex!, blockIds));
  };

  const getGroupId = () => {
    const pageBlocks = selectedPageBlocks.filter((_, i) => selectedBlocks.blockIndices.includes(i));
    // if every block has the same groupId (and is not null)
    if (pageBlocks.every((b) => b.groupId && b.groupId === pageBlocks[0].groupId)) {
      return pageBlocks[0].groupId;
    }
    return;
  };

  const getGroup = () => {
    const groupId = getGroupId();
    //In the case where the groupBlock was already deleted - DIGI-2016
    if (groupId) {
      const { documentId, pageIndex } = selectedBlocks;
      return groups?.[documentId!]?.[pageIndex!]?.[groupId!];
    }
    return;
  };

  const editGroup = (updateObj: any) => {
    const group = getGroup();
    if (group?.id) {
      dispatch(
        actions.editBlockGroup(
          group.id,
          group.envelopeId,
          selectedBlocks.documentId!,
          selectedBlocks.pageIndex!,
          updateObj
        )
      );
    }
  };

  const align = (dir: string) => {
    let newValue = Infinity;
    if (dir === 'right' || dir === 'down') {
      newValue = -Infinity;
    }

    // First we find the new value depending on the alignment direction, i.e. for aligning left
    // we find the lowest X of all the selected blocks
    selectedBlocks.blockIndices.forEach((blockIndex) => {
      const b = selectedPageBlocks[blockIndex];
      switch (dir) {
        case 'left':
          newValue = newValue < b.x! ? newValue : b.x!;
          break;
        case 'right':
          newValue = newValue > b.x! + b.width! ? newValue : b.x! + b.width!;
          break;
        case 'up':
          newValue = newValue < b.y! ? newValue : b.y!;
          break;
        case 'down':
          newValue = newValue > b.y! + b.height! ? newValue : b.y! + b.height!;
          break;
        default:
          break;
      }
    });

    // Then we update all of the blocks with the new value we found based on the selected blocks above
    selectedBlocks.blockIndices.forEach((blockIndex) => {
      const b = selectedPageBlocks[blockIndex];
      let { x, y } = b;
      switch (dir) {
        case 'left':
          x = newValue;
          break;
        case 'right':
          x = newValue - b.width!;
          break;
        case 'up':
          y = newValue;
          break;
        case 'down':
          y = newValue - b.height!;
          break;
        default:
          break;
      }
      dispatch(
        actions.editBlock(selectedBlocks.documentId!, selectedBlocks.pageIndex!, blockIndex, {
          x,
          y,
        })
      );
    });
  };

  const isCreating = selectedBlocks.blockIndices.find((blockIndex) => selectedPageBlocks[blockIndex].isCreating);
  const selectedBlocksOnPage = selectedBlocks.blockIndices.map((index) => selectedPageBlocks[index]);

  const getBlockProperties = () => {
    let blockProperties: string[] = [];
    if (selectedBlocksOnPage.length > 1) {
      blockProperties = getMultipleBlockProperties(selectedBlocksOnPage);
    } else {
      blockProperties = blockType?.properties ?? [];
    }
    return blockProperties;
  };

  const blockProperties = getBlockProperties();

  return (
    <>
      {isCreating ? (
        <div className={classes.creatingBlock}>
          <CircularProgress />
        </div>
      ) : (
        ''
      )}
      <header className={classes.header}>
        <Typography variant="h6">{`Editing ${numberOfSelectedBlocks} Block${
          numberOfSelectedBlocks > 1 ? 's' : ''
        }`}</Typography>
        <IconButton aria-label="Close" className={classes.close} onClick={stopEditing}>
          <Close />
        </IconButton>
      </header>

      <div className={classes.sections}>
        {blockType.options!.checkboxGroupControls &&
          (selectedBlocks.blockIndices.length === 1 || getGroupId()) &&
          !hasReadOnlyBlocks && (
            <section className={classes.section}>
              <Typography variant="overline">Linked Checkboxes:</Typography>
              <CheckboxGroupControls
                group={getGroup()}
                addMoreCheckboxes={addMoreCheckboxes}
                editGroup={editGroup}
                removeCheckboxes={removeCheckboxes}
                pageDimensions={pageDimensions}
                selectedBlocks={selectedBlocks}
                pageIndex={selectedBlocks.pageIndex!}
                documentId={selectedBlocks.documentId!}
                blocks={blocks}
              />
            </section>
          )}

        <BlockActions isButtonDisabled={isButtonDisabled} />

        {blockType && blockType!.options!.hasText && selectedBlocks.blockIndices.length === 1 && (
          <section className={classes.section}>
            <Typography variant="body1">Text</Typography>
            <TextBox
              blockId={firstBlock.blockId!}
              fontSize={firstBlock.fontSize}
              value={firstBlock!.value as string}
              height={firstBlock!.height!}
              width={firstBlock!.width!}
              updateBlock={changeProperties}
              zoom={zoom}
              isCreating={firstBlock.isCreating}
            />
          </section>
        )}

        {blockProperties.length > 0 && (
          <BlockProperties
            data-spec="blockProperties"
            selectedBlocks={selectedBlocksOnPage}
            changeProperty={changeProperty}
            blockTypeProperties={blockProperties}
            firstBlock={firstBlock}
          />
        )}
      </div>

      {selectedBlocks.blockIndices.length > 1 && (
        <footer className={classes.footer}>
          <Typography variant="body2">Alignment</Typography>
          <div className={classes.alignmentButtons}>
            <Button variant="text" color="primary" onClick={() => align('left')} id="alignLeft">
              <AlignLeft height={32} width={32} />
            </Button>
            <Button variant="text" color="primary" onClick={() => align('right')} id="alignRight">
              <AlignRight height={32} width={32} />
            </Button>
            <Button variant="text" color="primary" onClick={() => align('up')} id="alignTop">
              <AlignTop height={32} width={32} />
            </Button>
            <Button variant="text" color="primary" onClick={() => align('down')} id="alignBottom">
              <AlignBottom height={32} width={32} />
            </Button>
          </div>
        </footer>
      )}
    </>
  );
};

export default BlockShelf;
