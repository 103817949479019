import React from 'react';
import { makeStyles, styled, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableMui from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import Tooltip from '@material-ui/core/Tooltip';
import { colors, elevation } from '@skyslope/mache';
import cx from 'classnames';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { chameleonElId } from '../../lib/constants';
import { checkIfTemplateSharedToParticularGroup, checkIfUserIsExcludedFromTemplateShare } from '../../lib/utils';
import { IShareRecord, SubGroup } from '../../store/templateSharing/types';
import SkeletonRow from '../../common/loading/SkeletonRow';
import StyledTooltip from '../../common/StyledTooltip';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    backgroundColor: '#FFFFFF',
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
    height: 64,
  },
  wrapper: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[400]}`,
    borderBottomWidth: 2,
  },
  tableCell: {
    color: colors.grey[800],
    borderColor: colors.grey[400],
    padding: '0 24px',
    '&:first-child': {
      borderLeft: '3px solid transparent',
    },
  },
  backgroundOverride: {
    backgroundColor: 'inherit',
  },
  clickableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.blue[50],
    },
  },
  clickableHeader: {
    userSelect: 'none',
    '&:hover': {
      backgroundColor: colors.blue[50],
      cursor: 'pointer',
    },
  },
  tableHeader: {
    display: 'table',
    marginLeft: -16,
    padding: '8px 16px',
    borderRadius: '50px',
  },
  headerLabel: {
    display: 'table-cell',
  },
  peopleHeaderLabel: {
    display: 'table-cell',
    paddingLeft: '130px',
  },
  sortIcons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sortIcon: {
    margin: '-7.5px 0',
  },
  tooltip: {
    backgroundColor: colors.grey[900],
    marginTop: 0,
    color: 'white',
    fontSize: '13px',
    fontWeight: 'normal',
    borderRadius: '2px',
    padding: '2px 10px',
    opacity: '.9 !important' as any,
  },
  firstCell: {
    borderLeft: '3px solid transparent',
  },
  rowActive: {
    borderLeftColor: `${colors.blue[800]} !important`,
  },
  topCheckboxTooltip: {
    height: '52px',
    width: '100%',
    borderRadius: '2px',
    backgroundColor: colors.grey[800],
    fontSize: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px, 10px',
    boxShadow: elevation.normal[12],
    margin: '0px',
  },
}));

// TableCell use to change the column size for the first column in Offices tab
const StyledTableCell = styled(TableCell)({
  maxWidth: '184px',
  minWidth: '104px',
  width: '20%',
});

interface IProps {
  columns: IColumn[];
  rows?: any[];
  idKey?: string;
  isLoading?: boolean;
  showBottomLoader?: boolean;
  resetScroll?: boolean;
  onClickRow?: Function;
  className?: string;
  rowClassName?: string;
  cellClassName?: string;
  onClickHeader?: (sortKey: string) => void;
  currentSortKey?: string;
  currentSortDesc?: boolean;
  activityLog?: boolean;
  checkbox?: boolean;
  checkboxOnClick?: (row: any, alreadyShared?: boolean) => void;
  userExclusions?: string[];
  toggleTemplateShareToAllGroups?: (alreadyShared: boolean) => void;
  isFetching?: boolean;
  sharedToAllParentGroups?: boolean;
  sharedIds?: IShareRecord[];
  currentTab?: string;
  onSharingPage?: boolean;
  subGroups?: SubGroup[];
  managementTable?: boolean;
}

export interface IColumn {
  key: string;
  label: any;
  align?: 'left' | 'center' | 'right';
  boldText?: boolean;
  cell?: (row: any) => React.ReactNode;
  sortKey?: string;
}

let cachedScrollTop = 0;
const Table = (props: IProps) => {
  const classes = useStyles(props);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const numberOfRowsOnLoad = 3;
  const [dash, setDash] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const currentRowActive = props.sharedToAllParentGroups && props.currentTab === 'People' ? classes.rowActive : '';

  React.useEffect(() => {
    if (cachedScrollTop && !props.resetScroll) {
      wrapperRef.current!.scrollTop = cachedScrollTop;
    }
    return () => {
      cachedScrollTop = wrapperRef.current!.scrollTop;
    };
  }, [props.rows]);

  React.useEffect(() => {
    if (props.sharedToAllParentGroups && props.userExclusions?.length) {
      setDash(true);
    } else setDash(false);
  }, [props.sharedToAllParentGroups, props.userExclusions, props.sharedIds]);

  const handleTopCheckboxClick = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
    props.toggleTemplateShareToAllGroups(props.sharedToAllParentGroups);
  };
  return (
    <div
      ref={wrapperRef}
      className={cx(classes.wrapper, props.className)}
      style={{ maxHeight: props.activityLog ? 'none' : '' }}
    >
      <TableMui stickyHeader>
        <TableHead>
          <TableRow className={cx(classes.tableRow, props.rowClassName)}>
            {props.columns.map((col, index: number) =>
              props.currentTab === 'Offices' && index === 0 ? (
                <StyledTableCell
                  classes={{
                    root: cx(classes.tableCell, props.cellClassName, currentRowActive),
                    stickyHeader: classes.backgroundOverride,
                  }}
                  key={col.key}
                  align={col.align || 'left'}
                  onClick={() => (col.sortKey && props.onClickHeader ? props.onClickHeader(col.sortKey) : false)}
                  data-spec={`header-${col.sortKey}`}
                >
                  <div
                    className={cx(classes.tableHeader, {
                      [classes.clickableHeader]: Boolean(col.sortKey),
                    })}
                  >
                    <Typography
                      className={col.key === 'people' ? classes.peopleHeaderLabel : classes.headerLabel}
                      variant="body2"
                    >
                      {col.key === 'check' && props.currentTab === 'People' ? (
                        <Tooltip
                          open={showTooltip}
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseOut={() => setShowTooltip(false)}
                          classes={{ tooltipPlacementTop: classes.topCheckboxTooltip }}
                          placement="top"
                          aria-label={
                            props.sharedToAllParentGroups ? 'Unshare with everyone selected' : 'Share with everyone'
                          }
                          title={
                            props.sharedToAllParentGroups ? 'Unshare with everyone selected' : 'Share with everyone'
                          }
                        >
                          <Checkbox
                            data-spec="top-checkbox"
                            indeterminate={dash}
                            disabled={props.isFetching}
                            checked={props.sharedToAllParentGroups}
                            onKeyPress={(e: any) => (e.key === 'Enter' ? handleTopCheckboxClick() : '')}
                            onClick={handleTopCheckboxClick}
                            color="primary"
                          />
                        </Tooltip>
                      ) : (
                        col.label
                      )}
                    </Typography>
                    {col.sortKey && (
                      <div className={classes.sortIcons}>
                        <ArrowUpIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && !props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                        <ArrowDownIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </StyledTableCell>
              ) : (
                <TableCell
                  classes={{
                    root: cx(classes.tableCell, props.cellClassName, currentRowActive),
                    stickyHeader: classes.backgroundOverride,
                  }}
                  key={col.key}
                  align={col.align || 'left'}
                  onClick={() => (col.sortKey && props.onClickHeader ? props.onClickHeader(col.sortKey) : false)}
                  data-spec={`header-${col.sortKey}`}
                >
                  <div
                    className={cx(classes.tableHeader, {
                      [classes.clickableHeader]: Boolean(col.sortKey),
                    })}
                  >
                    <Typography
                      className={col.key === 'people' ? classes.peopleHeaderLabel : classes.headerLabel}
                      variant="body2"
                    >
                      {col.key === 'check' && props.currentTab === 'People' ? (
                        <Tooltip
                          open={showTooltip}
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseOut={() => setShowTooltip(false)}
                          classes={{ tooltipPlacementTop: classes.topCheckboxTooltip }}
                          placement="top"
                          aria-label={
                            props.sharedToAllParentGroups ? 'Unshare with everyone selected' : 'Share with everyone'
                          }
                          title={
                            props.sharedToAllParentGroups ? 'Unshare with everyone selected' : 'Share with everyone'
                          }
                        >
                          <Checkbox
                            data-spec="top-checkbox"
                            indeterminate={dash}
                            disabled={props.isFetching}
                            checked={props.sharedToAllParentGroups}
                            onKeyPress={(e: any) => (e.key === 'Enter' ? handleTopCheckboxClick() : '')}
                            onClick={handleTopCheckboxClick}
                            color="primary"
                          />
                        </Tooltip>
                      ) : (
                        col.label
                      )}
                    </Typography>
                    {col.sortKey && (
                      <div className={classes.sortIcons}>
                        <ArrowUpIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && !props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                        <ArrowDownIcon
                          className={classes.sortIcon}
                          style={{
                            color:
                              col.sortKey === props.currentSortKey && props.currentSortDesc
                                ? colors.grey[800]
                                : colors.grey[500],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoading ? (
            <TableRow>
              <TableCell>Fetching...</TableCell>
            </TableRow>
          ) : (
            props.rows!.map((row, i) => (
              <Row
                key={i}
                index={i}
                row={row}
                idKey={props.idKey}
                columns={props.columns}
                onClickRow={props.onClickRow}
                rowClassName={props.rowClassName}
                cellClassName={props.cellClassName}
                onCheckboxClick={props.checkboxOnClick}
                checkAll={props.sharedToAllParentGroups}
                exclusions={props.userExclusions}
                onSharingPage={props.onSharingPage}
                currentTab={props.currentTab}
                sharedIds={props.sharedIds}
                isFetching={props.isFetching}
                managementTable={props.managementTable}
              />
            ))
          )}
          {props.showBottomLoader && (
            <>
              {[...Array(numberOfRowsOnLoad)].map((_, i) => (
                <SkeletonRow key={i} columns={props.columns} />
              ))}
            </>
          )}
        </TableBody>
      </TableMui>
    </div>
  );
};

interface IRowProps {
  index: number;
  row: any;
  idKey?: string;
  columns: IColumn[];
  rowClassName?: string;
  cellClassName?: string;
  onClickRow?: Function;
  onCheckboxClick: (row: any, alreadyShared?: boolean) => void;
  checkAll?: boolean;
  exclusions?: string[];
  onSharingPage?: boolean;
  currentTab?: string;
  sharedIds?: IShareRecord[];
  isFetching?: boolean;
  managementTable?: boolean;
}

const Row = (props: IRowProps) => {
  const {
    index,
    row,
    idKey,
    columns,
    onClickRow,
    onCheckboxClick,
    checkAll,
    exclusions,
    onSharingPage,
    currentTab,
    sharedIds,
    isFetching,
  } = props;
  const classes = useStyles(props);
  const clickableRow = Boolean(onClickRow);
  const isTemplatePage = /\/templates/.test(window.location.toString());
  const [disabled, setDisabled] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const userIsExcluded = checkIfUserIsExcludedFromTemplateShare(exclusions, row.id);
  const templateSharedToSubGroup = checkIfTemplateSharedToParticularGroup(sharedIds, row.id);
  const onGroupsTab = currentTab === 'Groups';
  const onOfficesTab = currentTab === 'Offices';
  const templateSharedViaGroups = onGroupsTab && (templateSharedToSubGroup || checkAll);

  React.useEffect(() => {
    setDisabled(false);
    // Disabling checkboxes in Groups and Offices tabs when All users is selected
    if ((currentTab === 'Groups' || currentTab === 'Offices') && checkAll) {
      setChecked(true);
      setDisabled(true);
      return;
    }
    if (row.isTemplateShared || templateSharedViaGroups || checkAll) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [row.isTemplateShared, row.id, checkAll, sharedIds, onGroupsTab, onOfficesTab]);

  React.useEffect(() => {
    if ((currentTab === 'Groups' || currentTab === 'Offices') && checkAll) {
      setDisabled(true);
      return;
    }
    setDisabled(isFetching);
  }, [isFetching]);

  const handleCheckboxClick = () => {
    onCheckboxClick(row, checked);
    setDisabled(true);
    setChecked(!checked);
  };

  return (
    // @ts-ignore
    <TableRow
      key={row[idKey!] || index}
      className={cx(classes.tableRow, props.rowClassName, { [classes.clickableRow]: clickableRow })}
      onClick={clickableRow ? () => onClickRow!(row) : undefined}
    >
      {columns.map((col, index) => {
        const propsToAdd = index === columns.length - 1 ? { id: chameleonElId(2) } : null;
        const firstRowClass = index === 0 ? classes.firstCell : '';
        const currentRowActive = checked && !userIsExcluded && index === 0 ? classes.rowActive : '';
        const groupRow = onSharingPage && currentTab === 'Groups' && col.key !== 'check';
        const checkbox =
          col.key === 'check' ? (
            <Checkbox
              data-spec={onSharingPage ? `checkbox-${row.id || row.officeGuid}` : `checkbox-${row.oktaUserId}`}
              disabled={disabled}
              checked={checked && !userIsExcluded}
              onKeyPress={(e: any) => (e.key === 'Enter' ? handleCheckboxClick() : '')}
              onClick={handleCheckboxClick}
              color="primary"
            />
          ) : (
            ''
          );
        return props.currentTab === 'Offices' && index === 0 ? (
          <StyledTableCell
            className={cx(classes.tableCell, props.cellClassName, firstRowClass, currentRowActive)}
            key={col.key}
            align={col.align || 'left'}
            {...propsToAdd}
          >
            {col.cell ? (
              col.cell(row)
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {''}
                {checkbox}
                <Typography tabIndex={0} variant={col.boldText ? 'body2' : 'body1'}>
                  {groupRow ? row.name : row[col.key]}
                </Typography>
              </div>
            )}
          </StyledTableCell>
        ) : (
          <TableCell
            className={cx(classes.tableCell, props.cellClassName, firstRowClass, currentRowActive)}
            key={col.key}
            align={col.align || 'left'}
            {...propsToAdd}
          >
            {col.cell ? (
              col.cell(row)
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isTemplatePage && row.senderCanSign && row.status !== 'Draft' && row.status !== 'Canceled' ? (
                  <StyledTooltip classes={{ tooltip: classes.tooltip }} title="Your signature required">
                    <NotificationImportantIcon
                      tabIndex="0"
                      aria-label="your signature required"
                      data-spec="sender-notification-icon"
                      style={{ color: colors.blue[800], marginRight: '14px', fontSize: '27px' }}
                    />
                  </StyledTooltip>
                ) : (
                  ''
                )}
                {checkbox}
                <Typography
                  data-spec={`${row.id}`}
                  className={
                    props.managementTable
                      ? 'whitespace-nowrap text-ellipsis overflow-hidden max-w-sm hover:overflow-visible hover:whitespace-normal hover:w-fit'
                      : ''
                  }
                  tabIndex={0}
                  variant={col.boldText ? 'body2' : 'body1'}
                >
                  {groupRow ? row.name : row[col.key]}
                </Typography>
              </div>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default Table;
