import { colors } from '@skyslope/mache';
import React from 'react';
import format from 'date-fns/format';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import * as Sentry from '@sentry/react';
import {
  ISigner,
  IGroups,
  IGroup,
  ICopyCache,
  IBlocks,
  ISelectedBlocks,
  IBlock,
  StrikeType,
} from '../store/senderBuild/types';
import { BLOCK_TYPES, BLOCK_TYPE_KEYS, SIGNER_COLORS } from './constants';
import { createRandomId } from './randomId';
import { IRecipientBody, IDocumentBody, IEnvelopeHistoryBody } from '../store/envelopeHistory/types';
import { IShareRecord, ITemplateUser, IUserOffice, SubGroup } from '../store/templateSharing/types';
import { IProperty } from '../dtm/types';
import { IEnvelopeMetadata } from '../store/dtm/envelopes/types';
import { OfficeUser } from './api/prime/prime';
import { IUser } from '../store/dtm/recipients/types';
import { IRecipient } from '../common/recipients/types';
import { getEnvelopeCounts } from './api/envelope/envelope';

dayjs.extend(advancedFormat);

interface IPasteMultipleBlocks {
  copyCache: ICopyCache;
  blocks: IBlocks;
  pasteCounter: number;
  pageHeight: number;
  pageWidth: number;
  selectedBlocks: ISelectedBlocks;
  currentPage: number;
  currentDocument: string;
  blockGroups: IGroups;
  envelopeId: string;
}

export const allOfficeGuid = '00000000-0000-0000-0000-000000000000';
export const hex2rgba = (hex: string, opacity = 0.2) => {
  if (hex) {
    const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${opacity})`;
  }
  return '';
};

export const getValueBasedOnBlockType = (
  blockType: string,
  signer: ISigner,
  dateFormat: string,
  currentValue?: string | boolean
) => {
  let value: string | boolean = currentValue || '';
  switch (blockType) {
    case BLOCK_TYPE_KEYS.SIGNATURE:
      value = 'Signature';
      break;
    case BLOCK_TYPE_KEYS.FULL_NAME:
      value = generateFullName(signer);
      break;
    case BLOCK_TYPE_KEYS.INITIALS:
      value = generateInitials(signer);
      break;
    case BLOCK_TYPE_KEYS.DATE:
      value = dateFormat;
      break;
    case BLOCK_TYPE_KEYS.TIME:
      value = `${dateFormat}, HH:MM:SS`;
      break;
    case BLOCK_TYPE_KEYS.CHECKBOX:
      value = currentValue || false;
      break;
    default:
      break;
  }
  return value;
};

export const generateInitials = (signer: ISigner) => {
  if (signer) {
    return signer!.firstName[0] + (signer!.lastName ? `${signer!.lastName[0]}` : '');
  }
  return '';
};
export const generateFullName = (signer?: ISigner) => {
  if (signer) {
    return signer!.firstName + (signer!.lastName ? ` ${signer!.lastName}` : '');
  }
  return '';
};

export const generateEmailSubject = () => 'You have documents to sign';

export const generateEmailBody = () => 'Your documents are ready to review and sign.';

export const getGroupById = (currentGroupId: string, blockGroups: IGroups) => {
  let foundGroup: IGroup | null = null;
  Object.keys(blockGroups).forEach((documentId) => {
    Object.keys(blockGroups[documentId]).forEach((pageNumber) => {
      Object.keys(blockGroups[documentId][pageNumber]).find((groupId) => {
        const group = blockGroups[documentId][pageNumber][groupId] as IGroup;
        if (group.id === currentGroupId) {
          foundGroup = group;
        }
      });
    });
  });
  return foundGroup;
};

const buildSelectBoxPosition = (blocks: IBlock[]) => {
  let topLeftX = Infinity;
  let topLeftY = Infinity;
  let bottomRightX = 0;
  let bottomRightY = 0;
  blocks.forEach((block) => {
    if (block.x! < topLeftX) {
      topLeftX = block.x!;
    }
    if (block.y! < topLeftY) {
      topLeftY = block.y!;
    }
    if (block.x! + block.width! > bottomRightX) {
      bottomRightX = block.x! + block.width!;
    }
    if (block.y! + block.height! > bottomRightY) {
      bottomRightY = block.y! + block.height!;
    }
  });
  return {
    x: topLeftX,
    y: topLeftY,
    width: bottomRightX - topLeftX,
    height: bottomRightY - topLeftY,
  };
};

const createCopiedCheckboxGroup = (
  block: IBlock,
  newGroupId: string,
  newBlockId: string,
  blockGroups: IGroups,
  envelopeId: string,
  currentPage: number,
  currentDocument: string
) => {
  const currentGroup = getGroupById(block.groupId!, blockGroups) as unknown as IGroup;
  const currentValidationRule = currentGroup!.validation.rule;
  const currentValidtionValue = currentGroup!.validation.value;

  const group: IGroup = {
    envelopeId,
    pageNumber: currentPage,
    blockGroupType: 'CheckboxGroup',
    id: newGroupId,
    blockIds: [newBlockId],
    documentId: currentDocument,
    validation: {
      rule: currentValidationRule,
      value: currentValidtionValue,
    },
  };
  return group;
};

export const pasteCacheBlocks = (
  args: IPasteMultipleBlocks
): {
  blocksWithNewIds: IBlock[];
  newGroups?: { [groupId: string]: IGroup };
  updatedGroup?: { currentGroup: IGroup; newBlock: IBlock[] };
} => {
  const {
    copyCache,
    pageHeight,
    pageWidth,
    selectedBlocks,
    blocks,
    currentPage,
    currentDocument,
    blockGroups,
    envelopeId,
  } = args;
  const { pasteCounter } = copyCache;
  const firstBlock = copyCache.blocks[0];
  const selectBox =
    selectedBlocks.blockIndices.length > 1
      ? buildSelectBoxPosition(copyCache.blocks)
      : { x: null, y: null, height: null, width: null };
  const x = selectBox.x || firstBlock.x!;
  const y = selectBox.y || firstBlock.y!;
  const height = selectBox.height || firstBlock.height!;
  const width = selectBox.width || firstBlock.width!;
  const blocksWithNewIds: IBlock[] = [];
  const xValue = x + (16 * copyCache.pasteCounter + width);
  const yValue = y + (16 * copyCache.pasteCounter + height);
  const seenGroupIds = {};
  const newGroups = {};
  const newBlock: IBlock[] = [];
  const updatedGroup = { currentGroup: {}, newBlock };
  let group;
  let xBounds = false;
  let yBounds = false;
  let currentGroupId: string | null;

  copyCache.blocks.forEach((block, index) => {
    const lastCopiedBlockIndex = blocks[currentDocument][currentPage].length - 1;
    const currentBlockIndex = selectedBlocks.blockIndices[index];
    const currentBlock =
      block ||
      blocks[selectedBlocks.documentId!][selectedBlocks.pageIndex!][currentBlockIndex] ||
      blocks[currentDocument][currentPage][lastCopiedBlockIndex];
    const newBlockId = createRandomId();
    let newGroupId = null;
    if (currentBlock.groupId && !seenGroupIds[currentBlock.groupId]) {
      newGroupId = createRandomId();
      currentGroupId = newGroupId;
      const newGroup = createCopiedCheckboxGroup(
        currentBlock,
        newGroupId,
        newBlockId,
        blockGroups,
        envelopeId,
        currentPage,
        currentDocument
      );
      newGroups[newGroupId] = newGroup;
      seenGroupIds[currentBlock.groupId] = newGroupId;
    } else if (!currentBlock.groupId) {
      currentGroupId = null;
    } else if (currentBlock.groupId && seenGroupIds[currentBlock.groupId]) {
      newGroupId = seenGroupIds[currentBlock.groupId];
      currentGroupId = newGroupId;
      newGroups[newGroupId].blockIds.push(newBlockId);
    } else if (currentBlock.groupId && !seenGroupIds[currentBlock.groupId]) {
      currentGroupId = currentBlock.groupId;
      group = blockGroups[currentDocument][currentPage][currentGroupId] as IGroup;
      updatedGroup.currentGroup = group;
      updatedGroup.newBlock = blocksWithNewIds;
      seenGroupIds[currentBlock.groupId] = currentGroupId;
    }
    if (xValue >= pageWidth && yValue >= pageHeight) {
      xBounds = true;
      yBounds = true;
    } else if (xValue >= pageWidth) {
      xBounds = true;
    } else if (yValue >= pageHeight) {
      yBounds = true;
    }
    blocksWithNewIds.push({
      ...block,
      blockId: newBlockId,
      ...(newGroupId && { groupId: currentGroupId! }),
      x: xBounds ? currentBlock.x! : block.x! + 16 * pasteCounter,
      y: yBounds ? currentBlock.y! : block.y! + 16 * pasteCounter,
      pageNumber: currentPage,
    });

    xBounds = false;
    yBounds = false;
  });
  // @ts-ignore
  return { blocksWithNewIds, newGroups, updatedGroup };
};

export const concatStrings = (firstName?: string, middleName?: string, lastName?: string) => {
  return [firstName, middleName, lastName].filter(Boolean).join(' ');
};

export const concatObject = (recipient?: IRecipientBody) => {
  return [recipient?.firstName, recipient?.middleName, recipient?.lastName].filter(Boolean).join(' ');
};

export const getTimezoneCode = () => {
  const match = new Date().toString().match(/\((.+)\)/);
  if (match!.length < 2) {
    return '';
  }
  const longTimezone = match![1];
  return longTimezone
    .split('')
    .filter((c) => /[A-Z]/.test(c))
    .join('');
};

export const generateFileName = (documents: IDocumentBody[]) => {
  if (!documents) {
    return '';
  }
  return documents.length > 1 ? `digisign-documents-${format(new Date(), 'MM-dd-yyyy')}.pdf` : documents[0].fileName;
};

export const saveFile = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  window.addEventListener('focus', (e) => URL.revokeObjectURL(url), { once: true });
};

export const printFile = (url: string) => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  document.body.append(iframe);
  iframe.contentWindow?.print();
};

export const getRecipientGroup = (recipient: IRecipientBody, envelope: IEnvelopeHistoryBody) => {
  if (recipient.role === 'Signer') {
    const signerDetail = envelope.signerDetails!.details!.find((item) => item.value?.signerId === recipient.id);
    const group = envelope.signingGroups!.find((group) => group.id === signerDetail!.value!.signingGroupId!);
    return group;
  }
  return null;
};

export const bold = (s: string) => {
  return <strong>{s}</strong>;
};

export const boldAll = (recipients: IRecipientBody[]) => {
  return recipients?.map((r, i) => {
    if (i === recipients.length - 1 && recipients.length > 1) {
      return bold(`& ${r.firstName}`);
    }
    if (recipients.length === 1) {
      return bold(`${r.firstName}`);
    }
    return bold(`${r.firstName}, `);
  });
};

export const setEndOfContenteditable = (contentEditableElement: any) => {
  let range;
  let selection;
  if (document.createRange) {
    range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  }
};

export const getBlocks = (blocks: IBlocks) => {
  const blockArray: IBlock[] = [];
  Object.keys(blocks).forEach((documentId) => {
    Object.keys(blocks[documentId]).forEach((pageNumber) => {
      blocks[documentId][pageNumber].forEach((block: IBlock) => {
        blockArray.push(block);
      });
    });
  });
  return blockArray;
};

export const getGroupIds = (blockGroups: IGroups) => {
  const groupIds: string[] = [];
  Object.keys(blockGroups).forEach((documentId) => {
    Object.keys(blockGroups[documentId]).forEach((pageNumber) => {
      Object.keys(blockGroups[documentId][pageNumber]).find((groupId) => {
        groupIds.push(groupId);
      });
    });
  });
  return groupIds;
};

export const getListOfGroups = (blockGroups: IGroups): IGroup[] => {
  const groupList: IGroup[] = [];
  Object.keys(blockGroups).forEach((documentId) => {
    Object.keys(blockGroups[documentId]).forEach((pageNumber) => {
      Object.keys(blockGroups[documentId][pageNumber]).find((groupId) => {
        groupList.push(blockGroups[documentId][pageNumber][groupId]);
      });
    });
  });
  return groupList;
};

export const getInvalidCheckboxes = (blocks: IBlock[], groupIds: string[], groups: IGroup[]): IBlock[] => {
  const groupBlockIds = groups
    .map((group) => {
      return group.blockIds;
    })
    .flat();
  const invalidCheckboxes = blocks.filter((b) => {
    // if block group id points to group, validate the group points to block id
    if (b.groupId && !groupBlockIds.includes(b.blockId!)) {
      return b;
    }
    return b.groupId && !groupIds.includes(b.groupId);
  });
  return invalidCheckboxes;
};

export const getInvalidCheckboxGroups = (groups: IGroup[], blocks: IBlock[], groupIds: string[]): IGroup[] => {
  const invalidCheckboxGroups: IGroup[] = [];
  groups.forEach((group) => {
    group.blockIds.forEach((blockId) => {
      const block = blocks.find((b) => b.blockId! === blockId);
      if (!block && !invalidCheckboxGroups.includes(group)) {
        invalidCheckboxGroups.push(group);
      } else {
        // if checkbox exists with no group id, group is bad
        const groupIdOnBlock = block?.groupId;
        if (!groupIdOnBlock && !invalidCheckboxGroups.includes(group)) {
          invalidCheckboxGroups.push(group);
        }
      }
    });
  });
  return invalidCheckboxGroups;
};

export const getBlockDocumentId = (blockId: string, blocks: IBlocks) => {
  let foundDocumentId = '';
  Object.keys(blocks).forEach((documentId) => {
    Object.keys(blocks[documentId]).forEach((pageNumber) => {
      blocks[documentId][pageNumber].forEach((block: IBlock) => {
        if (block.blockId === blockId) {
          foundDocumentId = documentId;
        }
      });
    });
  });
  return foundDocumentId;
};

export const getBlockIndex = (blockId: string, blocks: IBlocks) => {
  let foundIndex = 0;
  Object.keys(blocks).forEach((documentId) => {
    Object.keys(blocks[documentId]).forEach((pageNumber) => {
      blocks[documentId][pageNumber].forEach((block: IBlock, index: number) => {
        if (block.blockId === blockId) {
          foundIndex = index;
        }
      });
    });
  });
  return foundIndex;
};

export const removeInvalidBlockIdsFromGroup = (group: IGroup, blocks: IBlock[]): IGroup => {
  const updatedBlockIds = group.blockIds.filter((blockId: string) => {
    const block = blocks.find((b) => b.blockId === blockId);
    if (block && block.groupId === group.id) {
      return block.blockId;
    }
  });
  return { ...group, blockIds: updatedBlockIds };
};

export const removeInvalidBlockIdsFromGroups = (groups: IGroup[], blocks: IBlock[]): IGroup[] => {
  return groups.map((group) => {
    return removeInvalidBlockIdsFromGroup(group, blocks);
  });
};

export const removeGroupIdFromBlock = (block: IBlock): IBlock => {
  const { groupId, ...updatedBlock } = block;
  return updatedBlock;
};

export const removeGroupIdsFromBlocks = (blocks: IBlock[]): IBlock[] => {
  return blocks.map((block) => {
    return removeGroupIdFromBlock(block);
  });
};

export const cleanUpInvalidBlockValuesAndLogToSentry = (
  block: IBlock,
  id: string,
  sentryMessage: string,
  isTemplate?: boolean
): IBlock => {
  let blockHadInvalidValues = false;
  const errors: any = {};
  const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === block.blockType);
  if (block.y < 0 || block.y === null || isNaN(block.y)) {
    blockHadInvalidValues = true;
    errors.y = block.y;
    block.y = 1;
  }
  if (block.x < 0 || block.x === null || isNaN(block.x)) {
    blockHadInvalidValues = true;
    errors.x = block.x;
    block.x = 1;
  }
  if (block.height === null || isNaN(block.height) || block.height < 0) {
    blockHadInvalidValues = true;
    errors.height = block.height;
    block.fontSize = currentBlockType?.size?.fontSize?.default;
    block.height = currentBlockType?.size?.default.height;
  }
  if (block.width === null || isNaN(block.width) || block.width < 0) {
    errors.width = block.width;
    blockHadInvalidValues = true;
    block.width = currentBlockType?.size?.default.width;
  }
  if (block.fontSize >= block.height || isNaN(block.fontSize) || block.fontSize < 0) {
    errors.fontSize = block.fontSize;
    block.fontSize = currentBlockType?.size?.fontSize?.default;
  }
  if (blockHadInvalidValues) {
    Sentry.captureMessage(sentryMessage, {
      tags: {
        ...(isTemplate ? { templateId: id } : { envelopeId: id }),
      },
      extra: {
        block,
        errors,
      },
      level: 'warning',
    });
  }
  return block;
};

export const checkForInvalidBlockValuesAndLogToSentry = (
  envelopeId: string,
  blockXOrWidth: number,
  blockYOrHeight: number,
  sentryMessage: string,
  isXAndY: boolean,
  extraErrorProperties?: any
) => {
  const blockValues: any = extraErrorProperties ? { ...extraErrorProperties } : {};
  let blockHadNulls = false;
  if (blockXOrWidth < 0 || blockXOrWidth === null || isNaN(blockXOrWidth)) {
    blockHadNulls = true;
  }
  if (blockYOrHeight < 0 || blockYOrHeight === null || isNaN(blockYOrHeight)) {
    blockHadNulls = true;
  }
  if (blockHadNulls) {
    isXAndY ? (blockValues.x = blockXOrWidth) : (blockValues.width = blockXOrWidth);
    isXAndY ? (blockValues.y = blockYOrHeight) : (blockValues.height = blockYOrHeight);
    Sentry.captureMessage(sentryMessage, {
      tags: {
        envelopeId,
      },
      extra: {
        error: blockValues,
      },
      level: 'warning',
    });
  }
};

export const checkIfBoundsIsNullOrHasMissingProperties = (
  envelopeId: string,
  bounds: DOMRect,
  sentryMessage: string
) => {
  if (!bounds || bounds === null || Object.keys(bounds).length === 0) {
    Sentry.captureMessage(sentryMessage, {
      tags: {
        envelopeId,
      },
      extra: {
        error: bounds,
      },
      level: 'warning',
    });
  }
};

export const checkIfTemplateSharedToAnyUsersGroup = (shares: IShareRecord[], usersGroups: string[]): boolean => {
  if (!shares?.length || !usersGroups?.length) return false;
  return shares.some((share) => {
    return share.shareType !== 'Group'
      ? false
      : usersGroups.find((s) => {
          return s === share.id;
        });
  });
};

export const checkIfUserIsInUserExclusions = (userExclusions: string[], user: IUser) => {
  if (!userExclusions.length || !user) {
    return false;
  }
  return userExclusions.includes(user.oktaUserId);
};

export const checkIfTemplateIsSharedToUserInOffice = (
  user: ITemplateUser,
  officeUsers: OfficeUser[] | { oktaUserID: string }[],
  userExclusions: string[]
) => officeUsers.some((oU) => oU.oktaUserID === user.oktaUserId && !userExclusions.includes(user.oktaUserId));

export const checkIfTemplateSharedToParticularGroup = (shares: IShareRecord[], groupId: string): boolean => {
  if (!shares?.length) return false;
  return shares.some((share) => share.id === groupId);
};

export const checkIfTemplateSharedToAllUsersGroups = (groups: string[], shares: IShareRecord[]): boolean => {
  if (!groups?.length || !shares?.length) return false;
  const sharedGroupIds = shares?.flatMap((s) => (s.shareType === 'Group' ? s.id : []));
  const includesAllGroups = (group: string) => sharedGroupIds?.includes(group);
  return groups?.every(includesAllGroups);
};

export const checkIfUserIsExcludedFromTemplateShare = (exclusions: string[], userOktaId: string) => {
  if (!exclusions?.length) return false;
  return exclusions.includes(userOktaId);
};

export const toggleGroupAlphabeticalSort = (groups: SubGroup[], sortByDesc: boolean) => {
  if (sortByDesc) {
    return groups.sort((b, a) => {
      if (a.name === null || a.name === '') return 1;
      if (b.name === null || b.name === '') return -1;
      return a.name.toUpperCase().localeCompare(b.name.toUpperCase(), 'de', { sensitivity: 'base' });
    });
  }
  return groups.sort((a, b) => {
    if (a.name === null || a.name === '') return 1;
    if (b.name === null || b.name === '') return -1;
    return a.name.toUpperCase().localeCompare(b.name.toUpperCase(), 'de', { sensitivity: 'base' });
  });
};

export const toggleOfficeAlphabeticalSort = (offices: IUserOffice[], sortByDesc: boolean) => {
  if (sortByDesc) {
    return offices.sort((b, a) => {
      if (a.officeName === null || a.officeName === '') return 1;
      if (b.officeName === null || b.officeName === '') return -1;
      return a.officeName.toUpperCase().localeCompare(b.officeName.toUpperCase(), 'de', { sensitivity: 'base' });
    });
  }

  const sortedOffices = offices.sort((a, b) => {
    if (a.officeName === null || a.officeName === '') return 1;
    if (b.officeName === null || b.officeName === '') return -1;
    return a.officeName.toUpperCase().localeCompare(b.officeName.toUpperCase(), 'de', { sensitivity: 'base' });
  });

  // moving the All office to the top of the list.
  const allOfficeIndex = sortedOffices.findIndex((office) => office.officeGuid === allOfficeGuid);
  if (allOfficeIndex > -1) {
    const allOffice = sortedOffices.splice(allOfficeIndex, 1)[0];
    sortedOffices.unshift(allOffice);
  }

  return sortedOffices;
};

export const determineStrikeType = (yValue: number, startY: number, startX: number, mouseY: number, mouseX: number) => {
  let strikeType: StrikeType | undefined;
  if (yValue === startY) {
    strikeType = undefined;
  } else if ((mouseX > startX && mouseY > startY) || (mouseX < startX && mouseY < startY)) {
    strikeType = StrikeType.Down;
  } else if ((mouseX < startX && mouseY > startY) || (mouseX > startX && mouseY < startY)) {
    strikeType = StrikeType.Up;
  }
  return strikeType;
};

export const propertyToString = (property: IProperty) => {
  // 1234 N Foo St #123
  const streetParts = [property.streetNumber, property.direction, property.streetAddress, property.unit];
  const streetStr = streetParts.filter((s) => typeof s === 'string' && s.length).join(' ');
  // CA 95820
  const endParts = [property.state, property.zip];
  const endStr = endParts.filter((s) => typeof s === 'string' && s.length).join(' ');
  // Combine everything
  const fullParts = [streetStr, property.city, endStr];
  return fullParts.filter((s) => typeof s === 'string' && s.length).join(', ');
};

export const getFileType = (metadata: IEnvelopeMetadata) => {
  return (metadata.fileType = metadata.listingGuid == null ? 'sale' : 'listing');
};

export const blockMenuResize = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false,
};

export const getMultipleBlockProperties = (selectedBlocks: IBlock[]) => {
  let blockProperties: string[] = [];
  const blocksAreSignatureInitialOrText = selectedBlocks?.every(
    (block) =>
      block.blockType === BLOCK_TYPE_KEYS.SIGNATURE ||
      block.blockType === BLOCK_TYPE_KEYS.INITIALS ||
      block.blockType === BLOCK_TYPE_KEYS.TEXT
  );
  const blocksAreSingleCheckboxOrText = selectedBlocks?.every(
    (block) =>
      block.blockType === BLOCK_TYPE_KEYS.TEXT || (block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && !block.groupId)
  );
  const blocksAreAllSingleCheckbox = selectedBlocks?.every(
    (block) => block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && !block.groupId
  );
  const blocksAreAllTextBlocks = selectedBlocks?.every((block) => block.blockType === BLOCK_TYPE_KEYS.TEXT);
  const blocksAreAllCheckboxesInSameGroup = selectedBlocks?.every(
    (block) =>
      block.blockType === BLOCK_TYPE_KEYS.CHECKBOX && block.groupId && block.groupId === selectedBlocks[0].groupId
  );

  const someBlocksAreReadOnly = selectedBlocks?.some((block) => block.readOnly);
  const allBlocksAreReadOnly = selectedBlocks?.every((block) => block.readOnly);

  if (blocksAreSignatureInitialOrText && (!someBlocksAreReadOnly || allBlocksAreReadOnly)) {
    blockProperties.push('required');
    if (blocksAreAllTextBlocks) blockProperties.push('readOnly');
  } else if (blocksAreSingleCheckboxOrText && !blocksAreAllSingleCheckbox) {
    blockProperties.push('readOnly');
  } else if (blocksAreAllCheckboxesInSameGroup || blocksAreAllSingleCheckbox) {
    blockProperties.push('readOnly', 'checked');
  }
  return blockProperties;
};

export const getRecipientColor = (colorCounter: number, title?: string) => {
  if (colorCounter === 0 || title === 'Me') return colors.blue[800];
  return SIGNER_COLORS[(colorCounter - 1) % SIGNER_COLORS.length];
};

export const sortRecipients = (recipients: IRecipient[] | ISigner[]) => {
  return recipients.sort((a, b) => {
    return (
      (b.recipientColorCounter != null) - (a.recipientColorCounter != null) ||
      a.recipientColorCounter - b.recipientColorCounter
    );
  });
};

export const getHighestRecipientCounter = (recipients: IRecipient[]) => {
  let highestColorCount = 0;
  recipients.forEach((recipient) => {
    if (recipient.recipientColorCounter > highestColorCount) {
      highestColorCount = recipient.recipientColorCounter;
    }
  });
  return highestColorCount;
};

export const applyTemporaryColorCounter = (
  newRecipient: IRecipient | ISigner,
  recipients: IRecipient[],
  envelopeColorCounter?: number
) => {
  // if there is an envelope color counter we minus 1 because signer colors are zero based and envelopeColorCounter is not
  let newRecipientColorCounter = envelopeColorCounter ? envelopeColorCounter - 1 : 0;
  const highestRecipientColorCount = getHighestRecipientCounter(recipients);
  const recipientsHaveHigherCountThanEnvelope =
    envelopeColorCounter && highestRecipientColorCount >= envelopeColorCounter;

  if (newRecipient.title === 'Me') return 0;
  if (recipients?.some((rec) => rec.recipientColorCounter)) {
    newRecipientColorCounter =
      recipientsHaveHigherCountThanEnvelope || !envelopeColorCounter
        ? highestRecipientColorCount
        : envelopeColorCounter - 1;
  }
  newRecipientColorCounter += 1;
  return newRecipientColorCounter;
};

export const applyColorAndCounterToNewRecipient = (
  newRecipients: IRecipient[],
  currentRecipients: IRecipient[] | ISigner[],
  colorCounter?: number
) => {
  const newRecipient = newRecipients[newRecipients.length - 1];
  if (newRecipients.length > currentRecipients.length && (!newRecipient.color || !newRecipient.recipientColorCounter)) {
    if (!newRecipient.recipientColorCounter) {
      newRecipient.recipientColorCounter = applyTemporaryColorCounter(newRecipient, newRecipients, colorCounter);
    }
    if ((!newRecipient.color || newRecipient.color === colors.grey[600]) && newRecipient.title != 'Me') {
      newRecipient.color = getRecipientColor(newRecipient.recipientColorCounter);
    }
  }
  return newRecipients;
};

export const getBlocksBasedOnBlockType = (blocks: IBlock[], blockType: string): IBlock[] => {
  return blocks.filter((b) => b.blockType === blockType);
};

export const handleFontSizing = (
  height: number,
  width: number,
  zoom: number,
  initialFontSize: number,
  initialValue: string
) => {
  const defaultFontSize = Math.min(height - 1, initialFontSize || 24, 24);
  const value = initialValue || '';
  const newFontSize = handleCalculateFontSize(value, defaultFontSize, height, width, zoom);
  return newFontSize;
};

const hiddenDiv = document.createElement('div');
const style = {
  fontFamily: 'courier',
  overflow: 'hidden',
  boxSizing: 'content-box',
  wordBreak: 'break-word',
  textAlign: 'left',
  lineHeight: 1,
  position: 'fixed',
  whiteSpace: 'break-spaces',
  visibility: 'hidden',
};
for (const key in style) {
  hiddenDiv.style[key] = style[key];
}
document.body.appendChild(hiddenDiv);

const shrink = (fontSize: number, height: number, zoom: number): number => {
  let newFontSize = Math.min(fontSize, height) - 1;
  while (newFontSize > 0) {
    hiddenDiv.style.fontSize = `${newFontSize * zoom}px`;
    if (hiddenDiv.clientHeight < height * zoom) {
      return newFontSize;
    }
    newFontSize--;
  }
  return 0;
};

const grow = (fontSize: number, height: number, zoom: number): number => {
  const maxFontSize = Math.min(height - 1, 25);
  let newFontSize = Math.min(fontSize + 1, maxFontSize);
  while (newFontSize <= maxFontSize) {
    hiddenDiv.style.fontSize = `${newFontSize * zoom}px`;
    if (hiddenDiv.clientHeight < height * zoom) {
      newFontSize++;
    } else {
      return newFontSize - 1;
    }
  }
  return newFontSize - 1;
};

export const handleCalculateFontSize = (
  value: string,
  fontSize: number,
  height: number,
  width: number,
  zoom: number
): number => {
  hiddenDiv.style.width = `${width * zoom - 8}px`;
  hiddenDiv.textContent = value;
  hiddenDiv.style.fontSize = `${fontSize * zoom}px`;
  if (hiddenDiv.clientHeight >= height * zoom || fontSize >= height) {
    return shrink(fontSize, height, zoom);
  }
  return grow(fontSize, height, zoom);
};

export const validateAndCorrectTextBlocks = (blocks: IBlock[], zoom: number) => {
  let textBlocks: IBlock[] = getBlocksBasedOnBlockType(blocks, 'Text');
  let textBlocksToUpdate: IBlock[] = [];
  textBlocks.forEach((b) => {
    if (!b.isEditingDisabled) {
      let fontSize = handleFontSizing(b.height!, b.width!, zoom, b.fontSize!, b.value as string);
      if (b.fontSize && b.fontSize > fontSize) {
        b = { ...b, fontSize };
        textBlocksToUpdate.push(b);
      }
    }
  });

  return textBlocksToUpdate;
};

export const isInactiveTemplateRecipient = (recipient: IRecipient) => {
  return (
    recipient.isPlaceholder &&
    !recipient.isActive &&
    ((!recipient.firstName && !recipient.lastName) || !recipient.email)
  );
};

export const getBestRecipientMatchForOptionalRecipient = (
  mappedRecipientIds: string[],
  recipients: IRecipient[],
  optionalPrimeRecipient: IRecipient
) => {
  const matchedResult = { match: {}, rank: 4 };

  // First, try to find a best match against recipients added to the envelope, including
  // template placeholder recipients that were added to the envelope, atleast at some point
  const matchedResultForEnvelopeRecipients = recipients.reduce((result, r) => {
    const isRecipientInactiveTemplateRecipient = isInactiveTemplateRecipient(r);

    if (mappedRecipientIds.includes(r.id) || isRecipientInactiveTemplateRecipient) {
      return result;
    }
    if (r.title?.toLowerCase() === optionalPrimeRecipient.title?.toLowerCase()) {
      if (
        r.firstName?.toLowerCase() === optionalPrimeRecipient.firstName?.toLowerCase() &&
        r.lastName?.toLowerCase() === optionalPrimeRecipient.lastName?.toLowerCase() &&
        r.email?.toLowerCase() === optionalPrimeRecipient.email?.toLowerCase()
      ) {
        // try to match by title, firstname, lastname, and email
        return { match: r, rank: 1 };
      }
      if (
        r.firstName?.toLowerCase() === optionalPrimeRecipient.firstName?.toLowerCase() &&
        r.lastName?.toLowerCase() === optionalPrimeRecipient.lastName?.toLowerCase() &&
        result.rank > 2
      ) {
        // if no match try to match by title, firstname, and lastname
        return { match: r, rank: 2 };
      }
      if (r.email?.toLowerCase() === optionalPrimeRecipient.email?.toLowerCase() && result.rank > 3) {
        // if no match try to match by title, and email
        return { match: r, rank: 3 };
      }
    }
    return result;
  }, matchedResult);

  // if a match is found, return them
  if (matchedResultForEnvelopeRecipients.rank !== 4) {
    return matchedResultForEnvelopeRecipients.match;
  }

  // if no match, try to match with template placeholder recipients
  const { match: matchedRecipient } = recipients.reduce((result, r) => {
    const isRecipientInactiveTemplateRecipient = isInactiveTemplateRecipient(r);
    if (mappedRecipientIds.includes(r.id) || !isRecipientInactiveTemplateRecipient) {
      return result;
    }
    // if a recipient is an inactive template recipient that has never been added to the envelope
    // try to match only by title
    if (r.title?.toLowerCase() === optionalPrimeRecipient.title?.toLowerCase() && result.rank > 1) {
      return { match: r, rank: 1 };
    }

    return result;
  }, matchedResultForEnvelopeRecipients);

  return matchedRecipient;
};

export const handleAppLogout = () => {
  localStorage.removeItem('_trialBanner');
  window.location.pathname = window.location.pathname.includes('templates') ? '/templates/logout' : '/logout';
};

export const getUserEnvelopeCounts = async () => {
  let envelopesCreated, envelopesSent, envelopesCompleted;
  const res = await getEnvelopeCounts();
  envelopesCreated = res.envelopesCreated;
  envelopesSent = res.envelopesSent;
  envelopesCompleted = res.envelopesCompleted;
  return {
    Ds3EnvelopesCreated: envelopesCreated,
    Ds3EnvelopesSent: envelopesSent,
    Ds3EnvelopesCompleted: envelopesCompleted,
  };
};
