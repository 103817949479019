import { action } from 'typesafe-actions';
import { UserInfo } from '../../auth/useUserInfo';
import { IRecipient } from '../../common/recipients/types';
import { ResourceNames } from '../../lib/constants';
import { IBlock, IBlocks, IFetchDone, IGroup, ISenderBuildState, ISigningGroup } from './types';

export enum ActionTypes {
  RESET = 'senderBuild/RESET',
  FETCH = 'senderBuild/FETCH',
  FETCH_DONE = 'senderBuild/FETCH_DONE',
  FETCH_ERROR = 'senderBuild/FETCH_ERROR',
  FETCH_ENVELOPE_PDFS = 'senderBuild/FETCH_ENVELOPE_PDFS',
  FETCH_ENVELOPE_PDF_DONE = 'senderBuild/FETCH_ENVELOPE_PDF_DONE',
  FETCH_ENVELOPE_PDF_ERROR = 'senderBuild/FETCH_ENVELOPE_PDF_ERROR',
  SEND_ENVELOPE = 'senderBuild/SEND_ENVELOPE',
  SEND_ENVELOPE_DONE = 'senderBuild/SEND_ENVELOPE_DONE',
  SEND_ENVELOPE_ERROR = 'senderBuild/SEND_ENVELOPE_ERROR',
  ZOOM_IN = 'senderBuild/ZOOM_IN',
  ZOOM_OUT = 'senderBuild/ZOOM_OUT',
  SET_ZOOM = 'senderBuild/SET_ZOOM',
  CREATE_NEW_BLOCK = 'senderBuild/CREATE_NEW_BLOCK',
  CREATE_NEW_BLOCK_DONE = 'senderBuild/CREATE_NEW_BLOCK_DONE',
  EDIT_BLOCK = 'senderBuild/EDIT_BLOCK',
  REMOVE_GROUP_ID_FROM_BLOCK = 'senderBuild/REMOVE_GROUP_ID_FROM_BLOCK',
  DELETE_BLOCK_GROUP_DONE = 'senderBuild/DELETE_BLOCK_GROUP_DONE',
  DELETE_BLOCKS = 'senderBuild/DELETE_BLOCKS',
  DELETE_BLOCKS_CANCELLED = 'senderBuild/DELETE_BLOCKS_CANCELLED',
  DELETE_BLOCKS_DONE = 'senderBuild/DELETE_BLOCKS_DONE',
  CHANGE_ACTIVE_BLOCK_TYPE = 'senderBuild/CHANGE_ACTIVE_BLOCK_TYPE',
  CHANGE_ACTIVE_SIGNER = 'senderBuild/CHANGE_ACTIVE_SIGNER',
  SELECT_BLOCKS = 'senderBuild/SELECT_BLOCKS',
  COPY_BLOCKS = 'senderBuild/COPY_BLOCKS',
  PASTE_BLOCKS = 'senderBuild/PASTE_BLOCKS',
  PASTE_BLOCKS_DONE = 'senderBuild/PASTE_BLOCKS_DONE',
  EDITING_FIELD = 'senderBuild/EDITING_FIELD',
  EDIT_GROUP = 'senderBuild/EDIT_GROUP',
  SET_PAGE_DIMENSIONS = 'senderBuild/SET_PAGE_DIMENSIONS',
  CREATE_NEW_SIGNING_GROUP = 'senderBuild/CREATE_NEW_SIGNING_GROUP',
  EDIT_SIGNER = 'senderBuild/EDIT_SIGNER',
  UPDATE_EMAIL_CONFIG = 'senderBuild/UPDATE_EMAIL_CONFIG',
  UPDATE_EMAIL_CONFIG_DONE = 'senderBuild/UPDATE_EMAIL_CONFIG_DONE',
  UPDATE_EMAIL_CONFIG_ERROR = 'senderBuild/UPDATE_EMAIL_CONFIG_ERROR',
  SET_AUTOSAVING = 'senderBuild/SET_AUTOSAVING',
  COPY_BLOCKS_TO_CACHE = 'senderBuild/COPY_BLOCKS_TO_CACHE',
  SET_CURRENT_PAGE = 'senderBuild/SET_CURRENT_PAGE',
  SET_CURRENT_DOCUMENT = 'senderBuild/SET_CURRENT_DOCUMENT',
  CREATE_GROUP = 'senderBuild/CREATE_GROUP',
  CREATE_GROUP_DONE = 'senderBuild/CREATE_GROUP_DONE',
  ADD_NEW_BLOCKS_TO_GROUP = 'senderBuild/ADD_NEW_BLOCKS_TO_GROUP',
  ADD_NEW_BLOCKS_TO_GROUP_DONE = 'senderBuild/ADD_NEW_BLOCKS_TO_GROUP_DONE',
  FOCUS_INPUT = 'senderBuild/FOCUS_INPUT',
  BLUR_INPUT = 'senderBuild/BLUR_INPUT',
  SET_RECIPIENTS_AND_SIGNING_GROUPS_MODAL = 'senderBuild/SET_RECIPIENTS_AND_SIGNING_GROUPS_MODAL',
  UPDATE_RECIPIENTS = 'senderBuild/UPDATE_RECIPIENTS',
  UPDATE_RECIPIENTS_DONE = 'senderBuild/UPDATE_RECIPIENTS_DONE',
  UPDATE_RECIPIENTS_ERROR = 'senderBuild/UPDATE_RECIPIENTS_ERROR',
  SET_RECIPIENTS_LOADING = 'senderBuild/SET_RECIPIENTS_LOADING',
  FETCH_RECIPIENTS = 'senderBuild/FETCH_RECIPIENTS',
  FETCH_RECIPIENTS_DONE = 'senderBuild/FETCH_RECIPIENTS_DONE',
  ENABLE_TEXT_BLOCK_EDITING = 'senderBuild/ENABLE_TEXT_BLOCK_EDITING',
  DISABLE_TEXT_BLOCK_EDITING = 'senderBuild/DISABLE_TEXT_BLOCK_EDITING',
  FETCH_SIGNER_TOKEN = 'senderBuild/FETCH_SIGNER_TOKEN',
  FETCH_SIGNER_TOKEN_DONE = 'senderBuild/FETCH_SIGNER_TOKEN_DONE',
  CREATE_PRIME_CONTACT = 'senderBuild/CREATE_PRIME_CONTACT',
  FETCH_CONTACTS = 'senderBuild/FETCH_CONTACTS',
  FETCH_CONTACTS_DONE = 'senderBuild/FETCH_CONTACTS_DONE',
  VALIDATE_BLOCKS_BEFORE_SENDING = 'senderBuilds/VALIDATE_BLOCKS_BEFORE_SENDING',
  SHOW_MOBILE_SIGNING_ORDER = 'senderBuild/SHOW_MOBILE_SIGNING_ORDER',
  HIDE_MOBILE_SIGNING_ORDER = 'senderBuild/HIDE_MOBILE_SIGNING_ORDER',
  UPDATE_BLOCK_SIZING_CACHE = 'senderBuild/UPDATE_BLOCK_SIZING_CACHE',
  TOGGLE_READY_TO_SEND_MODAL = 'senderBuild/TOGGLE_READY_TO_SEND_MODAL',
  SET_MOUSE_COORDS = 'senderBuild/SET_MOUSE_COORDS',
  CLEAR_MOUSE_COORDS = 'senderBuild/CLEAR_MOUSE_COORDS',
  SET_BLOCK_MENU_POS = 'senderBluid/SET_BLOCK_MENU_POS',
  ADD_BLOCKS_BULK = 'senderBuild/ADD_BLOCKS_BULK',
  CLEAR_BLOCKS = 'senderBuild/CLEAR_BLOCKS',
}

export const reset = (newStore?: ISenderBuildState) => action(ActionTypes.RESET, newStore);
export const setAutoSaving = (val: boolean) => action(ActionTypes.SET_AUTOSAVING, val);
export const fetch = (id: string, resource: ResourceNames, userInfo: UserInfo) =>
  action(ActionTypes.FETCH, { id, resource, userInfo });

export const fetchDone = (result: IFetchDone, userInfo: UserInfo) =>
  action(ActionTypes.FETCH_DONE, {
    result,
    userInfo,
  });
export const fetchError = (error: string) => action(ActionTypes.FETCH_ERROR, error);
export const fetchEnvelopePdfs = () => action(ActionTypes.FETCH_ENVELOPE_PDFS);
export const fetchEnvelopePdfDone = (documentId: string) => action(ActionTypes.FETCH_ENVELOPE_PDF_DONE, documentId);
export const fetchEnvelopePdfError = () => action(ActionTypes.FETCH_ENVELOPE_PDF_ERROR);
export const sendEnvelope = () => action(ActionTypes.SEND_ENVELOPE);
export const sendEnvelopeDone = () => action(ActionTypes.SEND_ENVELOPE_DONE);
export const sendEnvelopeError = () => action(ActionTypes.SEND_ENVELOPE_ERROR);

export const zoomIn = () => action(ActionTypes.ZOOM_IN);
export const zoomOut = () => action(ActionTypes.ZOOM_OUT);
export const setZoom = (value: number) => action(ActionTypes.SET_ZOOM, value);

export const createNewBlock = (documentId: string, pageIndex: number, block: IBlock, select: boolean) =>
  action(ActionTypes.CREATE_NEW_BLOCK, { documentId, pageIndex, block, select });

export const createNewBlockDone = (documentId: string, pageIndex: number, tempId: string, blockId: string) =>
  action(ActionTypes.CREATE_NEW_BLOCK_DONE, { documentId, pageIndex, tempId, blockId });

export const editBlock = (documentId: string, pageIndex: number, blockIndex: number, updateObj: any) =>
  action(ActionTypes.EDIT_BLOCK, { documentId, pageIndex, blockIndex, updateObj });

export const removeGroupIdFromBlock = (documentId: string, pageIndex: number, blockIndex: number) =>
  action(ActionTypes.REMOVE_GROUP_ID_FROM_BLOCK, { documentId, pageIndex, blockIndex });

export const deleteBlocks = (documentId: string, pageIndex: number, blockIds: string[]) =>
  action(ActionTypes.DELETE_BLOCKS, {
    documentId,
    pageIndex,
    blockIds,
  });
export const deleteBlocksCancelled = () => action(ActionTypes.DELETE_BLOCKS_CANCELLED);

export const deleteBlocksDone = (documentId: string, pageIndex: number, blockIds: string[], resource: ResourceNames) =>
  action(ActionTypes.DELETE_BLOCKS_DONE, {
    documentId,
    pageIndex,
    blockIds,
    resource,
  });

export const selectBlocks = (
  documentId: string,
  pageIndex: number,
  blockIndices: number[],
  multiSelect?: boolean,
  selectThroughGroup?: boolean
) =>
  action(ActionTypes.SELECT_BLOCKS, {
    documentId,
    pageIndex,
    blockIndices,
    multiSelect,
    selectThroughGroup,
  });
export const copyBlocks = (blocks: IBlock[], startX: number, startY: number) =>
  action(ActionTypes.COPY_BLOCKS, { blocks, startX, startY });

export const pasteBlocks = (
  documentId: string,
  pageIndex: number,
  blocks: IBlock[],
  groups?: { [groupId: string]: IGroup }
) =>
  action(ActionTypes.PASTE_BLOCKS, {
    documentId,
    pageIndex,
    blocks,
    groups,
  });

export const pasteBlocksDone = (
  documentId: string,
  pageIndex: number,
  blocks: IBlock[],
  groups?: { [groupId: string]: IGroup }
) =>
  action(ActionTypes.PASTE_BLOCKS_DONE, {
    documentId,
    pageIndex,
    blocks,
    groups,
  });
export const changeActiveBlockType = (key: string) => action(ActionTypes.CHANGE_ACTIVE_BLOCK_TYPE, key);
export const changeActiveSigner = (key: string) => action(ActionTypes.CHANGE_ACTIVE_SIGNER, key);

export const createGroup = (group: IGroup, newBlocks: IBlock[], initialBlockId?: string) =>
  action(ActionTypes.CREATE_GROUP, { group, initialBlockId, newBlocks });

export const createGroupDone = (group: IGroup, newBlocks: IBlock[], initialBlockId?: string) =>
  action(ActionTypes.CREATE_GROUP_DONE, { group, initialBlockId, newBlocks });

export const editBlockGroup = (
  groupId: string,
  templateId: string,
  documentId: string,
  pageIndex: number,
  updateObj: any
) => action(ActionTypes.EDIT_GROUP, { groupId, templateId, documentId, pageIndex, updateObj });

export const deleteBlockGroupDone = (groupId: string, documentId: string, pageIndex: number) =>
  action(ActionTypes.DELETE_BLOCK_GROUP_DONE, { groupId, documentId, pageIndex });

export const addNewBlocksToGroup = (group: IGroup, newBlocks: IBlock[]) =>
  action(ActionTypes.ADD_NEW_BLOCKS_TO_GROUP, { group, newBlocks });

export const addNewBlocksToGroupDone = (group: IGroup, newBlocks: IBlock[]) =>
  action(ActionTypes.ADD_NEW_BLOCKS_TO_GROUP_DONE, { group, newBlocks });

export const setPageDimensions = (documentId: string, pageIndex: number, pageHeight: number, pageWidth: number) =>
  action(ActionTypes.SET_PAGE_DIMENSIONS, { documentId, pageIndex, pageHeight, pageWidth });

export const createNewSigningGroup = (group: ISigningGroup) => action(ActionTypes.CREATE_NEW_SIGNING_GROUP, { group });

export const editSigner = (signerId: string, updateObj: any) =>
  action(ActionTypes.EDIT_SIGNER, {
    signerId,
    updateObj,
  });

export const updateEmailConfig = (
  envelopeId: string,
  emailConfig: { subject: string; body: string; hasAttachment?: boolean },
  sendEnvelope?: boolean
) => action(ActionTypes.UPDATE_EMAIL_CONFIG, { envelopeId, emailConfig, sendEnvelope });

export const updateEmailConfigSuccess = (subject: string, body: string) =>
  action(ActionTypes.UPDATE_EMAIL_CONFIG_DONE, { subject, body });

export const updateEmailConfigError = () => action(ActionTypes.UPDATE_EMAIL_CONFIG_ERROR);

export const copyBlocksToCache = (blocks: IBlock[]) => action(ActionTypes.COPY_BLOCKS_TO_CACHE, { blocks });

export const setCurrentPage = (pageNumber: number) => action(ActionTypes.SET_CURRENT_PAGE, { pageNumber });

export const setCurrentDocument = (documentId: string) => action(ActionTypes.SET_CURRENT_DOCUMENT, { documentId });

export const focusInput = () => action(ActionTypes.FOCUS_INPUT);

export const blurInput = () => action(ActionTypes.BLUR_INPUT);

export const updateRecipients = (recipients: IRecipient[], envelopeId: string, userInfo: UserInfo) =>
  action(ActionTypes.UPDATE_RECIPIENTS, { recipients, envelopeId, userInfo });

export const updateRecipientsError = () => action(ActionTypes.UPDATE_RECIPIENTS_ERROR);

export const updateRecipientsDone = (res: IRecipient[], userInfo: UserInfo) =>
  action(ActionTypes.UPDATE_RECIPIENTS_DONE, {
    res,
    userInfo,
  });

export const setRecipientsAndSignerGroupsModal = (recipients: IRecipient[], signingGroups: ISigningGroup[]) =>
  action(ActionTypes.SET_RECIPIENTS_AND_SIGNING_GROUPS_MODAL, { recipients, signingGroups });

export const setRecipientsLoading = (loading: boolean) => action(ActionTypes.SET_RECIPIENTS_LOADING, loading);

export const fetchRecipients = (envelopeId: string) => action(ActionTypes.FETCH_RECIPIENTS, { envelopeId });
export const fetchRecipientsDone = (recipients: IRecipient[]) =>
  action(ActionTypes.FETCH_RECIPIENTS_DONE, { recipients });

export const enableTextEditing = () => action(ActionTypes.ENABLE_TEXT_BLOCK_EDITING);
export const disableTextEditing = () => action(ActionTypes.DISABLE_TEXT_BLOCK_EDITING);

export const fetchSignerToken = (requestBody: { envelopeId: string; signerId: string; signerEmail: string }) =>
  action(ActionTypes.FETCH_SIGNER_TOKEN, { requestBody });
export const fetchSignerTokenDone = (signerToken: string) => action(ActionTypes.FETCH_SIGNER_TOKEN_DONE, signerToken);

export const createPrimeContact = (contact: { firstName: string; lastName: string; email: string }) =>
  action(ActionTypes.CREATE_PRIME_CONTACT, contact);
export const fetchContacts = () => action(ActionTypes.FETCH_CONTACTS);
export const fetchContactsDone = (contacts: any[]) => action(ActionTypes.FETCH_CONTACTS_DONE, { contacts });
export const validateBlocksBeforeSending = (
  blocks: IBlock[],
  groups: IGroup[],
  emailConfig: { subject: string; body: string; hasAttachment?: boolean },
  sendEnvelope?: boolean
) => action(ActionTypes.VALIDATE_BLOCKS_BEFORE_SENDING, { blocks, groups, emailConfig, sendEnvelope });
export const showMobileSigningOrder = () => action(ActionTypes.SHOW_MOBILE_SIGNING_ORDER);
export const hideMobileSigningOrder = () => action(ActionTypes.HIDE_MOBILE_SIGNING_ORDER);
export const updateBlockSizingCache = (height: number, width: number, blockType: string) =>
  action(ActionTypes.UPDATE_BLOCK_SIZING_CACHE, { height, width, blockType });
export const toggleReadyToSendModal = () => action(ActionTypes.TOGGLE_READY_TO_SEND_MODAL);
export const setMouseCoords = (x: number, y: number) => action(ActionTypes.SET_MOUSE_COORDS, { x, y });
export const clearMouseCoords = () => action(ActionTypes.CLEAR_MOUSE_COORDS);
export const setBlockMenuPos = (x: number, y: number) => action(ActionTypes.SET_BLOCK_MENU_POS, { x, y });

export const addBlocksBulk = (blocks?: IBlock[]) => action(ActionTypes.ADD_BLOCKS_BULK, { blocks });
export const clearBlocks = (envelopeId: number, documentIds: string[]) =>
  action(ActionTypes.CLEAR_BLOCKS, { envelopeId, documentIds });
