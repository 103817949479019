import { ActionTypes } from './actions';
import update from 'immutability-helper';
import { IEnvelopeDocumentsState } from './types';

export const initialState: IEnvelopeDocumentsState = {
  envelope: undefined,
  isFetching: true,
  fetchError: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_ENVELOPE_DONE: {
      const envelope = action.payload;
      return update(state, {
        envelope: { $set: envelope },
        isFetching: { $set: false },
      });
    }

    case ActionTypes.UPLOAD_DOCUMENT: {
      const newDocument = {
        fileName: action.payload.file.name,
        url: '',
        documentId: action.payload.tempId,
        numberOfPages: 0,
        blocks: [],
        blockGroups: [],
        isUploading: true,
      };

      return update(state, { envelope: { documents: { $push: [newDocument] } } });
    }

    case ActionTypes.UPLOAD_DOCUMENT_DONE: {
      const i = state.envelope!.documents.findIndex((d) => d.documentId === action.payload.tempId);
      return update(state, {
        envelope: {
          documents: {
            [i]: {
              documentId: { $set: action.payload.document.documentId },
              numberOfPages: { $set: action.payload.document.numberOfPages },
              isUploading: { $set: false },
            },
          },
        },
      });
    }

    case ActionTypes.UPDATE_DOCUMENTS: {
      return update(state, {
        envelope: {
          documents: { $set: action.payload },
        },
      });
    }

    case ActionTypes.REORDER_DOCUMENTS: {
      return update(state, {
        envelope: {
          documents: { $set: action.payload },
        },
      });
    }

    case ActionTypes.DELETE_DOCUMENT: {
      const index = state.envelope!.documents.findIndex((d) => d.documentId === action.payload);
      return update(state, {
        envelope: {
          documents: { $splice: [[index, 1]] },
        },
      });
    }

    case ActionTypes.API_ERROR: {
      return { ...state, isFetching: false, fetchError: true };
    }

    default: {
      return state;
    }
  }
};
