import React, { useState, useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { validateInput } from '@digisign3-ui/utils';
import { IRecipientBody, IEnvelopeHistoryBody } from '../../store/envelopeHistory/types';
import * as envelopeHistoryActions from '../../store/envelopeHistory/actions';
import { updateEnvelopes } from '../../store/envelopeManagement/actions';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import { getRecipientGroup } from '../../lib/utils';
import { IEnvelopeMetadata } from '../../store/envelopeManagement/types';
import RecipientTextField from '../../common/recipients/recipientList/RecipientTextField';
import { IContact } from '../../lib/types';

interface IState {
  envelope: IEnvelopeHistoryBody;
  envelopeManagementEnvelopes: IEnvelopeMetadata[];
  updateRecipientsError: boolean | null;
}

interface IProps {
  recipient: IRecipientBody;
  setRecipientToEdit: Function;
  disabled?: boolean;
  contacts: IContact[];
}

const selector = (state: IRootState) => ({
  envelope: state.envelopeHistory.envelope,
  envelopeManagementEnvelopes: state.envelopeManagement.envelopes,
  updateRecipientsError: state.envelopeHistory.updateRecipientsError,
  contacts: state.envelopeHistory.contacts,
});

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: '20px',
  },
  nameWrapper: {
    display: 'flex',
  },
  actionButtons: {
    paddingTop: '56px',
    justifyContent: 'flex-end',
  },
  spacer: {
    width: '25px',
  },
}));

const EditRecipientForm = (props: IProps) => {
  const classes = useStyles();
  const { recipient, disabled } = props;
  const { envelope, envelopeManagementEnvelopes, updateRecipientsError }: IState = useShallowSelector(selector);
  const [firstname, setFirstname] = useState(recipient.firstName || '');
  const [lastname, setLastname] = useState(recipient.lastName || '');
  const [email, setEmail] = useState(recipient.email || '');
  const dispatch = useDispatch();
  const { recipients } = envelope;

  const [error, setError] = useState({
    firstname: false,
    lastname: false,
    email: false,
  });

  useEffect(() => {
    if (updateRecipientsError === false) {
      props.setRecipientToEdit(null);
    }
    return () => {
      if (updateRecipientsError !== null) {
        dispatch(envelopeHistoryActions.updateRecipientsError(null));
      }
    };
  }, [updateRecipientsError]);

  const handleEditRecipient = () => {
    let recipientToUpdate;
    const updatedRecipients = recipients.map((r) => {
      const group = getRecipientGroup(r, envelope);
      if (r.id === recipient.id) {
        recipientToUpdate = { ...recipient, firstName: firstname, lastName: lastname, email, group };
        return recipientToUpdate;
      }
      return { ...r, group };
    });

    dispatch(envelopeHistoryActions.updateRecipient(recipientToUpdate, updatedRecipients, envelope.id));

    const updatedEnvelopes = envelopeManagementEnvelopes.map((e) => {
      if (e.id === envelope.id) {
        e.recipients = updatedRecipients;
      }
      return e;
    });

    dispatch(updateEnvelopes(updatedEnvelopes));
  };

  const areThereAnyErrors = () => {
    return Object.values(error).includes(true);
  };

  const validateInputValue = (e: any, str: string) => {
    return setError({ ...error, [str]: validateInput(e, str) });
  };

  const handleContactSelect = (e: any, queryType: string) => {
    if (e) {
      setFirstname(e.firstName);
      setLastname(e.lastName);
      setEmail(e.email);
      validateInputValue(e.firstName, 'firstname');
      validateInputValue(e.lastName, 'lastname');
      validateInputValue(e.email, 'email');
    } else if (queryType === 'firstName') {
      setFirstname('');
    } else if (queryType === 'lastName') {
      setLastname('');
    } else {
      setEmail('');
    }
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="body2">Edit recipient details</Typography>
      <div className={classes.nameWrapper}>
        <div style={{ flex: 1 }}>
          <RecipientTextField
            autoFocus
            label="First Name"
            helperText={(error.firstname && 'Please enter a first name.') as string}
            placeholder="First Name"
            handleOnChange={(e) => {
              setFirstname(e.target.value);
              validateInputValue(e.target.value, 'firstname');
            }}
            error={error.firstname}
            value={firstname}
            dataSpec="edit-recipient-firstname"
            name="edit-recipient-firstname"
            disabled={disabled}
            contacts={props.contacts}
            handleContactSelect={handleContactSelect}
          />
        </div>
        <div className={classes.spacer} />
        <div style={{ flex: 1 }}>
          <RecipientTextField
            label="Last Name"
            helperText={(error.lastname && 'Please enter a last name.') as string}
            placeholder="Last name"
            handleOnChange={(e) => {
              setLastname(e.target.value);
              validateInputValue(e.target.value, 'lastname');
            }}
            error={error.lastname}
            value={lastname}
            dataSpec="edit-recipient-lastname"
            disabled={disabled}
            contacts={props.contacts}
            handleContactSelect={handleContactSelect}
          />
        </div>
      </div>
      <RecipientTextField
        label="Email"
        helperText={(error.email && 'Please enter an email.') as string}
        placeholder="Email"
        handleOnChange={(e) => {
          setEmail(e.target.value);
          validateInputValue(e.target.value, 'email');
        }}
        error={error.email}
        value={email}
        dataSpec="edit-recipient-email"
        disabled={disabled}
        contacts={props.contacts}
        handleContactSelect={handleContactSelect}
      />

      <DialogActions classes={{ root: classes.actionButtons }}>
        <Button
          data-spec="cancel"
          variant="text"
          style={{ marginRight: '24px' }}
          color="primary"
          onClick={() => props.setRecipientToEdit(null)}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          data-spec="saveButton"
          disabled={areThereAnyErrors()}
          onClick={handleEditRecipient}
          data-spect="edit-recipient-save-btn"
        >
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default EditRecipientForm;
