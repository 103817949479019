import { action } from 'typesafe-actions';
import { IRecipient } from '../../../common/recipients/types';
import { IFile } from '../../../dtm/types';
import { IRecipientsState } from './types';

export enum ActionTypes {
  FETCH = 'dtmRecipients/FETCH',
  FETCH_DONE = 'dtmRecipients/FETCH_DONE',
  UPDATE_RECIPIENTS = 'dtmRecipients/UPDATE_RECIPIENTS',
  UPDATE_OPTIONAL_RECIPIENTS = 'dtmRecipients/UPDATE_OPTIONAL_RECIPIENTS',
  SAVE = 'dtmRecipients/SAVE',
  SAVE_DONE = 'dtmRecipients/SAVE_DONE',
  RESET = 'dtmRecipients/RESET',
  FETCH_CONTACTS = 'dtmRecipients/FETCH_CONTACTS',
  FETCH_CONTACTS_DONE = 'dtmRecipients/FETCH_CONTACTS_DONE',
  CLEAR_CONTACTS = 'dtmRecipients/CLEAR_CONTACTS',
  CREATE_PRIME_CONTACT = 'dtmRecipients/CREATE_PRIME_CONTACT',
}

export const reset = (newStore?: IRecipientsState) => action(ActionTypes.RESET, newStore);
export const fetch = (envelopeId: string, impersonatedPrimeUserGuid?: string) =>
  action(ActionTypes.FETCH, { envelopeId, impersonatedPrimeUserGuid });
export const fetchDone = (file: IFile, optionalRecipients: IRecipient[], recipients: IRecipient[]) =>
  action(ActionTypes.FETCH_DONE, { file, optionalRecipients, recipients });

export const updateRecipients = (recipients: IRecipient[]) => action(ActionTypes.UPDATE_RECIPIENTS, recipients);
export const updateOptionalRecipients = (ors: IRecipient[]) => action(ActionTypes.UPDATE_OPTIONAL_RECIPIENTS, ors);

export const save = (envelopeId: string) => action(ActionTypes.SAVE, envelopeId);
export const saveDone = () => action(ActionTypes.SAVE_DONE);
export const fetchContacts = () => action(ActionTypes.FETCH_CONTACTS);
export const fetchContactsDone = (contacts: any) => action(ActionTypes.FETCH_CONTACTS_DONE, contacts);
export const clearContacts = () => action(ActionTypes.CLEAR_CONTACTS);

export const createPrimeContact = (contact: { firstName: string; lastName: string; email: string }) =>
  action(ActionTypes.CREATE_PRIME_CONTACT, contact);
