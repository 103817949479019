import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '464px',
    width: '1201px',
    backgroundColor: 'white',
    border: '1px solid #CAD5E0',
    justifyContent: 'space-evenly',
  },
  textWrapper: {
    width: '505px',
  },
  subtitle: {
    margin: '5px 0px 32px 0px',
    fontSize: '19px',
    width: '494px',
  },
  icon: {
    width: '300px',
  },
}));

interface IProps {
  icon: any;
  heading: string;
  subtitle: string;
  buttonText?: string;
  actionButton: () => void;
  headAria?: string;
  subAria?: string;
  buttAria?: string;
}

const EmptyState = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <img className={classes.icon} src={props.icon} />
      </div>
      <div className={classes.textWrapper}>
        <Typography variant="h2" aria-label={props.headAria}>
          {props.heading}
        </Typography>
        <Typography classes={{ body1: classes.subtitle }} variant="body1" aria-label={props.subAria}>
          {props.subtitle}
        </Typography>
        {props.buttonText ? (
          <Button
            variant="contained"
            color="primary"
            data-spec="empty-state-button"
            aria-label={props.buttAria}
            onClick={() => props.actionButton()}
          >
            {props.buttonText}
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default EmptyState;
