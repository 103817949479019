export interface IDoc {
  isUploading: boolean;
  documentId: string;
  fileName: string;
  numberOfPages: number;
  uploadStatus: DocumentStatus;
}

export enum DocumentStatus {
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Failed = 'Failed',
}
