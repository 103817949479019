import { createUserManager, UserManager, TokenDomain } from '@skyslope/auth-js';

import {
  azureFNFIDP,
  senderUIClientId,
  templateUIClientId,
  oktaEnv,
  primeAuthClientId,
  standaloneLoginUrl,
} from '../lib/constants';

const idpOktaIds = {
  fnf: azureFNFIDP,
};

const scopes = {
  prime: [
    'openid',
    'email',
    'profile',
    'com.skyslope.prime.accountid',
    'com.skyslope.digisign.userid',
    'com.skyslope.prime.subscriber',
    'com.skyslope.groups',
  ],
  forms: [
    'openid',
    'email',
    'profile',
    'com.skyslope.forms.accountid',
    'com.skyslope.digisign.userid',
    'com.skyslope.prime.subscriber',
    'com.skyslope.groups',
  ],
  fnf: [
    'openid',
    'email',
    'profile',
    'com.skyslope.digisign.userid',
    'com.skyslope.fnf.accountid',
    'com.skyslope.groups',
  ],
};

const getIdp = (): string | null => {
  const idpStorageKey = 'idp';
  const params = new URLSearchParams(window.location.search);
  const idp = params.get(idpStorageKey) ?? localStorage.getItem(idpStorageKey);
  if (idp) {
    localStorage.setItem(idpStorageKey, idp);
  }

  return idp;
};

export const getIdpOktaId: () => string | null = () => {
  const idp = getIdp();
  if (!idp || idp === 'none') {
    return null;
  }
  return idpOktaIds[idp];
};

export const getScopes: () => [string] = () => {
  if (document.referrer.includes('forms.skyslope') || document.referrer.includes('offers.skyslope')) {
    return scopes.forms;
  }
  const idp = getIdp();
  return idp ? scopes[idp] : scopes.prime;
};

const userManager = createUserManager({
  environment: oktaEnv,
  tokenDomain: TokenDomain.DigiSign,
  providers: {
    okta: {
      clientId: senderUIClientId,
      redirectPath: '/auth/callback',
      postLogoutRedirectPath: '/logged_out',
      scopes: getScopes(),
    },
    primeAuth: {
      clientId: primeAuthClientId,
      redirectPath: '/auth/callback/prime',
      postLogoutRedirectPath: '/logged_out',
    },
  },
  onAuthRequired: async (um: UserManager) => {
    const idp = getIdpOktaId();
    if (idp) {
      await um.login({ idp });
    } else if (standaloneLoginUrl) {
      // redirect to standalone login marketing page
      window.location.assign(standaloneLoginUrl);
    } else {
      // default okta login
      await um.login();
    }
  },
});

const userManagerTemplates = createUserManager({
  environment: oktaEnv,
  tokenDomain: TokenDomain.DigiSign,
  providers: {
    okta: {
      clientId: templateUIClientId,
      redirectPath: '/templates/auth/callback',
      postLogoutRedirectPath: '/templates/logged_out',
      scopes: [
        'openid',
        'email',
        'profile',
        'com.skyslope.digisign.userrole',
        'com.skyslope.digisign.accountid',
        'com.skyslope.digisign.userid',
        'com.skyslope.groups',
      ],
    },
  },
  onAuthRequired: async (um: UserManager) => {
    await um.login();
  },
});

export function getUserManager() {
  return /\/templates/.test(window.location.toString()) ? userManagerTemplates : userManager;
}

export async function getAccessToken() {
  const um = getUserManager();
  return um.getAccessToken();
}

export async function getGlobalToken() {
  const um = getUserManager();
  return um.getAccessToken(TokenDomain.Global);
}
