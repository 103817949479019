import React from 'react';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { StyledMenu, StyledMenuItem } from '../common/StyledMenu';
import { useDispatch } from '../../lib/reduxHooks';
import { createNewEnvelope } from '../../store/envelopeManagement/actions';
import useUserInfo from '../../auth/useUserInfo';

interface IProps {
  disabled?: boolean;
  isPrimeAuthenticated?: boolean;
  isPrimeUser?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    margin: '-4px 0 -4px 4px',
  },
}));

const NewEnvelopeButton = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const { impersonatedPrimeUserGuid } = useUserInfo() ?? {};

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const chooseProperty = () => {
    setAnchorEl(null);
    history.push('/dtm/properties');
  };

  const noProperty = () => {
    setAnchorEl(null);
    dispatch(createNewEnvelope(impersonatedPrimeUserGuid));
  };

  return (
    <>
      {props.isPrimeUser ? (
        <>
          <Button variant="contained" color="primary" onClick={handleClick} disabled={props.disabled}>
            New Envelope <ExpandMoreIcon className={classes.icon} />
          </Button>
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            getContentAnchorEl={null}
          >
            <StyledMenuItem onClick={chooseProperty} disabled={!props.isPrimeAuthenticated}>
              Choose property
            </StyledMenuItem>
            <StyledMenuItem onClick={noProperty}>No property</StyledMenuItem>
          </StyledMenu>
        </>
      ) : (
        <Button variant="contained" color="primary" onClick={noProperty} disabled={props.disabled}>
          New Envelope
        </Button>
      )}
    </>
  );
};

export default NewEnvelopeButton;
