import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserManager } from '../auth/oktaConfig';
import { standaloneLogoutRedirectUrl } from '../lib/constants';

const LoggedOut = () => {
  const history = useHistory();

  useEffect(() => {
    const um = getUserManager();
    um.endLogout().then(() => {
      if (localStorage.getItem('_isStandaloneUser')) {
        localStorage.removeItem('_isStandaloneUser');
        window.open(standaloneLogoutRedirectUrl, '_self');
      } else if (localStorage.getItem('idp') === 'fnf') {
        history.push('/');
      } else {
        history.push(window.location.pathname.includes('templates') ? '/templates' : '/envelopes');
      }
    });
  }, []);

  return null;
};

export default LoggedOut;
