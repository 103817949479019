import React from 'react';
import { useDroppable } from '@dnd-kit/core';

interface IProps {
  children: any;
  id: string;
}

const Droppable = (props: IProps) => {
  const { children, id } = props;
  const { isOver, setNodeRef } = useDroppable({
    id,
  });
  const style = {
    color: isOver ? 'green' : undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default Droppable;
