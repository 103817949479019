import { ActionTypes } from './actions';
import { ITemplate, ITemplateCreateState } from './types';
import { IDocument } from '../senderBuild/types';
import update from 'immutability-helper';

export const initialState: ITemplateCreateState = {
  isFetching: true,
  template: undefined,
  fetchError: false,
  showAutoSaveIndicator: false,
  hasSavedAtLeastOnce: false,
};

export default (state: ITemplateCreateState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.RESET: {
      return action.payload || { ...initialState };
    }
    case ActionTypes.FETCH_TEMPLATE_DONE: {
      const template: ITemplate = action.payload;
      return { ...state, isFetching: false, template };
    }
    case ActionTypes.UPDATE_TEMPLATE: {
      return { ...state, showAutoSaveIndicator: true };
    }
    case ActionTypes.UPDATE_TEMPLATE_DONE: {
      return { ...state, showAutoSaveIndicator: false, hasSavedAtLeastOnce: true };
    }
    case ActionTypes.UPLOAD_DOCUMENT: {
      const newDocument: IDocument = {
        documentId: action.payload.tempId,
        fileName: action.payload.file.name,
        blocks: [],
        blockGroups: [],
        isUploading: true,
      };
      return update(state, { template: { documents: { $push: [newDocument] } } });
    }
    case ActionTypes.UPLOAD_DOCUMENT_DONE: {
      const i = state.template!.documents.findIndex((d) => d.documentId === action.payload.tempId);
      return update(state, {
        template: {
          documents: {
            [i]: {
              documentId: { $set: action.payload.document.id },
              order: { $set: action.payload.document.order },
              numberOfPages: { $set: action.payload.document.numPages },
              isUploading: { $set: false },
            },
          },
        },
      });
    }
    case ActionTypes.ADD_RECIPIENT: {
      return update(state, {
        template: { recipients: { $push: [action.payload] } },
      });
    }
    case ActionTypes.DELETE_RECIPIENT_DONE: {
      const recipientId = action.payload;
      const filteredRecipients = state.template!.recipients.filter((r) => r.id !== recipientId);
      return update(state, {
        template: { recipients: { $set: filteredRecipients } },
      });
    }
    case ActionTypes.UPDATE_RECIPIENT: {
      return { ...state, showAutoSaveIndicator: true };
    }
    case ActionTypes.UPDATE_RECIPIENT_DONE: {
      const i = state.template?.recipients.findIndex((d) => d.id === action.payload.recipientId);
      return update(state, {
        showAutoSaveIndicator: { $set: false },
        hasSavedAtLeastOnce: { $set: true },
        template: {
          recipients: {
            [i]: { $set: action.payload.recipient },
          },
        },
      });
    }
    case ActionTypes.CHANGE_RECIPIENT_TITLE: {
      const i = state.template?.recipients.findIndex((d) => d.id === action.payload.recipientId);
      return update(state, {
        template: {
          recipients: {
            [i]: { recipientTitle: { $set: action.payload.title } },
          },
        },
      });
    }
    case ActionTypes.API_ERROR: {
      return { ...state, isFetching: false, showAutoSaveIndicator: false, fetchError: true };
    }

    case ActionTypes.REORDER_DOCUMENTS: {
      return update(state, {
        template: {
          documents: { $set: action.payload },
        },
      });
    }

    case ActionTypes.UPDATE_DOCUMENTS: {
      return update(state, {
        template: {
          documents: { $set: action.payload },
        },
      });
    }

    case ActionTypes.DELETE_DOCUMENT: {
      const index = state.template!.documents.findIndex((d) => d.documentId === action.payload);
      return update(state, {
        template: {
          documents: { $splice: [[index, 1]] },
        },
      });
    }
    default: {
      return state;
    }
  }
};
