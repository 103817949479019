import { UserOrigins } from '../../lib/types';

export interface IPageFrameState {
  loading: boolean;
  errorShow: boolean;
  successShow: boolean;
  msg: string;
  settings?: ISettings;
  settingsFetched: boolean;
  showAlert?: boolean;
  message?: string;
  alert?: IAlert;
  recipientColorCounter?: number;
  fetchedUserSubscription?: boolean;
  isFetchingUserInfo?: boolean;
  isUserInfoFetched?: boolean;
  fetchedGlobalToken?: boolean;
}

export interface IAlert {
  message: string;
  type: 'ERROR' | 'SUCCESS' | 'WARNING';
  visible: boolean;
}

export interface ISettings {
  account: IAccountSettings;
  user: IUserSettings;
}

export interface IAccountSettings {
  template?: ITemplateSettings;
  envelope?: IEnvelopeSettings;
  userOrigin?: UserOrigins;
}

export type AutoStampDateTimePreference = 'Date' | 'DateTime' | 'None' | undefined;
export interface IUserSettings {
  autoStampDateTime?: {
    preference?: AutoStampDateTimePreference;
  };
  keepBlocksSelected?: boolean;
  emailDocsAsAttachment?: boolean;
  subscription?: IUserSubscription;
  trial?: IUserTrial;
  dateFormat?: IDateFormat;
  memberships?: IUserMemberships;
  automatedReminders?: {
    enabled: boolean;
    updatedDate?: string;
  };
}
export interface IDateFormat {
  preference: DateFormatType;
  selectionModalViewed?: boolean;
}

export enum DateFormatType {
  Default = 'MM/DD/YYYY',
  DayMonthYear = 'DD/MM/YYYY',
  YearMonthDay = 'YYYY/MM/DD',
}

export interface IUserSubscription {
  type: string;
  created: string;
  validUntil: string;
  subscriptionId: string;
}

export interface IUserTrial {
  endDate: string | Date;
  daysLeftInTrial: number;
  extensions?: TrialExtension[];
}

export type TrialExtension = {
  startDate: Date;
  endDate: Date | null;
  envelopesLeft: number;
};

export type ITrialBannerStorageObject = {
  dismissed: boolean;
  dismissalExpiration?: number;
} | null;

export interface ITemplateSettings {
  tagging?: ITaggingSettings;
  blockTypes?: ISettingsBlockTypes;
  recipientTitles?: string[];
}

export interface IEnvelopeSettings {
  blockTypes?: ISettingsBlockTypes;
}

export interface ISettingsBlockTypes {
  disabled: string[];
}

export interface ITaggingSettings {
  enabled: boolean;
  tagTypes: ITagType[];
}

export interface ITagType {
  id: string;
  name: string;
  displayType: 'AUTOCOMPLETE' | 'DROPDOWN';
  options: string[];
  iconName?: string;
  placeholderText?: string;
  helperText?: string;
}

export interface IUserMemberships {
  permissions: any[];
}

export enum PermissionType {
  DS3 = 'ds3.*',
  FORMS = 'forms.*',
}
