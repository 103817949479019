import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ModalCancelButton, ModalConfirmationButton } from '../../common/Modal';
import { dateFormatPreferenceOptions } from '../../lib/preferencePanels';

const useStyles = makeStyles(() => ({
  checked: {
    color: `${colors.blue[800]} !important`,
  },
  borderTop: {
    borderTop: `1px solid ${colors.grey[300]}`,
  },
}));

const RadioLabel = (props: { option: any }) => {
  const { option } = props;
  const mainLabelText = (
    <>
      <span className="font-bold text-[19px] tracking-[.16px]">{option.value} </span>
      {option.isDefault && <span className="text-[19px]">(default)</span>}
    </>
  );
  return (
    <div className="pl-3">
      {mainLabelText}
      <br />
      <span className="text-['16px'] whitespace-normal break-words">{option.subLabel}</span>
    </div>
  );
};

const header = 'Which date format would you like to set as your default?';
const subText = 'You will only see this message once. You can update these settings any time in your preferences.';

interface IProps {
  initialSelection: string;
  updateDateFormat: (val: string) => void;
}

export const TimeStampPreferenceModal = (props: IProps) => {
  const [currentSelection, setCurrentSelection] = useState(props.initialSelection);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSelection((e.target as HTMLInputElement).value);
  };
  const classes = useStyles();
  const setDateFormatPreference = props.updateDateFormat;
  return (
    <Dialog open disableBackdropClick aria-labelledby="responsive-dialog-title">
      <div className="pt-10 pb-4 px-10">
        <Typography variant="h3"> {header} </Typography>
        <DialogContent className="p-0">
          <Typography className="pt-3 pb-2">{subText}</Typography>
          <FormControl className="w-full" component="fieldset">
            <RadioGroup
              value={currentSelection}
              onChange={handleChange}
              data-spec="format_options"
              data-testid="format_options"
            >
              {dateFormatPreferenceOptions.map((o, index) => {
                const className = `mr-2 items-start px-0 py-7 hover:bg-[${colors.blue[50]}] ${
                  index > 0 ? classes.borderTop : ''
                }`;
                return (
                  <FormControlLabel
                    className={className}
                    value={o.value}
                    control={
                      <Radio
                        classes={{ checked: classes.checked }}
                        color="primary"
                        style={{ color: colors.grey[600] }}
                      />
                    }
                    label={<RadioLabel option={o} />}
                    id={o.dataSpec}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ModalConfirmationButton
            onClick={() => setDateFormatPreference(currentSelection)}
            aria-label="Finish date format selection"
            id="dateFormatModalFinish"
          >
            Finish
          </ModalConfirmationButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default TimeStampPreferenceModal;
