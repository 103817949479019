import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import Split from 'react-split';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ResourceNames, SHELF_WIDTH, chameleonElId } from '../../lib/constants';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';
import EmailSettings from './EmailSettings';
import Signers from './Signers';
import DocumentList from './DocumentList';
// @ts-ignore
import shelfDragSVG from '../../images/shelf_drag.svg';
import { LaunchDarklyFlags, withLaunchDarkly } from '../../common/launchDarkly';

const DEFAULT_SIZES = [33.3, 33.3, 33.3];
const HEADER_SIZE = 56;

const useStyles = makeStyles((theme: Theme) => ({
  shelf: {
    position: 'fixed',
    left: 0,
    top: '156px',
    bottom: 0,
    width: SHELF_WIDTH,
    textAlign: 'left',
    borderRight: `1px solid ${colors.grey[400]}`,
    color: colors.blue[900],
    display: 'flex',
    zIndex: 0,
    flexDirection: 'column',
    overflowY: 'auto',
  },
  header: {
    height: 60,
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  section: {
    position: 'relative',
    overflow: 'hidden',
  },
  legalLinksSection: {
    borderTop: `1px solid ${colors.grey[400]}`,
    display: 'flex',
    minHeight: 64,
    alignItems: 'center',
    flexDirection: 'column',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    alignItems: 'center',
    height: HEADER_SIZE,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '0 24px',
  },
  sectionBody: {
    overflowY: 'auto',
    position: 'absolute',
    top: HEADER_SIZE,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 24px',
  },
  menuIcon: {
    marginRight: '-20px',
    color: colors.grey[500],
  },
  '@global .gutter': {
    background: `url(${shelfDragSVG})`,
    backgroundRepeatY: 'no-repeat',
    backgroundPositionY: 'center',
    cursor: 'row-resize',
    backgroundRepeat: 'no-repeat',
  },
  legalLinks: {
    display: 'inline-block',
    marginTop: 20,
    flex: 'auto',
  },
  anchor: {
    color: colors.grey[800],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    fontSize: 13,
  },
  spacer: {
    padding: '0 12px',
    fontSize: 13,
    display: 'inline-block',
  },
  wrapper: {
    position: 'fixed',
    // Leaving this here in case UX decides move it back to the top of the page -CH
    // top: theme.spacing(2)
    bottom: 12,
    right: theme.spacing(2),
    zIndex: 1200, // material app bar has a high z-index
    justifyContent: 'flex-end',
    display: 'flex',
    width: 'calc(100VW - 400px)',
  },
}));

const envelopeShelfSelector = (state: IRootState) => ({
  resource: state.senderBuild.resource,
  numRecipients: state.senderBuild.signers.length + state.senderBuild.carbonCopy?.length,
  areLegalLinksVisible: state.senderBuild.config.areLegalLinksVisible,
});

interface IState {
  resource: ResourceNames;
  numRecipients: number;
  areLegalLinksVisible: boolean;
}

const LegalLinks = () => {
  const { areLegalLinksVisible }: IState = useShallowSelector(envelopeShelfSelector);
  const classes = useStyles();
  return !areLegalLinksVisible ? null : (
    <div className={classes.legalLinksSection}>
      <div className={classes.legalLinks} data-spec="legalLinks">
        <a className={classes.anchor} href="https://support.skyslope.com/hc/en-us" target="blank">
          Support
        </a>
        <span className={classes.spacer}>|</span>
        <a className={classes.anchor} href="https://skyslope.com/terms-conditions/" target="blank">
          Terms
        </a>
        <span className={classes.spacer}>|</span>
        <a className={classes.anchor} href="https://skyslope.com/privacy-policy/" target="blank">
          Privacy
        </a>
      </div>
    </div>
  );
};

const EnvelopeShelf = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const { resource, numRecipients }: IState = useShallowSelector(envelopeShelfSelector);
  const classes = useStyles();
  const envelopeResource = ResourceNames.ENVELOPE; // babel dies when i put this in the JSX
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenu] = React.useState('');
  const splitRef = React.useRef<any>(null);
  const autoSizeSection1Ref = React.useRef<HTMLDivElement>(null);
  const autoSizeSection2Ref = React.useRef<HTMLDivElement>(null);
  const [shelfSizes, setShelfSizes] = React.useState(DEFAULT_SIZES);
  const isEnvelopePage = resource === envelopeResource;
  const hideNavItems = flags && flags['hide-build-ui-elements'];

  const calculateShelfSplit = () => {
    const shelfHeight = splitRef.current!.parent.clientHeight;
    const autosizeSectionHeights = [
      autoSizeSection1Ref.current!.clientHeight + 80,
      autoSizeSection2Ref.current!.clientHeight + 80,
    ];
    let total = 0;
    const newSizes = autosizeSectionHeights.map((sectionHeight: number) => {
      const percent = Math.round((sectionHeight / shelfHeight) * 100);
      total += percent;
      return percent;
    });
    newSizes.push(100 - total);
    setShelfSizes(newSizes);
  };

  useEffect(calculateShelfSplit, [numRecipients]);

  const openMenu = (e: React.MouseEvent<HTMLElement>, clickedMenu: string) => {
    setMenuAnchor(e.currentTarget);
    setOpenedMenu(clickedMenu);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
    setOpenedMenu('');
  };

  return (
    <div className={classes.shelf}>
      <div className={classes.header}>
        <Typography variant="h6">{resource === envelopeResource ? 'Envelope' : 'Template'} Details</Typography>
      </div>
      <Split
        ref={splitRef}
        className={classes.body}
        direction="vertical"
        minSize={HEADER_SIZE}
        sizes={isEnvelopePage && !hideNavItems ? shelfSizes : [50, 50]}
        expandToMin
        gutterSize={16}
        cursor="row-resize"
      >
        <div className={classes.section} id={chameleonElId(5)}>
          <div className={classes.sectionHeader}>
            <Typography variant="overline">DOCUMENTS</Typography>
          </div>
          <div className={classes.sectionBody}>
            <div ref={autoSizeSection1Ref}>
              <DocumentList />
            </div>
          </div>
        </div>
        <div className={classes.section} id={chameleonElId(6)}>
          <div className={classes.sectionHeader}>
            <Typography variant="overline">RECIPIENTS</Typography>
            {resource !== ResourceNames.TEMPLATE && !hideNavItems && (
              <IconButton
                onClick={(e) => openMenu(e, 'recipients')}
                aria-controls="recipients-menu"
                aria-haspopup="true"
                data-spec="recipientsMenu"
                className={classes.menuIcon}
                tabIndex={0}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.sectionBody}>
            <div ref={autoSizeSection2Ref}>
              <Signers menuAnchor={openedMenu === 'recipients' ? menuAnchor! : null} closeMenu={closeMenu} />
            </div>
          </div>
        </div>
        {isEnvelopePage && !hideNavItems ? (
          <div className={classes.section} id={chameleonElId(7)}>
            <div className={classes.sectionHeader}>
              <Typography variant="overline">OUTGOING EMAIL</Typography>
              <IconButton
                onClick={(e) => openMenu(e, 'email')}
                aria-controls="email-menu"
                aria-haspopup="true"
                className={classes.menuIcon}
                data-spec="outgoingEmailMenu"
                tabIndex={0}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
            <div className={classes.sectionBody}>
              <EmailSettings menuAnchor={openedMenu === 'email' ? menuAnchor! : null} closeMenu={closeMenu} />
            </div>
          </div>
        ) : null}
      </Split>
      <LegalLinks />
    </div>
  );
};

export default withLaunchDarkly(EnvelopeShelf);
