import { useState, useEffect } from 'react';

type BreakPoints = {
  [key: string]: string;
};

/**
 * Custom hook for observing breakpoints
 * @param breakPoints An object containing breakpoint names and their corresponding media queries
 * @returns The current breakpoint
 */
export function useBreakPointObserver(breakPoints: BreakPoints): string | undefined {
  const [breakPoint, setBreakPoint] = useState<string | undefined>();

  useEffect(() => {
    // Function to match media query and set breakpoint
    const matchMediaQuery = () => {
      for (const [key, query] of Object.entries(breakPoints)) {
        if (window.matchMedia(query).matches) {
          setBreakPoint(key);
          break;
        }
      }
    };

    // Initial check
    matchMediaQuery();

    // Set up event listener
    window.addEventListener("resize", matchMediaQuery);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", matchMediaQuery);
    };
  }, [breakPoints]); // Only re-run if breakPoints change

  return breakPoint;
}
