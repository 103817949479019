import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { colors } from '@skyslope/mache';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import emailValidator from 'email-validator';
import { v4 as uuid } from 'uuid';
import { validateInput } from '@digisign3-ui/utils';
import { ReactComponent as PenIcon } from '../../../images/pen.svg';
import { ReactComponent as TrashIcon } from '../../../images/trash.svg';
import Recipient from './Recipient';
import RecipientSearch from './RecipientSearch';
import RecipientAddOrEdit from './RecipientAddOrEdit';
import { IGroup, IRecipient, RecipientRole, IContact } from '../types';
import StyledTooltip from '../../StyledTooltip';
import PlaceholderRecipient from './RecipientPlaceholder';
import { chameleonElId, checkIfRecipientIsSender } from '../../utils';
import { applyColorAndCounterToNewRecipient, getRecipientColor } from '@digisign3-ui/sender/src/lib/utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '32px 24px',
    overflow: 'auto',
  },
  addIcon: {
    height: 20,
    width: 20,
  },
  recipient: (props: Props) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: props.optionalRecipients?.length ? (!props.modal ? 40 : 0) : 0,
  }),
  buttonWrapper: {
    marginTop: theme.spacing(4),
    width: '100%',
    textAlign: 'left',
  },
  button: {
    padding: 0,
    color: colors.blue[800],
  },
  iconWrapper: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridAutoFlow: 'column',
    paddingLeft: theme.spacing(2),
  },
  icon: {
    width: 24,
    height: 24,
  },
  recipientsContainer: {
    display: 'grid',
    gridGap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  buttonIcon: {
    margin: -8,
    padding: 8,
    color: colors.grey[500],
    '&:hover': {
      color: colors.blue[800],
    },
  },
  activeCheckbox: {
    margin: '-8px 8px -8px -48px',
    padding: 8,
    color: colors.blue[800],
  },
  inactiveCheckbox: {
    padding: 8,
  },
  tooltipBox: {
    margin: '-8px 8px -8px -48px',
  },
}));

type Props = {
  setRecipients: (recipients: IRecipient[]) => void;
  recipients: IRecipient[];
  signingGroups: IGroup[];
  lastGroupEmpty: boolean;
  contacts: any[];
  optionalRecipients?: IRecipient[];
  incompletePlaceholders?: IRecipient[];
  createPrimeContact?: (contact: { firstName: string; lastName: string; email: string }) => void;
  isPrimeUser?: boolean;
  modal?: boolean;
  disableRecipientList?: boolean;
  colorCounter?: number;
};

const OptionalRecipient = (props: {
  recipient: IRecipient;
  allRecipients: IRecipient[];
  toggleOptionalRecipient: any;
  editRecipient: any;
  classes: any;
  firstRecipient: boolean;
  modal: boolean;
}) => {
  const { classes, toggleOptionalRecipient, editRecipient, firstRecipient, modal, allRecipients } = props;
  const or = allRecipients.find((recipient) => recipient.id === props.recipient.id) || props.recipient;
  const validRecipient = emailValidator.validate(or.email) && or.firstName?.trim().length > 0;
  return (
    <div className={classes.recipient} key={or.id}>
      {!modal ? (
        <>
          {' '}
          {validRecipient ? (
            <Checkbox
              color="primary"
              className={classes.activeCheckbox}
              checked={or.isActive}
              onClick={() => toggleOptionalRecipient(or)}
              id={firstRecipient ? chameleonElId(13) : ''}
            />
          ) : (
            <StyledTooltip title="You must edit this recipient before adding them" arrow>
              <span className={classes.tooltipBox}>
                <Checkbox color="primary" className={classes.inactiveCheckbox} disabled />
              </span>
            </StyledTooltip>
          )}
        </>
      ) : (
        ''
      )}

      <Recipient
        firstName={or.firstName}
        lastName={or.lastName}
        color={or.color}
        isSender={checkIfRecipientIsSender(or)}
        title={or.title}
      />
      <div className={classes.iconWrapper}>
        <IconButton
          color="primary"
          onClick={() => editRecipient(or)}
          aria-label="edit recipient"
          className={classes.buttonIcon}
          id={firstRecipient ? chameleonElId(14) : ''}
        >
          <PenIcon className={classes.icon} />
        </IconButton>
      </div>
    </div>
  );
};
const RecipientList = (props: Props) => {
  const { recipients, isPrimeUser, modal, disableRecipientList, colorCounter } = props;
  const classes = useStyles(props);
  const [isAddRecipientFlow, setIsAddRecipientFlow] = useState(false);
  const [recipientToEdit, setRecipientToEdit] = useState<IRecipient>();
  const [optionalCheckedMap, setOptionalCheckedMap] = useState<{ [rId: string]: boolean }>({});
  const [optionalRecipients, setOptionalRecipients] = useState<IRecipient[]>();
  const [placeholderRecipients, setPlaceholderRecipients] = useState<IRecipient[]>();
  const isUserPlaceholder = (recipient: IRecipient): boolean => {
    return recipient.isPlaceholder && (recipient.firstName === null || recipient.email === null);
  };

  React.useEffect(() => {
    setPlaceholderRecipients(props.incompletePlaceholders);
  }, []);

  React.useEffect(() => {
    if (recipientToEdit) {
      const updatedRecipientToEdit = recipients.find((r) => r.id === recipientToEdit.id);
      setRecipientToEdit(updatedRecipientToEdit);
    }
  }, [recipients]);

  React.useEffect(() => {
    const ors = props.optionalRecipients ? [...props.optionalRecipients] : [];
    ors.forEach((or) => {
      or.group = or.group || props.signingGroups[0];
    });
    props.recipients.forEach((r) => {
      if (!modal) {
        if (r.isPlaceholder && !ors.some((recipient) => recipient.id === r.id) && (r.firstName || r.lastName)) {
          ors.push(r);
        }
      }
    });
    const map = {};
    ors.forEach((or) => {
      for (let i = 0; i < recipients.length; i++) {
        const r = recipients[i];
        if (r.id === or.id) {
          map[or.id] = true;
          return;
        }
        if (
          r.firstName === or.firstName &&
          r.lastName === or.lastName &&
          r.email === or.email &&
          r.title?.toLowerCase() === or.title?.toLowerCase()
        ) {
          or.id = r.id;
          map[or.id] = true;
          return;
        }
      }
    });
    // check if sender is in both groups
    setOptionalRecipients(ors);
    setOptionalCheckedMap(map);
  }, [props.signingGroups, props.optionalRecipients]);

  const addRecipient = () => {
    setIsAddRecipientFlow(true);
  };

  const editRecipient = (recipient: IRecipient) => {
    setIsAddRecipientFlow(true);
    setRecipientToEdit(recipient);
  };

  const cancelEdit = () => {
    setIsAddRecipientFlow(false);
    setRecipientToEdit(undefined);
  };

  const saveRecipient = (recipient: IRecipient, isNew: boolean) => {
    recipient.isActive = true;
    const contact = {
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      email: recipient.email,
      optional: recipient.isPlaceholder,
      id: recipient.id,
    };
    if (isPrimeUser) {
      props.createPrimeContact(contact);
      const index = recipients.findIndex((r) => r.id === recipient.id);
      if (isNew || index < 0) {
        props.setRecipients([...recipients, recipient]);
      } else {
        const newRecipients = [...recipients];
        newRecipients[index] = recipient;
        props.setRecipients(newRecipients);
      }
    }
    if (optionalRecipients.some((r) => r.id === recipient.id)) {
      const index = optionalRecipients.findIndex((or) => or.id === recipient.id);
      const newOptionalRecipients = [...optionalRecipients];
      newOptionalRecipients[index] = recipient;
      setOptionalRecipients(newOptionalRecipients);
      if (optionalCheckedMap[recipient.id]) {
        const index = recipients.findIndex((r) => r.id === recipient.id);
        const newRecipients = [...recipients];
        newRecipients[index] = recipient;
        props.setRecipients(newRecipients);
      }
    } else {
      const index = recipients.findIndex((r) => r.id === recipient.id);
      if (isNew || index < 0) {
        props.setRecipients([...recipients, recipient]);
      } else {
        const newRecipients = [...recipients];
        newRecipients[index] = recipient;
        props.setRecipients(newRecipients);
      }
    }
    if (recipient.isPlaceholder && placeholderRecipients.some((pr) => pr.id === recipient.id)) {
      // const index = placeholderRecipients.indexOf(recipient);
      const index = placeholderRecipients.indexOf(placeholderRecipients.find((pr) => pr.id === recipient.id));
      const newPlaceholders = [...placeholderRecipients];
      newPlaceholders.splice(index, 1);
      setPlaceholderRecipients(newPlaceholders);
    }
  };

  const handleSearchResultSelect = (contact: IContact) => {
    const isContactValid = (contactParam: IContact) =>
      !validateInput(contactParam.email, 'email') &&
      !validateInput(contactParam.firstName, 'firstname') &&
      !validateInput(contactParam.lastName, 'lastname');

    const recipient = (contactParam: IContact): IRecipient =>
      ({
        id: uuid(),
        firstName: contactParam.firstName,
        lastName: contactParam.lastName,
        email: contactParam.email,
        role: RecipientRole.Signer,
        group: {
          id: 'default',
          name: 'default',
          order: 0,
        },
        isActive: true,
      } as IRecipient);

    // If contact is undefined or null, do nothing
    if (!contact) return;
    // If all fields are valid, call saveRecipient
    if (isContactValid(contact)) {
      saveRecipient(recipient(contact), true);
    } else {
      // If not, go to recipient add or edit with that recipient
      setIsAddRecipientFlow(true);
      setRecipientToEdit(recipient(contact));
    }
  };

  const deleteRecipient = (recipient: IRecipient) => {
    props.setRecipients(recipients.filter((item) => item.id !== recipient.id));
  };

  const toggleOptionalRecipient = (optionalRecipient: IRecipient) => {
    const checked = !optionalRecipient.isActive;
    setOptionalCheckedMap({ ...optionalCheckedMap, [optionalRecipient.id]: checked });
    const updatedOptionalRecipients: IRecipient[] = [...optionalRecipients];
    const indexToUpdate = optionalRecipients?.findIndex((op) => op.id === optionalRecipient.id);
    if (checked) {
      if (optionalRecipient.recipientColorCounter) {
        updatedOptionalRecipients[indexToUpdate].isActive = true;
        updatedOptionalRecipients[indexToUpdate].color = getRecipientColor(optionalRecipient.recipientColorCounter);
        setOptionalRecipients(updatedOptionalRecipients);
      }
      optionalRecipient.isActive = true;
      optionalRecipient.group = {
        id: 'default',
        name: 'Group 1',
        order: 0,
      };
      let newList = [...recipients, optionalRecipient];
      applyColorAndCounterToNewRecipient(newList, recipients, colorCounter);
      newList = newList.filter((r, index) => newList.indexOf(r) === index);
      props.setRecipients(newList);
    } else {
      const recipient = recipients.find((r) => r.id === optionalRecipient.id);
      recipient.isActive = false;
      const r = recipients.filter((r) => r.isPlaceholder || r.isActive);
      props.setRecipients(r);
      updatedOptionalRecipients[indexToUpdate].isActive = false;
      updatedOptionalRecipients[indexToUpdate].color = recipient?.title === 'Me' ? colors.blue[800] : colors.grey[600];
      if (!recipient?.isPlaceholder) {
        delete updatedOptionalRecipients[indexToUpdate].recipientColorCounter;
      }
      setOptionalRecipients(updatedOptionalRecipients);
    }
  };

  const handleEditPlaceholderRecipient = (r: IRecipient) => {
    editRecipient(r);
  };
  if (disableRecipientList) {
    return <></>;
  }
  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">Recipients</Typography>
      {!isAddRecipientFlow ? (
        <div>
          {isPrimeUser ? (
            <div id={chameleonElId(26)}>
              <RecipientSearch
                autoFocus
                helperText=""
                handleOnChange={(e) => {}}
                value=""
                contacts={props.contacts}
                dataSpec="recipient-search"
                handleContactSelect={handleSearchResultSelect}
                addRecipientNode={
                  <div>
                    Sorry, we couldn't find them. Please try again or
                    <Button
                      color="primary"
                      onClick={() => {}}
                      data-spec="add-recipient-btn"
                      aria-label="add new recipient"
                      style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
                    >
                      click here to add a new recipient.
                    </Button>
                  </div>
                }
              />
            </div>
          ) : (
            ''
          )}

          <div className={classes.recipientsContainer}>
            {placeholderRecipients && placeholderRecipients.length && !modal ? (
              <PlaceholderRecipient
                placeholderRecipients={placeholderRecipients}
                editRecipient={handleEditPlaceholderRecipient}
              />
            ) : (
              ''
            )}
            {optionalRecipients
              ?.filter((or) => (modal ? or.isActive : true))
              .map((or, index) => (
                <OptionalRecipient
                  allRecipients={recipients}
                  firstRecipient={index === 0}
                  recipient={or}
                  key={or.id}
                  classes={classes}
                  toggleOptionalRecipient={toggleOptionalRecipient}
                  editRecipient={editRecipient}
                  modal={modal}
                />
              ))}
            {recipients
              .filter((r) => !(r.id in optionalCheckedMap) && !isUserPlaceholder(r) && (modal ? r.isActive : true))
              .map((r) => {
                return (
                  <div className={classes.recipient} key={r.id}>
                    <Recipient firstName={r.firstName} lastName={r.lastName} color={r.color} title={r.title} />
                    <div className={classes.iconWrapper}>
                      {r.isPlaceholder ? (
                        ''
                      ) : (
                        <IconButton
                          color="primary"
                          onClick={() => deleteRecipient(r)}
                          aria-label="delete recipient"
                          className={classes.buttonIcon}
                        >
                          <TrashIcon className={classes.icon} />
                        </IconButton>
                      )}

                      <IconButton
                        color="primary"
                        onClick={() => editRecipient(r)}
                        aria-label="edit recipient"
                        className={classes.buttonIcon}
                      >
                        <PenIcon className={classes.icon} />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
          </div>
          {recipients.length < 25 ? (
            <div className={classes.buttonWrapper}>
              <Button
                color="primary"
                onClick={addRecipient}
                className={classes.button}
                data-spec="add-recipient-btn"
                aria-label="add new recipient"
              >
                <AddIcon className={classes.addIcon} /> New Recipient
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <RecipientAddOrEdit
          recipient={recipientToEdit}
          signingGroups={props.signingGroups}
          recipients={props.recipients}
          saveRecipient={saveRecipient}
          returnToRecipientList={cancelEdit}
          lastGroupEmpty={props.lastGroupEmpty}
          contacts={props.contacts}
          modal={modal}
        />
      )}
    </div>
  );
};

export default RecipientList;
