import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { colors, elevation } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      cursor: 'pointer',
      '& $checkbox:after': {
        opacity: 1,
      },
    },
  },
  disabled: {
    '& $checkbox': {
      backgroundColor: colors.grey[500],
    },
    '& $label': {
      color: colors.grey[500],
    },
    '&:hover, &:focus': {
      cursor: 'default',
      '& $checkbox:after': {
        opacity: 0,
      },
    },
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${colors.grey[500]}`,
    borderRadius: '3px',
    position: 'relative',
    '&::after': {
      boxShadow: elevation.normal[2],
      opacity: 0,
      transition: 'opacity 0.2s',
      content: '""',
      width: 18,
      height: 18,
      margin: 1,
      borderRadius: '3px',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
    },
  },
  checked: {
    backgroundColor: colors.blue[800],
  },
  icon: {
    color: '#FFFFFF',
    height: 18,
    width: 18,
  },
  lineChecked: {
    backgroundColor: colors.grey[800],
  },
  label: {
    marginLeft: theme.spacing(2),
    userSelect: 'none',
  },
  toolbarLabel: {
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  checked: boolean;
  onClick: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
  isBlockToolbarCheckbox?: boolean;
  intermediate?: boolean;
}

const Checkbox = (props: IProps) => {
  const classes = useStyles(props);

  return (
    <div
      className={`${classes.wrapper} ${props.disabled ? classes.disabled : ''} ${props.className || ''}`}
      onClick={() => !props.disabled && props.onClick(!props.checked)}
    >
      <div
        className={`${classes.checkbox} ${
          props.checked && props.intermediate ? classes.lineChecked : props.checked ? classes.checked : ''
        }`}
      >
        {props.checked && props.intermediate ? (
          <RemoveIcon className={classes.icon} />
        ) : props.checked ? (
          <CheckIcon className={classes.icon} />
        ) : (
          ''
        )}
      </div>
      {props.label ? (
        <Typography variant="body1" className={props.isBlockToolbarCheckbox ? classes.toolbarLabel : classes.label}>
          {props.label}
        </Typography>
      ) : (
        ''
      )}
    </div>
  );
};

export default Checkbox;
