import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from '@tanstack/react-query';
import { Label } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { LaunchDarklyFlags, withLaunchDarkly } from '@digisign3-ui/sender/src/common/launchDarkly';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';
import { AutoBlocksRes, IBlock } from '../../store/senderBuild/types';
import { envelopeApi } from '../../lib/api/envelope';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { ResourceNames } from '../../lib/constants';

const isAutoBlocksLoading = (autoBlocks?: AutoBlocksRes) =>
  !autoBlocks?.documents?.every((d) => d.status === 'done' || d.status === 'error');

export const AutoBlocks = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const isAutoBlocksEnabled = flags && flags.isAutoBlocksEnabled;
  const [showMapModal, setShowMapModal] = useState(false);
  const [adding, setAdding] = useState(false);
  const [signerRoleMapping, setSignerRoleMapping] = useState<{ [key: string]: string }>({});
  const dispatch = useDispatch();
  const smallScreen = isSmallScreenMediaQuery();
  const store = useShallowSelector((state) => ({
    envelopeId: state.senderBuild.id,
    zoom: state.senderBuild.zoom,
    signers: state.senderBuild.signers,
    documentIds: state.senderBuild.documents.map((d) => d.documentId),
    blocks: state.senderBuild.blocks,
    resource: state.senderBuild.resource,
  }));

  // if use is clicked, only add blocks
  const { data: autoBlocksRes, isFetched: autoBlocksFetched } = useQuery(
    ['autoBlocks', store.envelopeId],
    () => envelopeApi.getAutoBlocks(store.envelopeId),
    {
      enabled: store.resource === ResourceNames.ENVELOPE && !!store.envelopeId && isAutoBlocksEnabled,
      select: (res) => ({
        ...res,
        documents: res.documents.filter((d) => store.documentIds.includes(d.documentId)),
      }),
      refetchInterval: (data) => (isAutoBlocksLoading(data) ? 1000 : false),
    }
  );
  const isAutoBlocksLoadingDone: boolean = autoBlocksFetched && !isAutoBlocksLoading(autoBlocksRes);
  const hasAutoBlocks =
    (autoBlocksRes?.documents?.length ?? 0) > 0 &&
    !autoBlocksRes?.documents.every((d) => d.blocks.length === 0 && (d.status === 'done' || d.status === 'error'));
  const autoBlockDocumentIds = autoBlocksRes?.documents.map((d) => d.documentId);
  const pageHasBlocksOnAutoBlockDocuments =
    autoBlockDocumentIds &&
    Object.keys(store.blocks)
      .filter((docId) => autoBlockDocumentIds.includes(docId))
      .some((docId) => Object.values(store.blocks[docId]).flat().length > 0);

  const toggle = () => {
    if (pageHasBlocksOnAutoBlockDocuments) {
      dispatch(actions.clearBlocks(store.envelopeId, autoBlockDocumentIds));
      window.heap.track('autoBlocksClearButtonClicked', { envelopeId: store.envelopeId });
    } else {
      setShowMapModal(true);
      window.heap.track('autoBlocksModalShown', { envelopeId: store.envelopeId });
    }
  };

  const onClickAddAutoBlocks = async () => {
    setAdding(true);
    const validTransactionRoles = Object.values(signerRoleMapping).filter(Boolean);
    const signers = store.signers.filter((s) => s.signerId in signerRoleMapping);
    const blocksToAdd: IBlock[] = [];

    const abResCopy = { ...autoBlocksRes };

    abResCopy?.documents
      ?.filter((d) => store.documentIds.includes(d.documentId))
      .forEach((d) => {
        d.blocks
          .filter((b) => validTransactionRoles.includes(b.transactionRole!))
          .forEach((b) => {
            const signer = signers.find((s) => b.transactionRole === signerRoleMapping[s.signerId])!;
            const block = { ...b };
            delete block.transactionRole;
            blocksToAdd.push({
              ...block,
              assignedTo: signer.signerId,
              value: `${signer.firstName} ${signer.lastName}`,
            });
          });
      });

    if (blocksToAdd.length === 0) {
      setAdding(false);
      return;
    }

    const { bLocks: blockIds } = await envelopeApi.addEnvelopeBlocks(store.envelopeId, blocksToAdd);

    blocksToAdd.forEach((b, i) => {
      b.blockId = blockIds[i];
    });
    dispatch(actions.addBlocksBulk(blocksToAdd));
    setShowMapModal(false);
    setAdding(false);
    window.heap.track('autoBlocksBlocksAdded', { envelopeId: store.envelopeId });
  };

  useEffect(() => {
    if (hasAutoBlocks && store.envelopeId) {
      window.heap.track('autoBlockBannerShown', { envelopeId: store.envelopeId });
    }
  }, [hasAutoBlocks, store.envelopeId]);

  return !hasAutoBlocks ? (
    <div className="mb-16" />
  ) : (
    <div
      className="bg-blue-800 mt-[92px] border border-blue-900 rounded-md flex flex-col lg:flex-row z-10 py-6 px-8 max-w-[calc(100%-64px)] mb-4"
      style={{
        width: store.zoom < 1 ? 612 - 64 : 612 * store.zoom - 64,
        background: `radial-gradient(circle at 100%, rgba(255,255,255,0.06), rgba(255,255,255,0.06) 50%, transparent 50%)`,
      }}
    >
      <div className="flex flex-col gap-2 grow">
        <Typography className="text-white text-[19px] font-bold">
          {pageHasBlocksOnAutoBlockDocuments ? 'We saved you some time!' : 'Save some time with Smart Blocks!'}
        </Typography>
        <Typography className="text-white text-opacity-[0.85] text-base font-medium">
          {pageHasBlocksOnAutoBlockDocuments
            ? 'We went ahead and automatically placed your signature, initial, and date blocks saving you some precious time!'
            : 'Leverage the new DigiSign smart blocks feature to streamline your workflow. It places seller and buyer signature and initial blocks automatically, saving you precious time.'}
        </Typography>
      </div>
      <div className="flex items-end justify-end">
        <Button
          variant="contained"
          color="primary"
          className="whitespace-nowrap -mr-3 -mb-3 bg-transparent"
          onClick={isAutoBlocksLoadingDone ? toggle : undefined}
          data-spec="auto-blocks-btn"
        >
          {!isAutoBlocksLoadingDone ? (
            <CircularProgress size={18} color="inherit" />
          ) : pageHasBlocksOnAutoBlockDocuments ? (
            'Clear blocks'
          ) : (
            'Use Smart Blocks'
          )}
        </Button>
      </div>
      <Dialog
        classes={{ paper: 'p-4' }}
        fullScreen={smallScreen}
        open={showMapModal}
        onClose={() => setShowMapModal(false)}
        BackdropProps={{ style: { backgroundColor: 'rgba(63, 91, 119, .5)' } }}
      >
        <DialogTitle>Confirm Signer Roles</DialogTitle>
        <DialogContent>
          <div className="mb-8">
            <Typography className="mb-4">
              In order to automatically place signature and initial blocks, we need to know the roles of your signers.
            </Typography>
            <div className="grid grid-cols-2 gap-4">
              {store.signers.map((signer) => (
                <>
                  <div key={signer.signerId} className="flex items-center">
                    <Label className="mr-2 w-6 h-6" style={{ color: signer.color }} />
                    <Typography variant="body2" className="overflow-hidden overflow-ellipsis grow">
                      {signer.firstName} {signer.lastName}
                    </Typography>
                  </div>
                  <Select
                    variant="filled"
                    displayEmpty
                    value={signerRoleMapping[signer.signerId] ?? ''}
                    onChange={(e) =>
                      setSignerRoleMapping({
                        ...signerRoleMapping,
                        [signer.signerId]: e.target.value as string,
                      })
                    }
                    classes={{ root: 'flex min-w-[120px]', selectMenu: 'rounded' }}
                    data-spec="role-select"
                  >
                    <MenuItem value="">Unassigned</MenuItem>
                    {autoBlocksRes?.transactionRoles?.map((r) => (
                      <MenuItem key={r} value={r}>
                        {r}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMapModal(false)} color="primary" variant="text" className="mr-4">
            Cancel
          </Button>
          <Button
            onClick={onClickAddAutoBlocks}
            color="primary"
            variant="contained"
            disabled={adding}
            className="w-[180px]"
            data-spec="confirm-btn"
          >
            {adding ? <CircularProgress size={18} color="inherit" /> : 'Add Smart Blocks'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withLaunchDarkly(AutoBlocks);
