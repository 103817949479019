import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { colors } from '@skyslope/mache';
import { hex2rgba } from '../../lib/utils';
import { IBlocks, IGroup, IPageDimensions, ISelectedBlocks } from '../../store/senderBuild/types';
import { useDispatch } from '../../lib/reduxHooks';
import { focusInput, blurInput } from '../../store/senderBuild/actions';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    backgroundColor: hex2rgba(colors.blue[100], 0.11),
    border: `3px dotted ${colors.blue[100]}`,
  },
  numberLabel: {
    fontSize: 13,
    lineHeight: '16px',
  },
  number: {
    display: 'block',
    minWidth: 64,
    marginBottom: 16,
  },
  divider: {
    backgroundColor: '#CAD5E0',
    height: 1,
    width: '100%',
    marginTop: 32,
    marginBottom: 24,
  },
  actionIcon: {
    fontSize: 18,
    marginRight: 4,
  },
  removeButton: {
    marginTop: 24,
  },
  selectValue: {
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  group?: IGroup;
  pageDimensions: IPageDimensions;
  selectedBlocks: ISelectedBlocks;
  blocks: IBlocks;
  documentId: string;
  pageIndex: number;
  editGroup: (updateObj: any) => void;
  addMoreCheckboxes: (blocksToAdd: number) => void;
  removeCheckboxes: (blocksToRemove: number) => void;
}

const CheckboxGroupControls = (props: IProps) => {
  const dispatch = useDispatch();
  const { group, editGroup } = props;
  const defaultSize = group ? group.blockIds.length : 1;
  const [inputValue, setInputValue] = React.useState<number | ''>(defaultSize);
  const [size, setSize] = React.useState(defaultSize);
  const classes = useStyles(props);

  React.useEffect(() => {
    const currentSize = group ? group.blockIds.length : 1;
    if (currentSize < size) {
      props.addMoreCheckboxes(size - currentSize);
    } else if (currentSize > size) {
      props.removeCheckboxes(currentSize - size);
    }
  }, [size]);

  React.useEffect(() => {
    const newSize = group ? group.blockIds.length : 1;
    setInputValue(newSize);
  }, [group?.id, group?.blockIds.length]);

  const validation = group ? group.validation : { rule: 'gte', value: 0 };
  const validationValues = Array.from({ length: +inputValue }, (_, i) => i + 1);
  if (group?.validation.rule === 'gte') {
    validationValues.unshift(0);
  }

  const editValidation = (key: string, value: any) => {
    editGroup({ validation: { ...group!.validation, [key]: value } });
  };

  const onFocus = () => dispatch(focusInput());

  const onBlur = () => dispatch(blurInput());

  const blurCheckbox = (e: any) => {
    onBlur();
    let newSize = e.target.value || 1;
    if (newSize > 99) {
      newSize = 99;
    } else if (newSize < 1) {
      newSize = 1;
    }
    setInputValue(newSize);
    setSize(newSize);
  };

  React.useEffect(() => {
    if (group && !validationValues.find((vv) => vv === group.validation.value)) {
      editValidation('value', validationValues[0]);
    }
  }, [size, validation.rule]);

  return (
    <div className={classes.wrapper}>
      <Typography variant="caption" className={classes.numberLabel}>
        How many checkboxes would you
        <br />
        like to add to this group?
      </Typography>
      <TextField
        type="number"
        variant="filled"
        className={classes.number}
        onFocus={onFocus}
        value={inputValue}
        onChange={(e) => setInputValue(+e.target.value || '')}
        onBlur={blurCheckbox}
        inputProps={{
          onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          },
        }}
        id="numberOfCheckboxes"
      />
      {/* 
      <Typography variant="body1">Display orientation</Typography>
      <Select variant="filled" value={orientation} onChange={(e: any) => setOrientation(e.target.value)} fullWidth>
        <MenuItem value="vertical">Vertical</MenuItem>
        <MenuItem value="horizontal">Horizontal</MenuItem>
      </Select> */}

      <div className={classes.divider} />

      <Typography variant="body1">Required Checkboxes</Typography>
      <Select
        variant="filled"
        value={validation.rule}
        disabled={!group || group.blockIds.length === 1}
        onChange={(e: any) => editValidation('rule', e.target.value)}
        fullWidth
        id="selectGroupRule"
      >
        <MenuItem value="gte">Select at least</MenuItem>
        <MenuItem value="lte">Select at most</MenuItem>
        <MenuItem value="eq">Select exactly</MenuItem>
      </Select>

      <Select
        className={classes.selectValue}
        variant="filled"
        value={validation.value}
        disabled={!group || group.blockIds.length === 1 || validationValues.length === 1}
        onChange={(e: any) => editValidation('value', e.target.value)}
        fullWidth
        id="selectNumberCheckboxesInRule"
      >
        {validationValues.map((n) => (
          <MenuItem key={n} value={n}>
            {n}
          </MenuItem>
        ))}
      </Select>

      {/* <Button variant="text" className={classes.removeButton} color="primary">
        <Remove className={classes.actionIcon} />
        Remove box from group
      </Button> */}
    </div>
  );
};

export default CheckboxGroupControls;
