import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import { useHistory, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Shelf from '../components/templateCreate/Shelf';
import Footer from '../components/common/Footer';
import { createRandomId } from '../lib/randomId';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/templateCreate/actions';
import { ITemplateRecipient, ITemplate, ITemplateCreateState } from '../store/templateCreate/types';
import { SHELF_WIDTH, chameleonElId, preferencesUrl } from '../lib/constants';
import { IDocument } from '../store/senderBuild/types';
import { progressPercentageForDocuments } from '../lib/api/common/progressPercentage';
import { errorToast } from '../store/pageFrame/actions';
import { HeaderContext } from '../context/header-context';
import { ISettings } from '../store/pageFrame/types';
import { getUsersGroups } from '../lib/api/account/accountApi';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';
import NavHeader from '../common/navigation';
import { RecipientRole } from '../common/recipients/types';
import Documents from '../common/documents';
import { IDoc } from '../common/documents/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  savingText: {
    color: colors.grey[600],
  },
  header: {
    position: 'fixed',
    top: 64,
    right: 42,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 4,
    },
  },
  documentsWrapper: {
    position: 'fixed',
    left: SHELF_WIDTH,
    right: 0,
    bottom: 0,
    top: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  paper: {
    height: '75%',
    width: '75%',
    display: 'flex',
    boxShadow: 'none',
    border: `1px solid ${colors.grey[500]}`,
    flexDirection: 'column',
    alignItems: 'stretch',
    '@media(max-width: 1024px)': {
      width: '85%',
    },
    '@media(max-width: 768px)': {
      width: '95%',
    },
  },
  centerHeader: {
    justifyContent: 'center',
  },
  documents: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    width: 'calc(100% - 64px)',
    overflow: 'auto',
  },
  legalLinksSection: {
    borderTop: `1px solid ${colors.grey[400]}`,
    height: '72px',
    position: 'fixed',
    bottom: '0px',
    padding: '15px',
    width: '360px',
    left: '0px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  legalLinks: {
    display: 'inline-block',
    flex: 'auto',
  },
  anchor: {
    color: colors.grey[800],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    fontSize: 13,
  },
  spacer: {
    padding: '0 12px',
    fontSize: 13,
    display: 'inline-block',
  },
}));

interface IState {
  templateCreate: ITemplateCreateState;
  recipientTitles: string[];
  templateId: string;
  settings: ISettings;
  settingsFetched: boolean;
}

const selector = (state: IRootState) => ({
  templateCreate: state.templateCreate,
  recipientTitles: state.pageFrame.settings?.account.template?.recipientTitles,
  templateId: state.templateCreate.template?.id,
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
});

const TemplateCreate = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const headerContext = React.useContext(HeaderContext);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];
  const recipients = store.templateCreate.template?.recipients;
  const disabled = recipients?.length === 1;
  const recipientExistsWithNoTitle = recipients?.some((r) => r.recipientTitle === '');

  React.useEffect(() => {
    dispatch(actions.fetchTemplate(params.id));
    headerContext.setContent(
      <NavHeader
        labelName="Create template"
        breadcrumb={{ name: 'Templates', onClick: () => history.push('/templates') }}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        userOrigin={store.settings?.account.userOrigin}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );
    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, [store.settingsFetched]);

  if (!store.templateCreate.template) {
    return null;
  }

  const updateTemplate = (patchBody: Partial<ITemplate>) => {
    dispatch(actions.updateTemplate(patchBody));
  };

  const addRecipient = () => {
    const tempId = createRandomId();
    dispatch(
      actions.addRecipient({
        id: tempId,
        role: RecipientRole.Signer,
        recipientTitle: '',
        isNotCreated: true,
      })
    );
  };

  const updateRecipient = (recipientId: string, update: Partial<ITemplateRecipient>) => {
    dispatch(actions.updateRecipient(recipientId, update));
  };

  const changeRecipientTitle = (recipientId: string, newTitle: string) => {
    dispatch(actions.changeRecipientTitle(recipientId, newTitle));
  };

  const handleUploadDocument = (tempId: string, file: File) => {
    dispatch(actions.uploadDocument(tempId, file));
  };

  const handleReorderDocuments = (documents: IDocument[]) => {
    dispatch(actions.reorderDocuments(documents));
  };

  const handleDeleteDocument = (documentId: string) => {
    dispatch(actions.deleteDocument(documentId));
  };

  const handleUpdateDocuments = (documents: IDocument[]) => {
    dispatch(actions.updateDocuments(documents));
  };

  const handleError = (errStr: string) => {
    dispatch(errorToast(errStr));
  };

  const handleDeleteRecipient = (templateId: string, recipient: ITemplateRecipient) => {
    dispatch(actions.deleteRecipient(templateId, recipient));
  };

  return (
    <div className={classes.root}>
      <Shelf
        template={store.templateCreate.template!}
        updateTemplate={updateTemplate}
        addRecipient={addRecipient}
        updateRecipient={updateRecipient}
        changeRecipientTitle={changeRecipientTitle}
        recipientTitles={store.recipientTitles}
        deleteDisabled={disabled}
        templateId={store.templateId}
        deleteRecipient={handleDeleteRecipient}
        hideAction={hideNavItems}
        userOrigin={store.settings?.account.userOrigin}
      />
      <div className={classes.legalLinksSection}>
        <div className={classes.legalLinks} data-spec="legalLinks">
          <a className={classes.anchor} href="https://support.skyslope.com/hc/en-us" target="blank">
            Support
          </a>
          <span className={classes.spacer}>|</span>
          <a className={classes.anchor} href="https://skyslope.com/terms-conditions/" target="blank">
            Terms
          </a>
          <span className={classes.spacer}>|</span>
          <a className={classes.anchor} href="https://skyslope.com/privacy-policy/" target="blank">
            Privacy
          </a>
        </div>
      </div>
      <div className={classes.documentsWrapper}>
        <Paper className={classes.paper} id={chameleonElId(19)}>
          <Documents
            documents={store.templateCreate.template?.documents as IDoc[]}
            handleUploadDocument={handleUploadDocument}
            handleReorderDocuments={handleReorderDocuments}
            handleDeleteDocument={handleDeleteDocument}
            handleUpdateDocuments={handleUpdateDocuments}
            handleError={handleError}
            progressPercentageForDocuments={progressPercentageForDocuments}
            rootClassName={store.templateCreate.template?.documents.length === 0 ? classes.centerHeader : ''}
            documentsClassName={classes.documents}
          />
        </Paper>
      </div>
      <div className={classes.header}>
        {(store.templateCreate.hasSavedAtLeastOnce || store.templateCreate.showAutoSaveIndicator) && (
          <Typography className={classes.savingText} variant="body1">
            {store.templateCreate.showAutoSaveIndicator ? 'Saving Changes...' : 'All Changes Saved!'}
          </Typography>
        )}
      </div>
      <Footer customLeft="none" customWidth="calc(100VW - 392px)">
        <Button color="primary" onClick={() => history.push('/templates')}>
          Exit
        </Button>
        <Button
          data-spec="next-button"
          variant="contained"
          color="primary"
          disabled={
            !store.templateCreate.template?.documents.length ||
            !store.templateCreate.template?.recipients.filter((r) => !r.isNotCreated).length ||
            store.templateCreate.template.documents.some((d) => d.isUploading) ||
            recipientExistsWithNoTitle
          }
          onClick={() => history.push(`/templates/${store.templateCreate.template?.id}`)}
        >
          Next
        </Button>
      </Footer>
    </div>
  );
};

export default withLaunchDarkly(TemplateCreate);
