import alwaysIncludeDate from '../gifs/alwaysIncludeDate.gif';
import alwaysIncludeDateTime from '../gifs/alwaysIncludeDateTime.gif';
import onlyPlaceSignature from '../gifs/onlyPlaceSignature.gif';
import alwaysIncludeDateImage from '../images/alwaysIncludeDate.png';
import alwaysIncludeDateTimeImage from '../images/alwaysIncludeDateTime.png';
import onlyPlaceSignatureImage from '../images/onlyPlaceSignature.png';
import blocksSelectedImg from '../images/blocksSelected.svg';
import blocksDeselectedImg from '../images/blocksDeselected.svg';
import blocksSelectedGif from '../gifs/blocksSelected.gif';
import blocksDeselectedGif from '../gifs/blocksDeselected.gif';
import signatureCircle from '../images/Icon_Signature_circle.png';
import emailCircle from '../images/Icon_email_circle.png';
import attachment from '../images/attachedPdf.png';
import keepBlocksSelectedIcon from '../images/Icon_Keep_Blocks_Selected.png';
import dateCircle from '../images/Icon_Date_Format.svg';
import monthDayYearImage from '../images/monthDayYear.png';
import dayMonthYearImage from '../images/dayMonthYear.png';
import yearMonthDayImage from '../images/yearMonthDay.png';
import notificationImportant from '../images/notification_important.svg';
import autoRemindersImage from '../images/auto_reminders_img.svg';

interface PreferencePanelOption {
  value: string;
  label: string;
  subLabel: string;
  image: any;
  onHoverImage: any;
  isDefault: boolean;
  dataSpec: string;
}

interface PreferencePanelData {
  title: string;
  titleImage: any;
  ariaLabel: string;
  dataSpec: string;
}

const signaturePreference = {
  title: 'Signature Settings',
  titleImage: signatureCircle,
  ariaLabel: 'signature-block-settings',
  dataSpec: 'preferences-save',
};
const signaturePreferenceOptions = [
  {
    value: 'None',
    label: 'Signature-block only',
    subLabel: 'Only signature blocks are placed.',
    onHoverImage: onlyPlaceSignature,
    image: onlyPlaceSignatureImage,
    isDefault: true,
    dataSpec: 'default-signature-settings',
  },
  {
    value: 'Date',
    label: 'Include date',
    subLabel: 'Date blocks are automatically added next to signatures.',
    onHoverImage: alwaysIncludeDate,
    image: alwaysIncludeDateImage,
    isDefault: false,
    dataSpec: 'signature-date-settings',
  },
  {
    value: 'DateTime',
    label: 'Include date and time',
    subLabel: 'Date and time blocks are automatically added next to signatures.',
    onHoverImage: alwaysIncludeDateTime,
    image: alwaysIncludeDateTimeImage,
    isDefault: false,
    dataSpec: 'signature-date-time-settings',
  },
];

const dateFormatPreference = {
  title: 'Date Format',
  titleImage: dateCircle,
  ariaLabel: 'date-format-settings',
  dataSpec: 'date-format-save',
};
const dateFormatPreferenceOptions = [
  {
    value: 'MM/DD/YYYY',
    label: 'MM/DD/YYYY',
    subLabel: 'Date will be presented as month/day/year.',
    onHoverImage: monthDayYearImage,
    image: monthDayYearImage,
    isDefault: true,
    dataSpec: 'default-date-format-settings',
  },
  {
    value: 'DD/MM/YYYY',
    label: 'DD/MM/YYYY',
    subLabel: 'Date will be presented as day/month/year.',
    onHoverImage: dayMonthYearImage,
    image: dayMonthYearImage,
    isDefault: false,
    dataSpec: 'day-month-year-format-settings',
  },
  {
    value: 'YYYY/MM/DD',
    label: 'YYYY/MM/DD',
    subLabel: 'Date will be presented as year/month/day.',
    onHoverImage: yearMonthDayImage,
    image: yearMonthDayImage,
    isDefault: false,
    dataSpec: 'year-month-day-format-settings',
  },
];

const keepBlocksSelectedPreference = {
  title: 'Block Selection Preferences',
  titleImage: keepBlocksSelectedIcon,
  ariaLabel: 'block-selection-settings',
  dataSpec: 'block-selection-save',
};
const keepBlockSelectedOptions = [
  {
    value: 'true',
    label: 'Keep block type selected',
    subLabel: 'Block type selected will remain selected after placing a block.',
    onHoverImage: blocksSelectedGif,
    image: blocksSelectedImg,
    isDefault: true,
    dataSpec: 'blocks-selected',
  },
  {
    value: 'false',
    label: 'Deselect block type',
    subLabel: 'After placing a block, that block type will be deselected.',
    onHoverImage: blocksDeselectedGif,
    image: blocksDeselectedImg,
    isDefault: false,
    dataSpec: 'blocks-deselected',
  },
];

const emailDocsAsAttachmentPreference = {
  title: 'Email Settings',
  titleImage: emailCircle,
  ariaLabel: 'email-attachment-settings',
  dataSpec: 'email-attachment-save',
};
const emailDocsAsAttachmentPreferenceOptions = [
  {
    value: 'true',
    label: 'Send completed documents as a link and attachment',
    subLabel:
      'If signed documents are under 25 MB, an email will be sent to signers with an attached PDF of documents in addition to the document link.',
    onHoverImage: attachment,
    image: attachment,
    isDefault: true,
    dataSpec: 'default-send-email-attachment-settings',
  },
  {
    value: 'false',
    label: 'Send completed document as a link',
    subLabel: 'Once signing is complete, an email will be sent to signers with a link to the signed documents.',
    onHoverImage: attachment,
    image: attachment,
    isDefault: false,
    dataSpec: 'send-no-email-attachment-settings',
  },
];

const automatedEmailsReminderPreference = {
  title: 'Auto-Reminders',
  titleImage: notificationImportant,
  ariaLabel: 'automated-emails-settings',
  dataSpec: 'automated-emails-settings',
};
const automatedEmailsReminderPreferenceOptions = [
  {
    value: 'true',
    label: 'Auto-Reminders Enabled',
    subLabel: `Your recipients receive an email when it's their turn to sign. If they don't sign within 24 hours, we'll automatically send a reminder on your behalf.`,
    onHoverImage: autoRemindersImage,
    image: autoRemindersImage,
    isDefault: true,
    dataSpec: 'default-automated-emails-settings',
  },
  {
    value: 'false',
    label: 'Auto-Reminders Disabled',
    subLabel: 'Disables automatic reminders, but you can still send reminders manually if needed.',
    onHoverImage: autoRemindersImage,
    image: autoRemindersImage,
    isDefault: false,
    dataSpec: 'send-no-automated-emails-settings',
  },
];

export {
  signaturePreference,
  signaturePreferenceOptions,
  dateFormatPreference,
  dateFormatPreferenceOptions,
  keepBlocksSelectedPreference,
  keepBlockSelectedOptions,
  emailDocsAsAttachmentPreference,
  emailDocsAsAttachmentPreferenceOptions,
  automatedEmailsReminderPreference,
  automatedEmailsReminderPreferenceOptions,
  PreferencePanelData,
  PreferencePanelOption,
};
