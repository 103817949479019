import React from 'react';
import { convertFieldTypeToQueryType } from '../../utils';
import RecipientAutoComplete from '../RecipientAutoComplete';
import { IContact } from '../types';

type IProps = {
  autoFocus?: boolean;
  label: string;
  helperText: string;
  placeholder: string;
  handleOnChange: (e: any) => void;
  error: boolean;
  value: string;
  dataSpec?: string;
  name?: string;
  disabled?: boolean;
  contacts: IContact[];
  handleContactSelect: (e: any, queryType: string) => void;
};

const RecipientTextField = (props: IProps) => {
  const searchType = convertFieldTypeToQueryType(props.label);
  const filterContacts = (value: string) => {
    const normalizedValue = value.trim().toLowerCase();
    return (contact: IContact) => contact[searchType]?.toLowerCase().includes(normalizedValue);
  };

  const getOptionLabel = (option) => `${option[convertFieldTypeToQueryType(props.label)]}`;

  return (
    <RecipientAutoComplete
      autoFocus={props.autoFocus}
      label={props.label}
      helperText={props.helperText}
      placeholder={props.placeholder}
      handleOnChange={props.handleOnChange}
      error={props.error}
      value={props.value}
      dataSpec={props.dataSpec}
      name={props.name}
      disabled={props.disabled}
      contacts={props.contacts}
      handleContactSelect={props.handleContactSelect}
      filterMethod={filterContacts}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default RecipientTextField;
