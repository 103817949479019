import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { priceComparisonLink } from '../../lib/constants';
import { ReactComponent as MegaphoneIcon } from '../../images/megaphone.svg';
import { isSmallScreenMediaQuery, isMediumScreenMediaQuery } from '../../lib/isSmallScreen';
import { useExtendFreeTrial } from '../../hooks/paywall/use-ds3-trial';
import { TrialExtension } from '../../store/pageFrame/types';

interface IProps {
  expired: boolean;
  daysLeft: number;
  dismissBanner: () => void;
  isEligibleForExtension: boolean;
  latestTrialExtension?: TrialExtension;
}

const TrialPeriodBanner = (props: IProps) => {
  const { latestTrialExtension, expired, daysLeft, dismissBanner, isEligibleForExtension } = props;
  const isSmallScreen = isSmallScreenMediaQuery();
  const isMedScreen = isMediumScreenMediaQuery();
  const [isFadingOut, setIsFadingOut] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const hasActiveExtension = (latestTrialExtension?.envelopesLeft ?? 0) > 0;
  const bannerTitle = hasActiveExtension
    ? ''
    : props.expired
    ? 'Your free access to DigiSign has expired.'
    : 'Your free access to SkySlope DigiSign is coming to an end.';

  const envelopeText =
    latestTrialExtension?.envelopesLeft === 1
      ? `Enjoy your free trial extension with ${latestTrialExtension.envelopesLeft} more envelope.`
      : `Enjoy your free trial extension for another ${latestTrialExtension?.envelopesLeft} envelopes.`;

  const trialMessage = hasActiveExtension
    ? `${envelopeText} Sign up today for full access to DigiSign.`
    : expired
    ? 'You can still keep the good times rolling. Sign up today for full access to SkySlope DigiSign.'
    : `You have ${daysLeft} day${
        daysLeft === 1 ? '' : 's'
      } remaining to access DigiSign and all of its features. Sign up today to avoid any service disruption.`;

  const bannerSubtitle = trialMessage;

  const history = useHistory();
  const { mutateAsync: extendFreeTrial } = useExtendFreeTrial();

  async function handleExtendFreeTrial() {
    setIsFadingOut(true);
    await extendFreeTrial();
    setTimeout(async () => {
      setIsFadingOut(false);
    }, 700); // Match the timeout to the animation duration
  }

  const handleActionClick = () => {
    const previousUrl = window.location.href;
    history.push({
      pathname: '/subscriptions',
      state: {
        url: previousUrl,
        data: {
          navigatedToSubscriptions: true,
        },
      },
    });
  };
  const bannerHeight = document.getElementById('mobile-banner-wrapper')?.offsetHeight;
  const mobileTrialBannerClass =
    'flex justify-between items-center w-full h-[90px] bg-gradient-to-r from-[#533DB1] to-blue-400 absolute';
  return (
    <>
      {isSmallScreen ? (
        <>
          <div
            id="mobile-banner-wrapper"
            data-spec="mobile-trial-banner"
            style={{ top: '64px', zIndex: 9999 }}
            className={openDropdown ? mobileTrialBannerClass + ' drop-shadow-lg' : mobileTrialBannerClass}
          >
            <div className="flex justify-between w-full items-center" style={{ padding: '24px 40px' }}>
              <span data-spec="trial-banner-title" className="mb-1 font-bold max-w-[240px]">
                {bannerTitle}
              </span>
              <IconButton
                data-spec="toolbarExpandIcon"
                className="p-1.5 text-white"
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                {openDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          </div>
          {openDropdown && (
            <div
              style={{ top: (bannerHeight ?? 0) + 63 + 'px' }}
              className="flex flex-col justify-between w-full bg-gradient-to-r from-[#533DB1] to-blue-400 absolute"
            >
              <span className="py-7 px-10">{bannerSubtitle}</span>
              <Button className="shadow-none mx-8" variant="contained" onClick={() => handleActionClick()}>
                {expired ? 'Unlock Access' : 'Choose My Plan'}
              </Button>
              <Button className="text-white mx-8 py-8" variant="text" onClick={dismissBanner}>
                Remind Me later
              </Button>
            </div>
          )}
        </>
      ) : (
        <div
          data-spec="trial-banner"
          className={`flex justify-between items-center w-full h-[90px] bg-gradient-to-r from-[#533DB1] to-blue-400 ${
            isFadingOut ? 'animate-fade-out-top' : 'animate-fade-in-top'
          }`}
        >
          <div className="flex items-center ml-[1%]">
            <IconButton className="text-white" onClick={dismissBanner}>
              <CloseIcon />
            </IconButton>
            {!isMedScreen && <MegaphoneIcon className="my-0 mr-[18px] ml-3" />}
            <div className="flex-col flex">
              <span data-spec="trial-banner-title" className="mb-1 font-bold">
                {bannerTitle}
              </span>
              <span className="font-bold">{bannerSubtitle}</span>
            </div>
          </div>
          <div className="mr-[2%]">
            {isEligibleForExtension && !hasActiveExtension ? (
              <Button className="text-white mr-3.5" variant="text" onClick={handleExtendFreeTrial}>
                Extend Free Trial
              </Button>
            ) : (
              <Button
                className="text-white mr-3.5"
                variant="text"
                onClick={() => window.open(priceComparisonLink, '_blank')}
              >
                Learn More
              </Button>
            )}
            <Button className="shadow-none" variant="contained" onClick={() => handleActionClick()}>
              {expired ? 'Unlock Access' : 'Choose My Plan'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TrialPeriodBanner;
