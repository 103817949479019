/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { isSmallScreenMediaQuery } from '../lib/isSmallScreen';
import { Modal, ModalText, ModalTitle, ModalConfirmationButton, ModalCancelButton } from './Modal';
import { isSkySlopeMobileApp } from './utils';
import { handleAppLogout } from '../lib/utils';

interface IProps {
  closeModal: () => void;
}

const TimeoutModal = (props: IProps) => {
  const isMobileApp = isSkySlopeMobileApp();
  const isSmallScreen = isSmallScreenMediaQuery();
  const isMobileAppOrSmallScreen = isMobileApp || isSmallScreen;

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes, 1)}:{zeroPad(seconds)}
      </span>
    );
  };

  const actions = (
    <>
      <ModalCancelButton
        isMobileOrSmallScreen={isMobileAppOrSmallScreen}
        onClick={handleAppLogout}
        aria-label="sign out"
      >
        Sign Out
      </ModalCancelButton>
      <ModalConfirmationButton
        buttonStyle={isMobileAppOrSmallScreen ? { margin: '10px 0px 20px' } : {}}
        onClick={props.closeModal}
        aria-label="stay signed in"
      >
        Stay Signed In
      </ModalConfirmationButton>
    </>
  );

  const content = (
    <div>
      <div style={{ marginBottom: isMobileAppOrSmallScreen ? '26px' : '18px' }}>
        <ModalText>
          You've been inactive for a while. {isMobileAppOrSmallScreen && <br />} For security reasons, we're{' '}
          {!isMobileAppOrSmallScreen && <br />}
          automatically {isMobileAppOrSmallScreen && <br />} signing you out in{' '}
          <span style={{ fontWeight: 'bold' }}>
            <Countdown date={Date.now() + 300000} renderer={renderer} onComplete={handleAppLogout} /> minutes.
          </span>
        </ModalText>
      </div>
      <ModalText>
        Choose "Stay Signed In" to continue {isMobileAppOrSmallScreen && <br />} working or "Sign Out" if you're{' '}
        {!isMobileAppOrSmallScreen && <br />} done. {isMobileAppOrSmallScreen && <br />} All progress will be saved.
      </ModalText>
    </div>
  );

  return (
    <Modal
      data-spec="timeout-modal"
      hideCloseButton
      title={
        <ModalTitle
          customStyle={
            isMobileAppOrSmallScreen
              ? { letterSpacing: '0px', marginBottom: '32px', textAlign: 'center', fontSize: '32px' }
              : {}
          }
        >
          Your session is {isMobileAppOrSmallScreen && <br />} about to expire
        </ModalTitle>
      }
      content={content}
      actionButtons={actions}
      isMobileOrSmallScreen={isMobileAppOrSmallScreen}
      modalStyles={{
        titlePadding: isMobileAppOrSmallScreen ? '0px' : '40px 40px 0px',
        minHeight: '336px',
        minWidth: '300px',
        justifyContent: isMobileAppOrSmallScreen ? 'center' : 'space-around',
        maxHeight: isMobileAppOrSmallScreen ? 'none' : '',
        actionButtonsStyle: isMobileAppOrSmallScreen
          ? {
              paddingTop: '10px',
              paddingRight: '8px',
              paddingBottom: '0px',
              justifyContent: 'center',
              flexDirection: 'column-reverse',
            }
          : {},
        modalContentStyle: isMobileAppOrSmallScreen ? { textAlign: 'center', padding: '0px', fontSize: '17px' } : {},
      }}
    />
  );
};

export default TimeoutModal;
