import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import { errorToast, setLoading, successToast } from '../pageFrame/actions';
import * as templateService from '../../lib/api/template/index';
import * as accountApi from '../../lib/api/account/accountApi';
import { formatUsers } from '../../lib/api/account/utils';
import { ITemplateResponse } from '../../lib/api/template';
import { ITemplate } from '../templateCreate/types';
import {
  allOfficeGuid,
  checkIfTemplateIsSharedToUserInOffice,
  checkIfTemplateSharedToAllUsersGroups,
  checkIfTemplateSharedToAnyUsersGroup,
  checkIfUserIsExcludedFromTemplateShare,
} from '../../lib/utils';
import { IShareRecord, SubGroup } from './types';
import { primeApi } from '../../lib/api/prime';
import { OfficeUser, UserOffice } from '../../lib/api/prime/prime';

const ActionTypes = actions.ActionTypes;

export function* handleFetchUsers(action: ReturnType<typeof actions.fetchUsers>) {
  try {
    const res: any = yield call(accountApi.getUsers, action.payload.limit, action.payload.skip, action.payload.search);
    const users = res.data.map((user) => {
      const newUser: any = {};
      const metaData = formatUsers(user.metadata);
      newUser.firstName = metaData.FirstName;
      newUser.lastName = metaData.LastName;
      newUser.id = user._id;
      newUser.oktaUserId = user._id;
      newUser.email = metaData.Email;
      newUser.userGroups = user.userGroups;
      return newUser;
    });
    yield put(
      actions.fetchUsersDone(
        users,
        !!action.payload.search,
        res.totalCount,
        action.payload.search,
        action.payload.fromScroll
      )
    );
  } catch (err) {
    yield put(actions.fetchUsersError(err));
  } finally {
    yield put(setLoading(false));
  }
}

export function* toggleTemplateShareToUser(action: ReturnType<typeof actions.toggleTemplateShareToUser>) {
  try {
    yield call(
      templateService.toggleShareTemplateToUser,
      action.payload.user,
      action.payload.templateId,
      action.payload.sharedToAllParentGroups,
      action.payload.sharedToASubGroup,
      action.payload.sharedToAnOffice
    );
    yield put(
      actions.toggleTemplateShareToUserDone(
        action.payload.user,
        action.payload.sharedToAllParentGroups,
        action.payload.sharedToASubGroup
      )
    );
    if (action.payload.user.isTemplateShared) {
      yield put(
        successToast(
          `${action.payload.templateName} has been shared with ${action.payload.user.firstName} ${action.payload.user.lastName}`
        )
      );
    } else {
      yield put(
        successToast(
          `${action.payload.templateName} is no longer being shared with ${action.payload.user.firstName} ${action.payload.user.lastName}`
        )
      );
    }
  } catch (e) {
    const user = action.payload.user;
    user.isTemplateShared = !user.isTemplateShared;
    yield put(
      actions.toggleTemplateShareToUserDone(
        user,
        action.payload.sharedToAllParentGroups,
        action.payload.sharedToASubGroup,
        true
      )
    );
    yield put(
      errorToast(
        `Failed to share ${action.payload.templateName} with ${action.payload.user.firstName} ${action.payload.user.lastName}. Please try again.`
      )
    );
  }
}

export function* toggleTemplateShareToSubGroup(action: ReturnType<typeof actions.toggleTemplateShareToSubGroup>) {
  const subGroupIds = action.payload.subGroups.map((g) => g.id);
  const sharedIds = [...action.payload.sharedIds];
  sharedIds.push({ id: action.payload.group.id, shareType: 'Group' });
  const alreadySharedToAllSubGroups = action.payload.sharedIds.length === subGroupIds.length;
  const sharingToAllSubGroups = checkIfTemplateSharedToAllUsersGroups(subGroupIds, sharedIds);

  // get all officesIds of offices currently sharing
  const officesToSearch = getOfficesToSearch(sharedIds);

  const usersSharedByOffice: UserOffice[] = (yield call(primeApi.getUsersInOffice, officesToSearch.join(','))) || [];
  // this users should remain with their selected status
  // there are 5 scenarios that go through this handler which is why there are so many unique toast messages:
  // sharing/unsharing to a single sub group
  // sharing/un-sharing from all sub groups
  // un-sharing from all parent groups
  try {
    // if shared to all parent groups, unshare from all parent groups
    if (action.payload.sharedToAllParentGroups) {
      yield call(
        templateService.toggleShareTemplateToAllUsersParentGroups,
        action.payload.alreadyShared,
        action.payload.template.id
      );
    }
    // else toggle single sub group share
    else {
      yield call(
        templateService.toggleShareTemplateToASubGroupOrOffice,
        action.payload.template.id,
        action.payload.group.id,
        action.payload.alreadyShared,
        'Group'
      );
    }
    yield put(
      actions.toggleTemplateShareToSubGroupDone(
        action.payload.group.id,
        action.payload.sharedToAllParentGroups,
        false,
        usersSharedByOffice
      )
    );

    let successMessage: string;
    if (action.payload.sharedToAllParentGroups) {
      successMessage = `${action.payload.template.name} is no longer being shared with everyone`;
    } else if (alreadySharedToAllSubGroups && sharingToAllSubGroups) {
      successMessage = `${action.payload.template.name} is no longer being shared with all groups`;
    } else if (sharingToAllSubGroups) {
      successMessage = `${action.payload.template.name} is being shared with all groups`;
    } else if (action.payload.alreadyShared) {
      successMessage = `${action.payload.template.name} is no longer being shared with ${action.payload.group.name}`;
    } else {
      successMessage = `${action.payload.template.name} has been shared with ${action.payload.group.name}`;
    }
    yield put(successToast(successMessage));
  } catch (e) {
    yield put(
      actions.toggleTemplateShareToSubGroupDone(action.payload.group.id, action.payload.sharedToAllParentGroups, true)
    );
    let errorMessage: string;
    if (action.payload.sharedToAllParentGroups) {
      errorMessage = `Failed to unshare ${action.payload.template.name} from everyone. Please try again.`;
    } else if (alreadySharedToAllSubGroups && sharingToAllSubGroups) {
      errorMessage = `Failed to unshare ${action.payload.template.name} from all groups. Please try again.`;
    } else if (sharingToAllSubGroups) {
      errorMessage = `Failed to share ${action.payload.template.name} with all groups. Please try again.`;
    } else if (action.payload.alreadyShared) {
      errorMessage = `Failed to unshare ${action.payload.template.name} from ${action.payload.group.name}. Please try again.`;
    } else {
      errorMessage = `Failed to share ${action.payload.template.name} with ${action.payload.group.name}. Please try again.`;
    }
    yield put(errorToast(errorMessage));
  }
}

export function* toggleTemplateShareToAllGroups(
  action: ReturnType<typeof actions.toggleTemplateShareToAllUsersGroups>
) {
  try {
    // Passing the offices to be removed so no extra call is needed
    let officeGuids: string[] = [];
    if (action.payload.officeSharingEnabled) {
      officeGuids = action.payload.offices.map((o) => o.officeGuid);
    }
    yield call(
      templateService.toggleShareTemplateToAllUsersParentGroups,
      action.payload.alreadyShared,
      action.payload.templateId,
      officeGuids
    );
    yield put(actions.toggleShareTemplateToAllUsersGroupsDone(action.payload.alreadyShared));

    if (action.payload.alreadyShared) {
      yield put(successToast('Your template is no longer being shared with everyone'));
    } else {
      yield put(successToast('Your template has been shared with everyone'));
    }
  } catch (e) {
    yield put(actions.toggleShareTemplateToAllUsersGroupsDone(action.payload.alreadyShared, true));
    if (action.payload.alreadyShared) {
      yield put(errorToast('Unshare from everyone failed. Please try again.'));
    } else {
      yield put(errorToast('Share to everyone failed. Please try again.'));
    }
  }
}

function isTemplateSharedWithUser(
  newUser: any,
  templateSharedToUser: IShareRecord | undefined,
  templateSharedToUsersSubGroup: boolean,
  showGroupsTable: boolean,
  userIsExcluded: boolean,
  officeSharingEnabled: boolean,
  usersFromOffice: OfficeUser[]
) {
  if (
    !userIsExcluded &&
    (templateSharedToUser ||
      (templateSharedToUsersSubGroup && showGroupsTable) ||
      (officeSharingEnabled && checkIfTemplateIsSharedToUserInOffice(newUser, usersFromOffice, [])))
  ) {
    // eslint-disable-next-line no-param-reassign
    newUser.isTemplateShared = true;
  } else {
    // eslint-disable-next-line no-param-reassign
    newUser.isTemplateShared = false;
  }
}

export function* handleFetchTemplateShareInformation(action: ReturnType<typeof actions.fetchTemplateShareInformation>) {
  const { limit, skip, templateId, oktaUserId, fetchOffices } = action.payload;
  try {
    const usersRes: any = yield call(accountApi.getUsers, limit, skip);
    const users = usersRes.data;
    const templateRes: ITemplateResponse<ITemplate> = yield call(templateService.getTemplate, templateId, true);
    const template = templateRes.result;
    let offices: UserOffice[] = [];
    if (skip === 1) {
      const groupsRes = yield call(accountApi.getUsersGroupsPaginated);
      if (groupsRes.errors?.length) {
        yield put(actions.fetchUsersGroupsDone(false, [], [], true));
        console.error('Failed to fetch users groups');
      } else {
        const parentGroups = groupsRes.data.filter((g) => !g.parentGroupId).map((g) => g._id);
        const templateSharedToAllParentGroups = checkIfTemplateSharedToAllUsersGroups(
          parentGroups,
          template.shares.sharedTo
        );

        let formattedSubGroups: SubGroup[] = [];
        groupsRes.data
          .filter((g) => g.parentGroupId)
          .map((g) => {
            const activeUserInGroup = g.groupUsers.includes(action.payload.oktaUserId);
            formattedSubGroups.push({
              id: g._id,
              name: g.name,
              count: activeUserInGroup ? (g.userCount -= 1) : g.userCount,
            });
          });
        yield put(actions.fetchUsersGroupsDone(templateSharedToAllParentGroups, parentGroups, formattedSubGroups));
      }
    }

    let usersFromOffices: OfficeUser[] = [];
    // Getting all users share by office
    if (fetchOffices) {
      try {
        // get all offices the user has access
        offices = yield call(primeApi.getOffices);
        // replace the all office name from ALL to All Office
        const allOfficeIndex = offices.findIndex((o) => o.officeGuid === allOfficeGuid);
        if (allOfficeIndex > -1) {
          offices[allOfficeIndex].officeName = 'All Office';
        }
        // get all offices to search from current share
        const officeToSearch = getOfficesToSearch(template.shares.sharedTo);
        // search for all users in shared offices at once. If not office the the call is not done
        if (officeToSearch && officeToSearch.length) {
          const usersInOffices: OfficeUser[] = yield call(primeApi.getUsersInOffice, officeToSearch.join(','));
          usersFromOffices = usersInOffices ?? [];
        }
      } catch (e) {
        console.error('Failed to fetch users offices');
      }
    }

    const formattedUsers = users.flatMap((user) => {
      // Ignore users with no oktaID
      if (user._id === oktaUserId) {
        return [];
      }
      // Get the list of users shared via user (share.id === oktaId)
      const templateSharedToUser = template.shares.sharedTo.find((share) => share.id === user._id);
      // Get the list of users shared via Group
      const templateSharedToUsersSubGroup = checkIfTemplateSharedToAnyUsersGroup(
        template.shares.sharedTo,
        user.userGroups
      );
      // Get the list of users excluded
      const userIsExcluded = checkIfUserIsExcludedFromTemplateShare(template.shares.userExclusions, user._id);
      const newUser: any = {};
      const metaData = formatUsers(user.metadata);
      newUser.firstName = metaData.FirstName;
      newUser.lastName = metaData.LastName;
      newUser.id = user._id;
      newUser.oktaUserId = user._id;
      newUser.email = metaData.Email;
      newUser.userGroups = user.userGroups;
      // Sets user.isTemplateShared if shared
      isTemplateSharedWithUser(
        newUser,
        templateSharedToUser,
        templateSharedToUsersSubGroup,
        action.payload.showGroupsTable,
        userIsExcluded,
        fetchOffices,
        usersFromOffices.flat()
      );

      return newUser;
    });

    yield put(
      actions.fetchTemplateShareInformationDone(
        formattedUsers,
        usersRes.totalCount,
        template.shares ? template.shares.sharedTo : [],
        template.shares.userExclusions ?? [],
        offices ?? [],
        usersFromOffices ?? []
      )
    );
  } catch (e) {
    yield put(errorToast(`Failed to fetch Template Share Information. Please refresh and try again.`));
  } finally {
    yield put(setLoading(false));
  }
}

export function* handleFetchTemplateShareOffices() {
  try {
    const offices: UserOffice[] = yield call(primeApi.getOffices);
    yield put(actions.fetchTemplateShareOfficesDone(offices));
  } catch (e) {}
}

export function* handleToggleTemplateShareToOffice(action: ReturnType<typeof actions.toggleTemplateShareToOffice>) {
  const sharedIds = [...action.payload.sharedIds];

  const isAllOffice = action.payload.officeGuid === allOfficeGuid;
  const officeId = action.payload.officeGuid;

  // if ALLOffice is part of the other offices shared, set id to 0 GUID
  // to know what users to share with, search for offices already shared + officeGuid if its not part of shared already, else remove it
  const updatedShareIds = sharedIds.flatMap((s) =>
    s.shareType === 'Groups' || s.shareType === 'User'
      ? s
      : s.shareType === 'Office' && s.id === officeId
      ? []
      : s.shareType === 'AllOffice' && officeId === allOfficeGuid
      ? []
      : s
  );

  const officesToSearch = getOfficesToSearch(updatedShareIds);
  const usersInOfficesToShare: OfficeUser[] = (yield call(primeApi.getUsersInOffice, officesToSearch.join(','))) || [];

  try {
    yield call(
      templateService.toggleShareTemplateToASubGroupOrOffice,
      action.payload.templateId,
      action.payload.officeGuid,
      action.payload.alreadyShared,
      isAllOffice ? 'AllOffice' : 'Office'
    );
    yield put(
      actions.toggleTemplateShareToOfficeDone(
        action.payload.alreadyShared,
        action.payload.officeGuid,
        usersInOfficesToShare,
        isAllOffice
      )
    );

    if (action.payload.alreadyShared) {
      yield put(
        successToast(`${action.payload.templateName} is no longer being shared with ${action.payload.officeName}`)
      );
    } else {
      yield put(successToast(`${action.payload.templateName} has been shared with ${action.payload.officeName}`));
    }
  } catch (e) {
    yield put(errorToast(`Failed to share template. Please try again.`));
  }
}

export function* handleFetchOfficeUsers(action: ReturnType<typeof actions.fetchOfficeUsers>) {
  const { officeGuids } = action.payload;
  const officeGuidsString = officeGuids.join(',');
  try {
    const users: OfficeUser[] = yield call(primeApi.getUsersInOffice, officeGuidsString);
    yield put(actions.fetchOfficeUsersDone(users));
  } catch (e) {
    yield put(errorToast(`Error loading template office shares. Please refresh.`));
  }
}

export function getOfficesToSearch(shares: IShareRecord[]): string[] {
  const officeGuids = shares.filter((s) => s.shareType === 'Office').map((s) => s.id);
  // we add the ALL office guid if the ALL office has been shared to
  // or if any office has been shared to, because members of the All office
  // have access to all offices
  if (shares.some((s) => s.shareType === 'AllOffice') || officeGuids.length > 0) {
    officeGuids.push(allOfficeGuid);
  }
  return officeGuids;
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.FETCH_USERS, handleFetchUsers),
    takeEvery(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_USER, toggleTemplateShareToUser),
    takeEvery(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP, toggleTemplateShareToSubGroup),
    takeEvery(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS, toggleTemplateShareToAllGroups),
    takeEvery(ActionTypes.FETCH_TEMPLATE_SHARE_INFORMATION, handleFetchTemplateShareInformation),
    takeEvery(ActionTypes.FETCH_TEMPLATE_SHARE_OFFICES, handleFetchTemplateShareOffices),
    takeEvery(ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_OFFICE, handleToggleTemplateShareToOffice),
    takeEvery(ActionTypes.FETCH_OFFICE_USERS, handleFetchOfficeUsers),
  ]);
}
