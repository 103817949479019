import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '475px',
    backgroundColor: 'white',
    border: '1px solid #CAD5E0',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: '30px',
    fontFamily: 'proxima-nova, sans-serif',
    marginBottom: '14px',
  },
  subtitle: {
    fontSize: '16px',
  },
  image: {
    width: '434px',
    marginTop: '72px',
    marginBottom: '12px',
  },
}));

interface IProps {
  image: any;
  heading: string;
  subtitle: string;
  headAria?: string;
  subAria?: string;
}

const EmptyState = (props: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div>
        <img className={classes.image} src={props.image} />
      </div>
      <div className={classes.textWrapper}>
        <Typography classes={{ h2: classes.heading }} variant="h2" aria-label={props.headAria}>
          {props.heading}
        </Typography>
        <Typography classes={{ body1: classes.subtitle }} variant="body1" aria-label={props.subAria}>
          {props.subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default EmptyState;
