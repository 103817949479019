class IdleTimer {
  timeout: number;
  onTimeout: () => void;
  eventHandler: any;
  interval: NodeJS.Timeout;
  timeoutTracker: any;
  resetToggle: boolean;
  callbackCalled: boolean;
  constructor({ timeout, onTimeout }: { timeout: number; onTimeout: () => void }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.handleStorageChange = this.handleStorageChange.bind(this);
    this.reset = this.reset.bind(this);
    this.tracker();
    this.startInterval();
    this.resetToggle = false;
    this.callbackCalled = false;
  }

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime'), 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout && !this.callbackCalled) {
          this.onTimeout();
          this.callbackCalled = true;
        }
      }
    }, 1000);
  }

  reset() {
    this.tracker();
    this.startInterval();
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      this.callbackCalled = false;
      localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000);
    }, 300);
  }

  handleStorageChange(e: StorageEvent) {
    if (e.key === '_expiredTime' && !parseInt(localStorage.getItem('_expiredTime'), 10)) {
      if (this.onTimeout) {
        this.onTimeout();
      }
    }
  }

  tracker() {
    document.addEventListener('mousemove', this.eventHandler);
    document.addEventListener('mousedown', this.eventHandler);
    document.addEventListener('keypress', this.eventHandler);
    document.addEventListener('DOMMouseScroll', this.eventHandler);
    document.addEventListener('mousewheel', this.eventHandler);
    document.addEventListener('touchmove', this.eventHandler);
    document.addEventListener('MSPointerMove', this.eventHandler);
    window.addEventListener('storage', this.handleStorageChange);
  }

  cleanUp() {
    localStorage.removeItem('_expiredTime');
    clearInterval(this.interval);
    document.removeEventListener('mousemove', this.eventHandler);
    document.removeEventListener('mousedown', this.eventHandler);
    document.removeEventListener('keypress', this.eventHandler);
    document.removeEventListener('DOMMouseScroll', this.eventHandler);
    document.removeEventListener('mousewheel', this.eventHandler);
    document.removeEventListener('touchmove', this.eventHandler);
    document.removeEventListener('MSPointerMove', this.eventHandler);
    window.removeEventListener('storage', this.handleStorageChange);
  }
}
export default IdleTimer;
