import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import React, { useEffect, useState } from 'react';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { config } from '../../lib/constants';
import useUserInfo from '../../auth/useUserInfo';

type SetFlags = (flags: LDFlagSet) => void;
type LDUserInfo = {
  name: string;
  email: string;
  accountId: string;
  ds3UserId: string;
  subscriberId: string;
  primeContactType: string;
};

const initializeLD = async (env: string, setFlags: SetFlags, userInfo: LDUserInfo) => {
  if (process.env.JEST_WORKER_ID) {
    setFlags({});
    return;
  }
  const client = LaunchDarkly.initialize(config[env].LAUNCH_DARKLY_CLIENT_ID, {
    name: userInfo.name,
    key: userInfo.ds3UserId,
    email: userInfo.email,
    custom: {
      accountId: userInfo.accountId,
      subscriberId: userInfo.subscriberId,
      primeContactType: userInfo.primeContactType,
    },
  });
  client.on('ready', () => {
    setFlags(client.allFlags());
  });
};
export type LaunchDarklyFlags = { [key: string]: any };
type LaunchDarklyState = { flags: LaunchDarklyFlags; loading: boolean };
const defaultValue: LaunchDarklyState = { flags: {}, loading: true };
export const LaunchDarklyContext = React.createContext(defaultValue);
export const launchDarklyFlags = () => React.useContext(LaunchDarklyContext);

export const LaunchDarklyProvider = ({ env, children }: { env: string; children: any }) => {
  const [value, setValue] = useState(defaultValue);
  const userInfo = useUserInfo();

  useEffect(() => {
    if (userInfo) {
      initializeLD(
        env,
        (flags) => {
          setValue({ flags, loading: false });
        },
        {
          name: userInfo.senderFirstName + ' ' + userInfo.senderLastName,
          email: userInfo.senderEmail,
          ds3UserId: userInfo.senderId,
          accountId: userInfo.digisignAccountId,
          subscriberId: userInfo.subscriberId || 'NoSubscriberId',
          primeContactType: userInfo.primeContactType || 'NoPrimeContactType',
        }
      );
    }
  }, [userInfo]);
  return <LaunchDarklyContext.Provider value={value}>{children}</LaunchDarklyContext.Provider>;
};

export const withLaunchDarkly =
  (Comp: any) =>
  (props: any): any => {
    const render = ({ flags, loading: loadingLaunchDarklyFlags }: LaunchDarklyState) => {
      if (loadingLaunchDarklyFlags) {
        return null;
      }
      return <Comp flags={flags} {...props} />;
    };
    return <LaunchDarklyContext.Consumer>{render}</LaunchDarklyContext.Consumer>;
  };
