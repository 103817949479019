import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { colors } from '@skyslope/mache';
import { Document } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { BLOCK_TYPES, BLOCK_TYPE_KEYS, readOnlyKey, SHELF_WIDTH } from '../../lib/constants';
import pdfCache from '../../lib/pdfCache';
import { useShallowSelector, useDispatch } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IRootState } from '../../store';
import {
  IBlock,
  IBlocks,
  ICopyCache,
  IDocument,
  IGroups,
  IPageDimensions,
  ISelectedBlocks,
  ISigner,
} from '../../store/senderBuild/types';
import PdfPage from './PdfPage';
import Subheader from './Subheader';
import { isMediumScreenMediaQuery, isSmallScreenMediaQuery } from '../../lib/isSmallScreen';
import { withLaunchDarkly, LaunchDarklyFlags } from '../../common/launchDarkly';
import { isSkySlopeMobileApp } from '../../common/utils';
import { Rnd } from 'react-rnd';
import { blockMenuResize } from '../../lib/utils';
import StrikeBlockToolbar from './BlockToolbars/StrikeBlockToolbar';
import SignerBlockToolbar from './BlockToolbars/SignerBlockToolbar';
import BlockActions from './BlockShelf/BlockActions';
import BlockProperties from './BlockShelf/BlockProperties';
import AutoBlocks from './AutoBlocks';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 64,
    position: 'fixed',
    overflow: 'auto',
    left: SHELF_WIDTH + 1,
    right: 0,
    top: '156px',
    borderBottom: `1px solid ${colors.grey[400]}`,
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: SHELF_WIDTH + 1,
    borderBottom: `1px solid ${colors.grey[400]}`,
    marginBottom: '100px',
  },
  mobileAppWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    left: SHELF_WIDTH + 1,
    marginTop: '20px',
    marginBottom: theme.spacing(12),
  },
  document: {
    maxWidth: '100%',
  },
  mobileDocument: {
    marginTop: 0,
  },
  documentName: {
    fontSize: '16px',
    color: colors.grey[800],
    fontWeight: 600,
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  '@global': {
    '.MuiSnackbar-root': {
      marginBottom: 64,
    },
  },
}));

const selector = (state: IRootState) => ({
  documents: state.senderBuild.documents,
  selectedBlocks: state.senderBuild.selectedBlocks,
  blocks: state.senderBuild.blocks,
  pageDimensions: state.senderBuild.pageDimensions,
  zoom: state.senderBuild.zoom,
  stateBlockMenuPos: state.senderBuild.blockMenuPos,
  copyCache: state.senderBuild.copyCache,
  checkboxGroupd: state.senderBuild.groups,
  signers: state.senderBuild.signers,
  activeSigner: state.senderBuild.activeSigner,
  mouseCoords: state.senderBuild.mouseCoords,
});

interface IState {
  documents: IDocument[];
  selectedBlocks: ISelectedBlocks;
  blocks: IBlocks;
  pageDimensions: IPageDimensions;
  zoom: number;
  stateBlockMenuPos: { x: number | null; y: number | null };
  copyCache: ICopyCache;
  checkboxGroups: IGroups;
  signers: ISigner[];
  activeSigner: string;
  mouseCoords: { x: number | null; y: number | null };
}

const PdfViewer = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const [currentDocument, setCurrentDocument] = React.useState(
    store.documents.length ? store.documents[0].documentId : null
  );
  const [currentPageIndex, setCurrentPageIndex] = React.useState(0);
  const [visibleFullPage, setVisibleFullPage] = React.useState(0);
  const [docsLoaded, setDocsLoaded] = React.useState({});
  const [vizDisabled, setVizDisabled] = React.useState(false);
  const isFullScreen = isMediumScreenMediaQuery();
  const isMobileApp = isSkySlopeMobileApp();
  const centerOfMenu = 234 / 2;
  const filteredSigners = store.signers.filter((signer) => signer.signingGroup !== '');
  const oneSelectedBlock = store.selectedBlocks.blockIndices.length === 1;
  const currentSelectedBlock =
    oneSelectedBlock &&
    store.blocks[store.selectedBlocks?.documentId][store.selectedBlocks?.pageIndex][
      store.selectedBlocks?.blockIndices[0]
    ];
  const currentBlockType = BLOCK_TYPES.find((bt) => bt.key === currentSelectedBlock.blockType);
  const isStrikeBlock = currentBlockType?.key === BLOCK_TYPE_KEYS.STRIKE;
  const isRectangleBlock = currentBlockType?.key === BLOCK_TYPE_KEYS.RECTANGLE;
  const [pdfViewerCoords, setPDFViewerCoords] = React.useState({});
  const [pdfHeaderCoords, setPDFHeaderCoords] = React.useState({});
  const [blockMenuPos, setBlockMenuPos] = React.useState({
    x: null,
    y: null,
    position: 'top',
  });

  const classes = useStyles();
  const { documents, blocks } = store;
  let fullPageIndex = 0;

  React.useEffect(() => {
    const viewerCoords = document.querySelector('.PdfViewer')!.getBoundingClientRect();
    const headerCoords = document.querySelector('.pdfViewerHeader')!.getBoundingClientRect();
    setPDFViewerCoords(viewerCoords);
    setPDFHeaderCoords(headerCoords);
  }, []);

  const [isPasteButtonDisabled, setIsPasteButtonDisabled] = useState(true);
  React.useEffect(() => {
    if (store.copyCache.blocks.length > 0) {
      setIsPasteButtonDisabled(false);
      return;
    }
    setIsPasteButtonDisabled(true);
  }, [store.copyCache.blocks]);

  React.useEffect(() => {
    if (
      oneSelectedBlock &&
      !currentSelectedBlock.groupId &&
      !isFullScreen &&
      !isMobileApp &&
      pdfHeaderCoords &&
      pdfViewerCoords
    ) {
      const blockCoords = document.querySelector(`#rnd-${currentSelectedBlock.blockId}`)!.getBoundingClientRect();
      // since we are using the coordinates of the .PdfViewer now, we need to get the location of the
      // block on the document relative to .PdfViewer so these are the new coords for the selected block
      const translateX = blockCoords!.left - pdfViewerCoords!.left;
      const translateY = blockCoords!.top - pdfViewerCoords!.top;
      let newX = translateX;
      let newY = translateY;
      let position = blockMenuPos.position;

      if (isStrikeBlock) {
        const rightX = currentSelectedBlock.x * store.zoom + currentSelectedBlock.width * store.zoom;
        const leftX = currentSelectedBlock.x * store.zoom;
        const bottomLeftY = currentSelectedBlock.y * store.zoom;
        const bottomRightY = currentSelectedBlock.y * store.zoom + currentSelectedBlock.height * store.zoom;
        if (store.mouseCoords.x === leftX && store.mouseCoords.y === bottomLeftY) {
          position = 'topLeft';
        } else if (store.mouseCoords.x === rightX && store.mouseCoords.y === bottomLeftY) {
          position = 'topRight';
        } else if (store.mouseCoords.x === rightX && store.mouseCoords.y === bottomRightY) {
          position = 'bottomRight';
        } else if (store.mouseCoords.x === leftX && store.mouseCoords.y === bottomRightY) {
          position = 'bottomLeft';
        }

        const horizontalStrike = blockCoords!.height < 5;
        const menuHeight = document.getElementById(`${currentSelectedBlock.blockId}-toolbar`)!.offsetHeight;
        if (store.mouseCoords.y !== null && store.mouseCoords.y === currentSelectedBlock.y * store.zoom) {
          // placing a strike
          if (horizontalStrike && newY - 66 < pdfHeaderCoords!.height) {
            newY = newY + 10;
          } else {
            newY = newY - 66;
          }
          if (horizontalStrike || position === 'topRight' || position === 'bottomRight') {
            newX = newX + blockCoords!.width - centerOfMenu;
          } else {
            newX = newX - centerOfMenu;
          }
        } else if (store.mouseCoords.x !== null && store.mouseCoords.y !== null) {
          // drawing a strike
          if (horizontalStrike && newY - 66 > pdfHeaderCoords!.height) {
            newY = newY - 66;
          } else {
            newY = newY + blockCoords!.height + 10;
          }
          if (horizontalStrike || position === 'topRight' || position === 'bottomRight') {
            newX = newX + blockCoords!.width - centerOfMenu;
          } else {
            newX = newX - centerOfMenu;
          }
        } else {
          // selecting a strike
          if (newY - menuHeight - 10 < pdfHeaderCoords!.height) {
            newY = newY + blockCoords!.height + 10;
          } else {
            newY = newY - menuHeight - 10;
          }
          if (horizontalStrike) {
            newX = newX + blockCoords!.width - centerOfMenu;
          } else {
            newX = newX - centerOfMenu;
          }
        }
      } else {
        const signerToolbarCoords = getSignerBlockToolBarPos(
          translateX,
          translateY,
          blockCoords!.width,
          blockCoords!.height
        );
        newX = signerToolbarCoords.x;
        newY = signerToolbarCoords.y;
      }
      setBlockMenuPos({
        x: newX,
        y: newY,
        position: position,
      });
    }
  }, [store.selectedBlocks.blockIndices]);

  React.useEffect(() => {
    const page = document.getElementById('scroll');
    const pageNum = page?.parentElement?.dataset?.fullPage;
    const viewer = document.querySelector('.PdfViewer');
    if (!viewer) return;
    if (pageNum === '0') {
      viewer.scrollTop = 0;
    } else {
      page?.scrollIntoView();
      viewer.scrollTop = 200;
    }
    setVizDisabled(false);
  }, [store.zoom]);

  React.useEffect(() => {
    if (isMobileApp) {
      window.scrollTo(0, 0);
    }
  }, []);

  const getSignerBlockToolBarPos = (blockX: number, blockY: number, blockWidth: number, blockHeight: number) => {
    const menuWidth = document.getElementById(`${currentSelectedBlock.blockId}-toolbar`)?.offsetWidth;
    const menuHeight = document.getElementById(`${currentSelectedBlock.blockId}-toolbar`)?.offsetHeight;
    if (menuHeight && menuWidth && pdfHeaderCoords && pdfViewerCoords) {
      const isCheckBox = currentBlockType?.key === BLOCK_TYPE_KEYS.CHECKBOX;
      let draftBlockMenuPos: { x: number; y: number } = {};

      if (blockX + menuWidth > pdfViewerCoords!.width) {
        draftBlockMenuPos.x = blockX - menuWidth + blockWidth + (isCheckBox ? 5 : 0);
      } else {
        // isCheckBox is a temp fix to account for the add button on checkboxes. This can be changed when that is resolved with UX
        draftBlockMenuPos.x = blockX - (isCheckBox ? 5 : 0);
      }
      if (blockY - menuHeight - 10 < pdfHeaderCoords!.height) {
        // isCheckBox is a temp fix to account for the add button on checkboxes. This can be changed when that is resolved with UX
        draftBlockMenuPos.y = blockY + blockHeight + (isCheckBox ? 36 : 10);
      } else {
        draftBlockMenuPos.y = blockY - menuHeight - 10;
      }

      return draftBlockMenuPos;
    } else return {};
  };

  const zoom = (zoomIn: true) => {
    setVizDisabled(true);
    dispatch(zoomIn ? actions.zoomIn() : actions.zoomOut());
  };

  const setVisibility = (documentId: string, pageIndex: number, fullPage: number) => {
    setCurrentDocument(documentId);
    setCurrentPageIndex(pageIndex);
    setVisibleFullPage(fullPage);
    dispatch(actions.setCurrentPage(pageIndex));
    dispatch(actions.setCurrentDocument(documentId));
  };

  const intersactionRatioTracker = useRef<Record<string, number>>({});
  const [observer, setObserver] = useState<IntersectionObserver>();

  useEffect(() => {
    const intersectionObservor = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        if (vizDisabled) return;
        const tracker = intersactionRatioTracker.current;
        entries.forEach((e) => {
          tracker[e.target.id] = e.intersectionRatio;
        });

        const pages = Array.from(document.getElementsByClassName('pdf-viewer-page')) as HTMLDivElement[];
        const mostVisiblePage = pages.reduce<HTMLDivElement | null>((prev, current) => {
          return tracker[current.id] > (prev ? tracker[prev.id] : 0) ? current : prev;
        }, null);

        if (mostVisiblePage) {
          const { documentId, pageIndex, fullPage } = mostVisiblePage.dataset;
          if (
            currentDocument !== documentId ||
            Number(pageIndex) !== currentPageIndex ||
            Number(fullPage) !== fullPageIndex
          ) {
            setVisibility(documentId!, Number(pageIndex), Number(fullPage));
          }
        }
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      }
    );

    setObserver(intersectionObservor);

    return () => {
      observer?.disconnect();
      intersactionRatioTracker.current = {};
    };
  }, [docsLoaded]);

  const dragMenu = (e: any, pos: any) => {
    dispatch(actions.setBlockMenuPos(pos.x, pos.y));
    setBlockMenuPos({
      x: pos.x,
      y: pos.y,
      position: blockMenuPos.position,
    });
  };

  const changeProperty = (key: string, value: any) => {
    let pageBlocks: IBlock[] = [];
    if (
      blocks &&
      store.selectedBlocks.documentId in blocks &&
      store.selectedBlocks.pageIndex in blocks[store.selectedBlocks.documentId]
    ) {
      pageBlocks = blocks[store.selectedBlocks.documentId][store.selectedBlocks.pageIndex];
    }
    store.selectedBlocks.blockIndices.forEach((index, i) => {
      const block = pageBlocks[index];
      const update = {
        [key]: value,
      };
      if (key === readOnlyKey) {
        update.assignedTo = value ? null : block.previouslyAssignedTo || store.activeSigner;
        if (value) {
          update.required = false;
          update.previouslyAssignedTo = block.assignedTo;
        }
      }
      dispatch(
        actions.editBlock(
          store.selectedBlocks.documentId!,
          store.selectedBlocks.pageIndex!,
          store.selectedBlocks.blockIndices[i],
          update
        )
      );
    });
  };

  const selectBlockToolbarSigner = (e: any) => {
    store.selectedBlocks.blockIndices?.forEach((blockIndex) => {
      const index = store.selectedBlocks.blockIndices.findIndex((i) => i == blockIndex);
      const block: IBlock =
        store.blocks[store.selectedBlocks.documentId!][store.selectedBlocks.pageIndex!][
          store.selectedBlocks.blockIndices[index]
        ];
      if (block.readOnly || block.groupId) return;
      dispatch(
        actions.editBlock(store.selectedBlocks.documentId!, store.selectedBlocks.pageIndex!, blockIndex, {
          assignedTo: ![BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(
            store.selectedBlocks.blockTypes![blockIndex]
          )
            ? e.target.value
            : null,
        })
      );
    });
  };

  const handleColorClick = (color: string) => {
    dispatch(
      actions.editBlock(
        store.selectedBlocks.documentId,
        store.selectedBlocks.pageIndex,
        store.selectedBlocks.blockIndices[0],
        { color }
      )
    );
  };

  const blockToolbar = () => {
    if (!currentSelectedBlock || currentSelectedBlock.groupId || isFullScreen || isMobileApp) {
      return '';
    }
    const blockOptions = () => {
      return (
        <div className="flex flex-col w-full">
          <BlockActions blockType={currentBlockType?.key} isBlockToolbar isButtonDisabled={isPasteButtonDisabled} />
          {!!currentBlockType?.properties?.length && (
            <BlockProperties
              isBlockToolbar
              firstBlock={currentSelectedBlock}
              blockTypeProperties={currentBlockType?.properties}
              changeProperty={changeProperty}
            />
          )}
        </div>
      );
    };
    return (
      <Rnd
        id={`${currentSelectedBlock.blockId}-toolbar`}
        position={{
          x: store.stateBlockMenuPos.x || blockMenuPos.x,
          y: store.stateBlockMenuPos.y || blockMenuPos.y,
        }}
        bounds={'.PdfViewer'}
        onDragStop={dragMenu}
        style={{ zIndex: 9999, position: 'fixed' }}
        enableResizing={blockMenuResize}
      >
        {isStrikeBlock ? (
          <StrikeBlockToolbar
            blockOptions={blockOptions()}
            block={currentSelectedBlock}
            handleColorClick={handleColorClick}
          />
        ) : (
          <SignerBlockToolbar
            blockOptions={blockOptions()}
            blocks={[currentSelectedBlock]}
            isDisabled={!!currentSelectedBlock.groupId || currentSelectedBlock.readOnly}
            selectSigner={selectBlockToolbarSigner}
            signers={filteredSigners}
          />
        )}
      </Rnd>
    );
  };

  const wrapperClass = isMobileApp ? classes.mobileAppWrapper : isFullScreen ? classes.mobileWrapper : classes.wrapper;
  const documentClass = isFullScreen ? `${classes.document} ${classes.mobileDocument} first:mt-8` : classes.document;
  return (
    <div className={`${wrapperClass} PdfViewer`}>
      {!isRectangleBlock && blockToolbar()}
      {isFullScreen ? (
        ''
      ) : (
        <Subheader zoom={zoom} currentDocumentId={currentDocument} currentPageIndex={currentPageIndex} />
      )}
      <AutoBlocks />
      {documents.map((document: IDocument) => {
        const res = (
          <div key={document.documentId} className={`${documentClass} `}>
            {document.isDownloaded && (
              <Document
                file={pdfCache[document.documentId]}
                loading=""
                onLoadSuccess={() => setDocsLoaded({ ...docsLoaded, [document.documentId]: true })}
              >
                {Array(document.numberOfPages)
                  .fill(null)
                  .map((_, pageIndex: number) => {
                    const selectingOnThisPage =
                      store.selectedBlocks.documentId === document.documentId &&
                      store.selectedBlocks.pageIndex === pageIndex;
                    let pageBlocks: IBlock[] = [];
                    if (blocks && document.documentId in blocks && pageIndex in blocks[document.documentId]) {
                      pageBlocks = blocks[document.documentId][pageIndex];
                    }
                    return (
                      <PdfPage
                        observer={observer!}
                        key={fullPageIndex + pageIndex}
                        documentId={document.documentId}
                        pageIndex={pageIndex}
                        fullPage={fullPageIndex + pageIndex}
                        totalPages={document.numberOfPages!}
                        pageBlocks={pageBlocks}
                        selectedBlocks={selectingOnThisPage ? store.selectedBlocks.blockIndices : []}
                        flags={flags}
                        visiblePage={visibleFullPage}
                        isDownloaded
                        vizDisabled={vizDisabled}
                      />
                    );
                  })}
              </Document>
            )}
            {!docsLoaded[document.documentId] &&
              Array(document.numberOfPages)
                .fill(null)
                .map((_, pageIndex: number) => (
                  <PdfPage
                    observer={observer!}
                    key={`${fullPageIndex + pageIndex}-skeleton`}
                    documentId={document.documentId}
                    pageIndex={pageIndex}
                    fullPage={fullPageIndex + pageIndex}
                    totalPages={document.numberOfPages!}
                    pageBlocks={[]}
                    selectedBlocks={[]}
                    flags={flags}
                    visiblePage={visibleFullPage}
                  />
                ))}
          </div>
        );
        fullPageIndex += document.numberOfPages!;
        return res;
      })}
    </div>
  );
};

export default withLaunchDarkly(PdfViewer);
