import React from 'react';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';
import ActivityLog from '../components/envelopeHistory/ActivityLog';
import Summary from '../components/envelopeHistory/Summary';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import * as actions from '../store/envelopeHistory/actions';
import { HeaderContext } from '../context/header-context';
import FooterLinks from '../components/common/FooterLinks';
import { IRootState } from '../store';
import { preferencesUrl } from '../lib/constants';
import { ISettings } from '../store/pageFrame/types';
import { getUsersGroups } from '../lib/api/account/accountApi';
import { LaunchDarklyFlags, withLaunchDarkly } from '../common/launchDarkly';
import NavHeader from '../common/navigation';

interface IState {
  settings: ISettings;
  settingsFetched: boolean;
}

const selector = (state: IRootState) => ({
  settings: state.pageFrame.settings,
  settingsFetched: state.pageFrame.settingsFetched,
});

export const EnvelopeHistory = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const store: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const headerContext = React.useContext(HeaderContext);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];

  React.useEffect(() => {
    dispatch(actions.fetch(params.id));
    headerContext.setContent(
      <NavHeader
        labelName="Envelope history"
        breadcrumb={{ name: 'Envelope Management', onClick: () => history.push('/envelopes') }}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        userOrigin={store.settings?.account.userOrigin}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );
    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, [store.settingsFetched]);

  return (
    <Container role="main">
      <Summary />
      <ActivityLog flags={flags} />
      <FooterLinks showSocialIcons />
    </Container>
  );
};

export default withLaunchDarkly(EnvelopeHistory);
