import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { colors } from '@skyslope/mache';
import React, { useEffect, useState } from 'react';
import { useShallowSelector } from '../../lib/reduxHooks';
import { IRootState } from '../../store';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    color: colors.grey[600],
    zIndex: 1100,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
}));

interface IState {
  isAutoSaving: undefined | boolean;
  errorShow: boolean;
}

const selector = (state: IRootState) => ({
  isAutoSaving: state.senderBuild.isAutoSaving,
  errorShow: state.pageFrame.errorShow,
});

const AutoSaveIndicator = () => {
  const { isAutoSaving, errorShow }: IState = useShallowSelector(selector);
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  const [error, setError] = useState(errorShow);

  useEffect(() => {
    if (!isAutoSaving) {
      const timer = setTimeout(() => {
        setIsHidden(true);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setIsHidden(false);
    }
  }, [isAutoSaving]);

  useEffect(() => {
    if (!errorShow) {
      setError(false);
    } else {
      setError(true);
    }
  }, [errorShow]);

  return (
    <>
      {!isHidden && !error && (
        <div className={classes.wrapper}>
          <Typography variant="body1">{isAutoSaving ? 'Saving Changes...' : 'All Changes Saved!'}</Typography>
        </div>
      )}
    </>
  );
};

export default AutoSaveIndicator;
