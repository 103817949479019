import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { colors } from '@skyslope/mache';
import WarningIcon from '@material-ui/icons/Warning';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { IRecipient } from '../types';
import Recipient from './Recipient';

type Props = {
  placeholderRecipients: IRecipient[];
  editRecipient: (recipient: IRecipient) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingRight: theme.spacing(2),
  },
  recipientContainer: {
    display: 'flex',
    flexFlow: 'row',
    flexGrow: 1,
  },
  title: {
    fontSize: '14px',
    color: `${colors.grey[800]}`,
  },
  spacer: {
    color: `${colors.grey[500]}`,
    margin: '0px 7px',
  },
  recipient: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  placeholderRecipientContainer: {
    background: '#F3F8FC',
    padding: '0 16px',
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {
    cursor: 'pointer',
    zIndex: 1,
  },
  root: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    color: `${colors.grey[800]}`,
  },
  summaryRoot: {
    padding: 0,
  },
  grid: {
    display: 'grid',
    gridGap: theme.spacing(3),
  },
}));

const PlaceholderRecipient = (props: Props) => {
  const classes = useStyles();
  const { placeholderRecipients, editRecipient } = props;

  const handleKeydown = (e: any, r: IRecipient) => {
    if (e.key === 'Enter') {
      editRecipient(r);
    }
  };
  return (
    <div className={classes.placeholderRecipientContainer} data-spec="placeholderWrapper">
      <Typography
        component="span"
        variant="body1"
        aria-label={`Need details for ${placeholderRecipients?.length} recipient${
          placeholderRecipients?.length > 1 ? 's' : ''
        }`}
      >
        <Accordion defaultExpanded classes={{ root: classes.root }}>
          <AccordionSummary
            classes={{ root: classes.summaryRoot }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-spec="placeholderDropdown"
          >
            <div className={classes.subheader}>
              <span style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <WarningIcon style={{ marginRight: '16px' }} />
                Need details for {placeholderRecipients.length} recipient{placeholderRecipients.length > 1 ? 's' : ''}
              </span>
            </div>
          </AccordionSummary>
          <Divider style={{ width: '100%' }} />
          <AccordionDetails
            className={classes.grid}
            style={{ flexDirection: 'column', paddingTop: '16px', paddingLeft: '24px' }}
          >
            {placeholderRecipients?.map((r: IRecipient, i: number) => {
              return (
                <div className={classes.recipient} data-spec={`placeholder-${i}`}>
                  <Recipient
                    firstName={r.firstName}
                    lastName={r.lastName}
                    color={r.color}
                    title={r.title}
                    isPlaceholder
                  />
                  <span tabIndex={0} onKeyDown={(e: any) => handleKeydown(e, r)}>
                    <AddCircleOutlineIcon
                      className={classes.addIcon}
                      onClick={() => editRecipient(r)}
                      data-spec="editPlaceholderRecipient"
                    />
                  </span>
                </div>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </Typography>
    </div>
  );
};

export default PlaceholderRecipient;
