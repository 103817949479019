import update from 'immutability-helper';
import { IShareRecord, ITemplateShare, ITemplateSharingState, ITemplateUser, IUserOffice } from './types';
import { ActionTypes } from './actions';
import {
  allOfficeGuid,
  checkIfTemplateSharedToAnyUsersGroup,
  checkIfUserIsInUserExclusions,
  toggleGroupAlphabeticalSort,
  toggleOfficeAlphabeticalSort,
} from '../../lib/utils';
import { OfficeUser } from '../../lib/api/prime/prime';

export const initialState: ITemplateSharingState = {
  isFetching: true,
  users: [],
  templateShares: {},
  totalUsers: 0,
  currentUsers: 0,
  endReached: false,
  sharesFetched: false,
  fetchError: false,
  search: '',
  sharedIds: [],
  fromSearch: false,
  userExclusions: [],
  sharedToAllParentGroups: false,
  parentGroups: [],
  subGroups: [],
  orderByDesc: false,
  orderOfficeByDesc: false,
  offices: [],
  usersFromOffices: [],
  fetchingOfficeUsers: false,
};

// set users.isTemplateShared if template is shared to user via group, office or directly
function setUsersIsShared(users: ITemplateUser[], sharedIds: IShareRecord[], usersSharedByOffice: OfficeUser[]) {
  const usersSharedByOfficeIds = usersSharedByOffice?.map((u) => u.oktaUserID) || [];

  users.forEach((u) => {
    const templateSharedToUserGroup = checkIfTemplateSharedToAnyUsersGroup(sharedIds, u.userGroups);
    const templateSharedToUser = sharedIds.some((s) => s.id === u.oktaUserId);
    const templateSharedToUserOffice = usersSharedByOfficeIds.includes(u.oktaUserId);
    u.isTemplateShared = !!(templateSharedToUserGroup || templateSharedToUser || templateSharedToUserOffice);
  });
}

export default (state: ITemplateSharingState = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.FETCH_USERS_DONE: {
      let users: ITemplateUser[] = action.payload.users;
      const usersFromOfficeSet: Set<string> = new Set(state.usersFromOffices.map((u) => u.oktaUserID));
      users.forEach((u) => {
        const templateSharedToUser = state.sharedIds.some((share) => share.id === u.oktaUserId);
        const templateSharedToAUsersGroup = checkIfTemplateSharedToAnyUsersGroup(state.sharedIds, u.userGroups);
        const userIsInUserExclusions = checkIfUserIsInUserExclusions(state.userExclusions, u);
        if (templateSharedToUser || templateSharedToAUsersGroup) {
          u.isTemplateShared = true;
        }
        if (usersFromOfficeSet.has(u.oktaUserId) && !userIsInUserExclusions) {
          u.isTemplateShared = true;
        }
      });
      const totalUsers: number = action.payload.totalCount;
      const filteredUsers = users.map((u: ITemplateUser) => {
        return {
          ...u,
          name: `${u.firstName} ${u.lastName}`,
        };
      });
      const joinedUsers = state.users.concat(filteredUsers);
      const usersToSet = action.payload.fromSearch && !action.payload.fromScroll ? filteredUsers : joinedUsers;
      const endReached =
        action.payload.fromSearch && !action.payload.fromScroll
          ? filteredUsers.length >= totalUsers
          : users.length === 0 || joinedUsers.length >= totalUsers;
      return update(state, {
        isFetching: { $set: false },
        users: { $set: usersToSet },
        totalUsers: { $set: totalUsers },
        endReached: { $set: endReached },
        search: action.payload.search ? { $set: action.payload.search } : { $set: '' },
      });
    }
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_USER_DONE: {
      let { user, sharedToAllParentGroups, sharedToASubGroup, error } = action.payload;
      let userExclusions = [...state.userExclusions];
      let newUsers = [...state.users];
      const usersFromOfficeSet: Set<string> = new Set(state.usersFromOffices.map((u) => u.oktaUserID));

      if (!!user && !error) {
        newUsers = state.users.map((u) => {
          if (u.id === user.id) {
            return { ...u, isTemplateShared: user.isTemplateShared };
          }
          return u;
        });
      }
      const currentShares = [...state.sharedIds];
      if (!error) {
        if (sharedToAllParentGroups || sharedToASubGroup || usersFromOfficeSet.has(user?.oktaUserId)) {
          if (user?.isTemplateShared) {
            // the user was previously excluded from the template and now we are sharing to them
            // remove them from userExclusions in the state
            const userIndex = userExclusions.findIndex((ex) => ex === user.oktaUserId);
            if (userIndex > -1) {
              userExclusions.splice(userIndex, 1);
            }
          } else {
            // the template was previously shared to user in a group and now we are excluding them
            // add them to userExclusions in the state if they aren't already added
            const userIsExcluded = userExclusions.includes(user.oktaUserId);
            if (!userIsExcluded) {
              userExclusions.push(user.oktaUserId);
            }
          }
        } else if (user?.isTemplateShared) {
          // the template was not shared with the user and now we are sharing with them individually
          // add to shares list in the state
          currentShares.push({ id: user.oktaUserId, shareType: 'User' });
          // update user exclusions if needed
          const exclusionToRemove = userExclusions.find((ex) => ex === user.oktaUserId);
          const indexToRemove = userExclusions.indexOf(exclusionToRemove!);
          if (indexToRemove > -1) userExclusions.splice(indexToRemove, 1);
        } else {
          // the template was shared with the user individually and now we are un-sharing with them
          // remove from shares list in the state
          const shareToRemove = currentShares.find((share) => share.id === user.oktaUserId);
          const indexToRemove = currentShares.indexOf(shareToRemove!);
          if (indexToRemove > -1) currentShares.splice(indexToRemove, 1);
        }
      }
      return update(state, {
        isFetching: { $set: false },
        users: { $set: newUsers },
        sharedIds: { $set: currentShares },
        userExclusions: { $set: userExclusions },
      });
    }
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_USER:
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP:
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS:
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_OFFICE: {
      return update(state, {
        isFetching: { $set: true },
      });
    }
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_SUB_GROUP_DONE: {
      const { groupId, sharedToAllParentGroups, error, usersSharedByOffice } = action.payload;
      let sharedIds: IShareRecord[] = [...state.sharedIds];
      let users: ITemplateUser[] = [...state.users];
      let userExclusions: string[] = [...state.userExclusions];
      let sharedToAll: boolean = sharedToAllParentGroups;
      if (!error) {
        // if sharedToAllParentGroups was true we are now unsharing to all - empty current shared ids
        if (sharedToAllParentGroups) {
          sharedToAll = false;
          sharedIds = [];
          userExclusions = [];
          users = users.map((u) => {
            u.isTemplateShared = false;
            return u;
          });
        } else {
          // if template is already shared, remove single group from current shares, else add single group to current shares
          const shareToRemoveIndex = sharedIds.findIndex((share) => share.id === groupId);
          if (shareToRemoveIndex > -1) {
            sharedIds.splice(shareToRemoveIndex, 1);
          } else {
            sharedIds.push({ id: groupId, shareType: 'Group' });
          }
          setUsersIsShared(users, sharedIds, usersSharedByOffice);
        }
      }
      return update(state, {
        isFetching: { $set: false },
        users: { $set: users },
        userExclusions: { $set: userExclusions },
        sharedToAllParentGroups: { $set: sharedToAll },
        sharedIds: { $set: sharedIds },
      });
    }
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_ALL_PARENT_GROUPS_DONE: {
      const { alreadyShared, error } = action.payload;
      let updatedUsers: ITemplateUser[] = [];
      let { sharedIds } = state;
      const offices = [...state.offices];
      if (!error) {
        if (alreadyShared) {
          sharedIds = [];
          updatedUsers = state.users.map((u) => {
            u.isTemplateShared = false;
            return u;
          });
        } else {
          state.parentGroups?.forEach((g) => sharedIds.push({ id: g, shareType: 'Group' }));
          updatedUsers = state.users.map((u) => {
            u.isTemplateShared = true;
            return u;
          });

          offices?.forEach((office) => {
            office.isTemplateShared = false;
          });
        }
      } else {
        updatedUsers = state.users;
      }
      return update(state, {
        isFetching: { $set: false },
        sharedToAllParentGroups: { $set: error ? state.sharedToAllParentGroups : !state.sharedToAllParentGroups },
        users: { $set: updatedUsers },
        userExclusions: { $set: error ? state.userExclusions : [] },
        sharedIds: { $set: sharedIds },
        offices: { $set: offices },
        usersFromOffices: { $set: [] },
      });
    }
    case ActionTypes.FETCH_USERS_GROUPS_DONE: {
      const { sharedToAllParentGroups, parentGroups, subGroups, error } = action.payload;
      const sortedSubGroups = toggleGroupAlphabeticalSort(subGroups, state.orderByDesc);
      return update(state, {
        sharedToAllParentGroups: { $set: error ? state.sharedToAllParentGroups : sharedToAllParentGroups },
        parentGroups: { $set: error ? state.parentGroups : parentGroups },
        subGroups: { $set: error ? state.subGroups : sortedSubGroups },
      });
    }
    case ActionTypes.TOGGLE_GROUPS_SORT: {
      const sortedGroups = toggleGroupAlphabeticalSort(state.subGroups, !state.orderByDesc);
      return update(state, {
        subGroups: { $set: sortedGroups },
        orderByDesc: { $set: !state.orderByDesc },
      });
    }
    case ActionTypes.TOGGLE_OFFICE_SORT: {
      const sortedOffices = toggleOfficeAlphabeticalSort(state.offices, !state.orderOfficeByDesc);
      return update(state, {
        offices: { $set: sortedOffices },
        orderOfficeByDesc: { $set: !state.orderOfficeByDesc },
      });
    }
    case ActionTypes.FETCH_TEMPLATE_SHARE_INFORMATION_DONE: {
      const totalUsers: number = action.payload.totalUsers || state.totalUsers;
      const { shares, offices, usersFromOffices } = action.payload;

      if (shares.length) {
        const sharedOfficeGuids = shares.filter((s) => s.shareType === 'Office').map((s) => s.id);
        const isAllOfficeShared = shares.some((s) => s.shareType === 'AllOffice');

        offices.forEach((office) => {
          if (
            sharedOfficeGuids.includes(office.officeGuid) ||
            (isAllOfficeShared && office.officeGuid === allOfficeGuid)
          ) {
            office.isTemplateShared = true;
          }
        });
      }
      // first time office sort is ascending
      const sortedOffices = toggleOfficeAlphabeticalSort(offices, false);
      const formattedUsers = action.payload.users.map((u) => {
        return {
          ...u,
          name: `${u.firstName} ${u.lastName}`,
        };
      });

      const endReached = formattedUsers.length >= totalUsers;
      return update(state, {
        isFetching: { $set: false },
        sharesFetched: { $set: true },
        users: { $set: formattedUsers },
        sharedIds: { $set: action.payload.shares },
        totalUsers: { $set: totalUsers },
        endReached: { $set: endReached },
        userExclusions: { $set: action.payload.userExclusions },
        offices: { $set: sortedOffices || state.offices },
        usersFromOffices: { $set: usersFromOffices || state.usersFromOffices },
      });
    }
    case ActionTypes.FETCH_TEMPLATE_SHARE_OFFICES_DONE: {
      const { offices } = action.payload;
      const sortedOffices = toggleOfficeAlphabeticalSort(offices, state.orderOfficeByDesc);
      return update(state, {
        offices: { $set: sortedOffices },
      });
    }
    case ActionTypes.TOGGLE_TEMPLATE_SHARE_TO_OFFICE_DONE: {
      const updatedOffices: IUserOffice[] = [...state.offices];
      const users: ITemplateUser[] = [...state.users];
      const sharedIds: IShareRecord[] = [...state.sharedIds];
      const { alreadyShared, officeGuid, usersInOffice, isAllOffice } = action.payload;
      const officeToUpdate = updatedOffices.find((o) => o.officeGuid === officeGuid);

      if (alreadyShared && officeToUpdate) {
        officeToUpdate!.isTemplateShared = false;
      } else {
        officeToUpdate!.isTemplateShared = true;
      }

      const shareToRemoveIndex = sharedIds.findIndex((s) =>
        !isAllOffice ? s.id === officeGuid : s.shareType === 'AllOffice'
      );
      if (shareToRemoveIndex > -1) {
        sharedIds.splice(shareToRemoveIndex, 1);
      } else {
        sharedIds.push({ id: officeGuid, shareType: isAllOffice ? 'AllOffice' : 'Office' });
      }
      setUsersIsShared(users, sharedIds, usersInOffice);
      return update(state, {
        offices: { $set: updatedOffices },
        sharedIds: { $set: sharedIds },
        users: { $set: users },
        isFetching: { $set: false },
        usersFromOffices: { $set: usersInOffice },
      });
    }
    case ActionTypes.FETCHING_OFFICE_USERS: {
      return update(state, {
        fetchingOfficeUsers: { $set: true },
      });
    }
    case ActionTypes.FETCH_OFFICE_USERS_DONE: {
      const { officeUsers } = action.payload;
      return update(state, {
        usersFromOffices: { $set: officeUsers },
        fetchingOfficeUsers: { $set: false },
      });
    }
    case ActionTypes.UPDATE_USERS: {
      const { users } = action.payload;
      return update(state, {
        users: { $set: users },
      });
    }
    case ActionTypes.RESET: {
      return update(state, {
        users: { $set: [] },
        totalUsers: { $set: 0 },
        endReached: { $set: false },
      });
    }
    default: {
      return state;
    }
  }
};
