import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useAutomaticFontSizing } from '../../lib/useAutomaticFontSizing';
import useDebounce from '../../lib/useDebounce';
import { useDispatch } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';

interface IProps {
  blockId: string;
  value?: string;
  fontSize?: number;
  height: number;
  width: number;
  updateBlock: Function;
  zoom: number;
  isCreating: boolean;
}

const TextBox = (props: IProps) => {
  const dispatch = useDispatch();
  const { fontSize, value, setValue } = useAutomaticFontSizing({
    height: props.height,
    width: props.width,
    zoom: props.zoom,
    initialFontSize: props.fontSize,
    initialValue: props.value,
  });
  const blockId = props.blockId;
  const debouncedFontsize = useDebounce(fontSize, 50);
  const debouncedValue = useDebounce(value, 50);
  const firstRender = React.useRef(true);

  // Reset the value whenever a different block is selected
  React.useEffect(() => {
    setValue(props.value!);
  }, [blockId]);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if ((props.fontSize !== fontSize || props.value !== value) && !props.isCreating) {
      props.updateBlock({ fontSize, value });
    }
  }, [debouncedFontsize, debouncedValue]);

  React.useEffect(() => {
    // @ts-ignore
    setValue(props.value);
  }, [props.value]);

  const onType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onFocus = () => dispatch(actions.focusInput());

  const onBlur = () => dispatch(actions.blurInput());
  return (
    <TextField
      style={{ margin: 0 }}
      variant="filled"
      margin="none"
      onChange={onType}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value || ''}
      rowsMax={10}
      fullWidth
      multiline
      inputProps={{
        onKeyUp: (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
          // prevent backspace from deleting
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        },
      }}
      id="textFieldInput"
    />
  );
};

export default TextBox;
