import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/applyTemplate/actions';
import { IRootState } from '../../store';
import { IBlockWithSignerNumber, ITemplateMetadata } from '../../store/applyTemplate/types';
import { colors, elevation } from '@skyslope/mache';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from '../common/Search';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 68,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    borderBottom: `1px solid ${colors.grey[500]}`,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  scrollContainer: {
    overflowY: 'auto',
    position: 'absolute',
    top: 72,
    bottom: 0,
    left: 0,
    right: 0,
  },
  search: {
    margin: theme.spacing(3),
  },
  templates: {},
  template: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 24px 16px',
    padding: '12px 18px',
    boxShadow: elevation.active[6],
    '&:hover': {
      cursor: 'pointer',
      boxShadow: elevation.active[12],
    },
  },
  templateText: {
    overflow: 'hidden',
    marginRight: '16px',
  },
  addButton: {
    color: colors.blue[800],
  },
  removeButton: {
    color: colors.grey[800],
  },
  signers: {
    color: colors.grey[500],
  },
  appliedTemplate: {
    boxShadow: 'none',
    border: `1px solid ${colors.grey[500]}`,
    borderLeft: `3px solid ${colors.blue[800]}`,
    backgroundColor: colors.blue[50],
    paddingLeft: 15,
  },
  loadMore: {
    display: 'flex',
    margin: '24px auto',
  },
  templateName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export interface AppliedTemplate {
  template: string;
  data: {
    document: string;
  };
}

interface IProps {
  handleAddTemplate: (template: any, documentId?: string) => void;
  handleRemoveTemplate: (documentId: string) => void;
}

interface IState {
  templates?: ITemplateMetadata[];
  appliedTemplate?: { metadata: ITemplateMetadata; blocks?: IBlockWithSignerNumber[] };
  selectedDocumentId: string;
}

const selector = (state: IRootState) => ({
  templates: state.applyTemplate.templates,
  appliedTemplate: state.applyTemplate.appliedTemplates[state.applyTemplate.selectedDocumentId!],
  selectedDocumentId: state.applyTemplate.selectedDocumentId,
});

const TemplatesList = (props: IProps) => {
  const { templates, appliedTemplate, selectedDocumentId }: IState = useShallowSelector(selector);
  const { handleAddTemplate, handleRemoveTemplate }: IProps = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [shownTemplates, setShownTemplates] = React.useState<ITemplateMetadata[]>([]);
  const [showLoader, setShowLoader] = React.useState(true);
  const [firstRenderComplete, setFirstRenderComplete] = React.useState(false);
  const scrollContainer = React.useRef<HTMLDivElement>(null);
  const scanIndex = React.useRef(0);

  const togglePreview = (templateId: string) => {
    if (templateId === appliedTemplate?.metadata.id) {
      dispatch(actions.previewTemplate(undefined));
      handleRemoveTemplate(selectedDocumentId);
    } else {
      dispatch(actions.previewTemplate(templateId));
      handleSaveTemplate(templateId);
    }
  };

  const handleSaveTemplate = (templateId: string) => {
    const template: AppliedTemplate = {
      template: templateId,
      data: { document: selectedDocumentId },
    };

    handleAddTemplate(template, selectedDocumentId);
  };

  // New set of templates when they are first loaded or when search changes
  React.useEffect(() => {
    scanIndex.current = 0;
    loadMoreTemplates(true);
    // turns off the loader to show the no templates text if there are none at all
    if (templates?.length === 0 && showLoader) {
      setShowLoader(false);
    }
  }, [templates, search]);

  // When we reach the bottom of the scroll container, load more templates
  const onScroll = (e: any) => {
    const reachedBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (reachedBottom) {
      loadMoreTemplates();
    }
  };

  // If the original 10 templates aren't enough to make the container scroll, automatically load more
  React.useEffect(() => {
    // Wait until after render to check scroll
    setTimeout(() => {
      if (scrollContainer.current && firstRenderComplete) {
        if (scrollContainer.current!.scrollHeight === scrollContainer.current!.clientHeight) {
          loadMoreTemplates();
        }
      }
    }, 0);
  }, [shownTemplates.length]);

  const loadMoreTemplates = (reset = false) => {
    if (!templates) {
      return;
    }
    if (scanIndex.current === templates!.length) {
      return;
    }
    const foundTemplateIds = new Set();
    const addTemplate = (list: ITemplateMetadata[], template: ITemplateMetadata) => {
      const templateId = template.id;
      if (foundTemplateIds.has(templateId)) return;

      foundTemplateIds.add(templateId);
      list.push(template);
    };
    setShowLoader(true);
    const newTemplates: ITemplateMetadata[] = [];
    if (reset && appliedTemplate) {
      addTemplate(newTemplates, appliedTemplate.metadata);
    }

    const searchLower = search.toLowerCase();
    while (newTemplates.length < 10 && scanIndex.current < templates!.length) {
      const template = templates![scanIndex.current];
      if (template.id === appliedTemplate?.metadata.id) {
        scanIndex.current++;
        addTemplate(newTemplates, template);
        continue;
      }
      if (searchLower) {
        if (template.name.toLowerCase().includes(searchLower)) {
          addTemplate(newTemplates, template);
        }
      } else {
        addTemplate(newTemplates, template);
      }
      scanIndex.current++;
    }
    setShowLoader(false);
    setShownTemplates(reset ? newTemplates : [...shownTemplates, ...newTemplates]);
    if (!firstRenderComplete) {
      setFirstRenderComplete(true);
    }
  };

  return (
    <div data-spec="templates-list">
      <div className={classes.header}>
        <Typography variant="subtitle1">TEMPLATES</Typography>
      </div>
      <div className={classes.scrollContainer} onScroll={onScroll} ref={scrollContainer}>
        <div className={classes.search}>
          <Search value={search} onChange={setSearch} />
        </div>
        {!shownTemplates?.length && !showLoader ? (
          <Typography variant="body1" align="center">
            {templates?.length ? 'No templates found' : 'No templates have been created yet'}
          </Typography>
        ) : (
          ''
        )}
        <div className={classes.templates}>
          {shownTemplates?.length
            ? shownTemplates!.map((template) => (
                <Card
                  key={template.id}
                  className={`${classes.template} ${
                    template.id === appliedTemplate?.metadata.id ? classes.appliedTemplate : ''
                  }`}
                  onClick={() => togglePreview(template.id)}
                >
                  <div className={classes.templateText}>
                    <Typography variant="body1" className={classes.templateName}>
                      {template.name}
                    </Typography>
                    <Typography variant="overline" className={classes.signers}>
                      {template.numberOfSigners} SIGNER{template.numberOfSigners !== 1 ? 'S' : ''}
                    </Typography>
                  </div>
                  {template.id === appliedTemplate?.metadata.id ? (
                    <RemoveIcon className={classes.removeButton} />
                  ) : (
                    <AddIcon className={classes.addButton} />
                  )}
                </Card>
              ))
            : ''}
        </div>
        {showLoader && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplatesList;
