import { action } from 'typesafe-actions';
import { ITemplateRecipient, ITemplate, ITemplateCreateState } from './types';
import { IDocument } from '../senderBuild/types';

export enum ActionTypes {
  RESET = 'templateCreate/RESET',
  API_ERROR = 'templateCreate/API_ERROR',
  FETCH_TEMPLATE = 'templateCreate/FETCH_TEMPLATE',
  FETCH_TEMPLATE_DONE = 'templateCreate/FETCH_TEMPLATE_DONE',
  UPDATE_TEMPLATE = 'templateCreate/UPDATE_TEMPLATE',
  UPDATE_TEMPLATE_DONE = 'templateCreate/UPDATE_TEMPLATE_DONE',
  UPLOAD_DOCUMENT = 'templateCreate/UPLOAD_DOCUMENT',
  UPLOAD_DOCUMENT_DONE = 'templateCreate/UPLOAD_DOCUMENT_DONE',
  ADD_RECIPIENT = 'templateCreate/ADD_RECIPIENT',
  UPDATE_RECIPIENT = 'templateCreate/UPDATE_RECIPIENT',
  UPDATE_RECIPIENT_DONE = 'templateCreate/UPDATE_RECIPIENT_DONE',
  CHANGE_RECIPIENT_TITLE = 'templateCreate/CHANGE_RECIPIENT_TITLE',
  DELETE_DOCUMENT = 'templateCreate/DELETE_DOCUNENT',
  REORDER_DOCUMENTS = 'templateCreate/REORDER_DOCUMENTS',
  UPDATE_DOCUMENTS = 'templateCreate/UPDATE_DOCUMENTS',
  DELETE_RECIPIENT = 'templateCreate/DELETE_RECIPIENT',
  DELETE_RECIPIENT_DONE = 'templateCreate/DELETE_RECIPIENT_DONE',
}

export const reset = (newStore?: ITemplateCreateState) => action(ActionTypes.RESET, newStore);
export const apiError = (error?: string) => action(ActionTypes.API_ERROR, error);

export const fetchTemplate = (templateId: string) => action(ActionTypes.FETCH_TEMPLATE, templateId);
export const fetchTemplateDone = (template: ITemplate) => action(ActionTypes.FETCH_TEMPLATE_DONE, template);

export const updateTemplate = (patchBody: Partial<ITemplate>) => action(ActionTypes.UPDATE_TEMPLATE, patchBody);
export const updateTemplateDone = (template: ITemplate) => action(ActionTypes.UPDATE_TEMPLATE_DONE, template);

export const uploadDocument = (tempId: string, file: File) => action(ActionTypes.UPLOAD_DOCUMENT, { tempId, file });
export const uploadDocumentDone = (tempId: string, document: IDocument) =>
  action(ActionTypes.UPLOAD_DOCUMENT_DONE, { tempId, document });

export const addRecipient = (recipient: ITemplateRecipient) => action(ActionTypes.ADD_RECIPIENT, recipient);

export const updateRecipient = (recipientId: string, update: Partial<ITemplateRecipient>) =>
  action(ActionTypes.UPDATE_RECIPIENT, { recipientId, update });

export const updateRecipientDone = (recipientId: string, recipient: ITemplateRecipient) =>
  action(ActionTypes.UPDATE_RECIPIENT_DONE, { recipientId, recipient });

export const changeRecipientTitle = (recipientId: string, title: string) =>
  action(ActionTypes.CHANGE_RECIPIENT_TITLE, { recipientId, title });

export const deleteDocument = (documentId: string) => action(ActionTypes.DELETE_DOCUMENT, documentId);

export const reorderDocuments = (documents: IDocument[]) => action(ActionTypes.REORDER_DOCUMENTS, documents);

export const updateDocuments = (documents: IDocument[]) => action(ActionTypes.UPDATE_DOCUMENTS, documents);

export const deleteRecipient = (templateId: string, recipient: ITemplateRecipient) =>
  action(ActionTypes.DELETE_RECIPIENT, { templateId, recipient });

export const deleteRecipientDone = (recipientId: string) => action(ActionTypes.DELETE_RECIPIENT_DONE, recipientId);
