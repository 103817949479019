import { Paper } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import ListItem from '@material-ui/core/ListItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { colors, elevation } from '@skyslope/mache';
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useShallowSelector } from '../lib/reduxHooks';
import { IRootState } from '../store';
import * as actions from '../store/templateTagging/actions';
import { ITagType, ISettings } from '../store/pageFrame/types';
import Autocomplete from '../components/common/Autocomplete';
import { HeaderContext } from '../context/header-context';
import { preferencesUrl } from '../lib/constants';
import { getUsersGroups } from '../lib/api/account/accountApi';
import NavHeader from '../common/navigation';
import { withLaunchDarkly, LaunchDarklyFlags } from '../common/launchDarkly';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: `${theme.spacing(7)}px ${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: colors.grey[700],
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  content: {
    maxWidth: 627,
    width: '100%',
    padding: theme.spacing(6),
    margin: '0 auto',
    boxShadow: elevation.active[6],
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: 28,
    },
  },
  shortField: {
    width: 300,
    '& > *': {
      width: '100%',
    },
  },
  footer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '24px 36px 4px',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));

interface IState {
  loading: boolean;
  settings?: ISettings;
  settingsFetched: boolean;
  name: string;
  tagsById: { [tagTypeId: string]: string[] };
  saveDone: boolean;
}

const selector = (state: IRootState) => ({
  loading: state.pageFrame.loading,
  settings: state.pageFrame.settings,
  name: state.templateTagging.name,
  tagsById: state.templateTagging.tagsById,
  saveDone: state.templateTagging.saveDone,
  settingsFetched: state.pageFrame.settingsFetched,
});

export const TemplateTagging = ({ flags }: { flags: LaunchDarklyFlags }) => {
  const { loading, settings, settingsFetched, name, tagsById, saveDone }: IState = useShallowSelector(selector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const headerContext = React.useContext(HeaderContext);
  const hideNavItems = flags && flags['hide-build-ui-elements'];
  const showBreezeApp = flags && flags['add-breeze-to-apps-drawer'];
  const showPreferencesMenuItem = flags && flags['add-automatic-time-stamp-signatures-to-user-preferences'];

  React.useEffect(() => {
    if (Object.keys(tagsById).length === 0) {
      dispatch(actions.fetchTags(params.id));
    }
    headerContext.setContent(
      <NavHeader
        labelName="Template information"
        breadcrumb={{ name: 'Template Management', onClick: () => history.push('/templates') }}
        hideNavItems={hideNavItems}
        showBreezeApp={showBreezeApp}
        preferencesUrl={preferencesUrl}
        showPreferences={showPreferencesMenuItem}
        userOrigin={settings?.account.userOrigin}
        getUsersGroups={getUsersGroups}
        flags={flags}
      />
    );
    return () => {
      headerContext.setContent(null);
      dispatch(actions.reset());
    };
  }, [settingsFetched]);

  if (loading || !settings) {
    return null;
  }

  if (saveDone) {
    history.push(`/templates/${params.id}/create`);
  }

  const changeTag = (tagTypeId: string, values: string[]) => {
    dispatch(actions.changeTag(tagTypeId, values));
  };

  const changeName = (newName: string) => {
    dispatch(actions.changeName(newName));
  };

  const save = () => {
    dispatch(actions.save(params.id));
  };

  const renderTagField = (tagType: ITagType) => {
    switch (tagType.displayType) {
      case 'DROPDOWN':
        return (
          <div key={tagType.id} className={classes.shortField} data-spec="tag-field">
            <InputLabel>{tagType.name}</InputLabel>
            <Select
              variant="filled"
              value={tagsById[tagType.id]?.length ? tagsById[tagType.id][0] : undefined}
              displayEmpty
              onChange={(e: any) => changeTag(tagType.id, [e.target.value])}
            >
              {tagType.placeholderText && <ListItem disabled>{tagType.placeholderText}</ListItem>}
              {tagType.options.map((o) => (
                <ListItem key={o} value={o}>
                  {o}
                </ListItem>
              ))}
            </Select>
          </div>
        );
      case 'AUTOCOMPLETE':
        return (
          <Autocomplete
            key={tagType.id}
            options={tagType.options}
            values={tagsById[tagType.id]}
            setValues={(values) => changeTag(tagType.id, values)}
            label={tagType.name}
            placeholder={tagType.placeholderText}
            helperText={tagType.helperText}
            data-spec="tag-field"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Template information
      </Typography>
      <Paper className={classes.content}>
        <TextField
          variant="filled"
          label="Template Name"
          placeholder="Name"
          defaultValue={name}
          onBlur={(e) => changeName(e.target.value)}
        />
        {settings?.account.template?.tagging?.tagTypes.map(renderTagField)}
      </Paper>
      <div className={classes.footer}>
        <div>
          <Button color="primary" className={classes.cancelButton} onClick={() => history.push('/templates')}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={save} data-spec="tagging-save-button">
            Save & Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withLaunchDarkly(TemplateTagging);
