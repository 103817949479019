import React from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import cx from 'classnames';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useShallowSelector } from '../../lib/reduxHooks';
import * as actions from '../../store/senderBuild/actions';
import { IRootState } from '../../store';
import { colors } from '@skyslope/mache';
import { IBlock, ICopyCache, IGroups, IPageDimensions, ISelectedBlocks, ISigner } from '../../store/senderBuild/types';
import { ARROW_KEY_MOVEMENT_INCREMENT, BLOCK_TYPES, BLOCK_TYPE_KEYS, readOnlyKey } from '../../lib/constants';
import { createRandomId } from '../../lib/randomId';
import { Rnd } from 'react-rnd';
import { hex2rgba, blockMenuResize, getMultipleBlockProperties } from '../../lib/utils';
import BlockStatic from './BlockStatic';
import BlockTag from './BlockTag';
import AddIcon from '@material-ui/icons/Add';
import SignerBlockToolbar from './BlockToolbars/SignerBlockToolbar';
import { isSmallScreenMediaQuery } from '../../lib/isSmallScreen';
import { isSkySlopeMobileApp } from '../../common/utils';
import BlockActions from './BlockShelf/BlockActions';
import BlockProperties from './BlockShelf/BlockProperties';

const useStyles = makeStyles((theme: Theme) => ({
  rnd: {
    zIndex: 4,
  },
  border: {
    margin: -6,
    padding: 6,
    height: '100%',
    width: '100%',
  },
  groupPlus: {
    height: 24,
    width: 24,
    backgroundColor: colors.blue[800],
    cursor: 'pointer',
    position: 'absolute',
    top: 'calc(100% + 12px)',
    left: 'calc(50% - 12px)',
    color: '#ffffff',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    backgroundColor: colors.grey[500],
    cursor: 'wait',
  },
}));

const selector = (state: IRootState) => ({
  zoom: state.senderBuild.zoom,
  pageDimensions: state.senderBuild.pageDimensions,
  groups: state.senderBuild.groups,
  signers: state.senderBuild.signers,
  isAutoSaving: state.senderBuild.isAutoSaving,
  copyCache: state.senderBuild.copyCache,
  selectedBlockState: state.senderBuild.selectedBlocks,
  activeSigner: state.senderBuild.activeSigner,
});

interface IState {
  zoom: number;
  pageDimensions: IPageDimensions;
  groups: IGroups;
  signers: ISigner[];
  isAutoSaving: boolean;
  copyCache: ICopyCache;
  selectedBlockState: ISelectedBlocks;
  activeSigner: string;
}

interface IProps {
  documentId: string;
  pageIndex: number;
  pageBlocks: IBlock[];
  selectBlocks: (
    documentId: string,
    pageIndex: number,
    blockIndices: number[],
    multiSelect?: boolean,
    selectThroughGroup?: boolean
  ) => void;
  dontRenderBlocks: boolean;
  blocksIndices: number[];
  fullSelectedBlocks: number[];
  isBlockSignerToolbarDisabled: boolean;
  selectSigner: (e: any) => void;
}

enum PdfActions {
  drag = 'drag',
  click = 'click',
}

const SelectBox = (props: IProps) => {
  const { zoom, pageDimensions, groups, signers, isAutoSaving, copyCache, selectedBlockState, activeSigner }: IState =
    useShallowSelector(selector);
  const [disableAddBlock, setDisableAddBlock] = React.useState(false);
  const [pdfAction, setPdfAction] = React.useState<PdfActions>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { width, height } = pageDimensions[props.documentId][props.pageIndex];
  const pageHeight = height;
  const pageWidth = width;
  const currentPageDimensions = pageDimensions[props.documentId][props.pageIndex];
  const filteredSigners = signers.filter((signer) => signer.signingGroup !== '');
  const isSmallScreen = isSmallScreenMediaQuery();
  const isMobileApp = isSkySlopeMobileApp();
  const [blockMenuPos, setBlockMenuPos] = React.useState({
    x: null,
    y: null,
    position: 'bottom',
  });

  const dragMenu = (e: any, pos: any) => {
    setBlockMenuPos({
      x: pos.x,
      y: pos.y,
      position: blockMenuPos.position,
    });
  };

  const buildSelectBoxPosition = () => {
    let topLeftX = Infinity;
    let topLeftY = Infinity;
    let bottomRightX = 0;
    let bottomRightY = 0;
    props.blocksIndices.forEach((index) => {
      const block = props.pageBlocks[index];
      if (block.x! < topLeftX) {
        topLeftX = block.x!;
      }
      if (block.y! < topLeftY) {
        topLeftY = block.y!;
      }
      if (block.x! + block.width! > bottomRightX) {
        bottomRightX = block.x! + block.width!;
      }
      if (block.y! + block.height! > bottomRightY) {
        bottomRightY = block.y! + block.height!;
      }
    });
    return {
      x: topLeftX * zoom,
      y: topLeftY * zoom,
      width: (bottomRightX - topLeftX) * zoom,
      height: (bottomRightY - topLeftY) * zoom,
    };
  };

  const getSignerBlockToolBarPos = (
    selectBoxX: number,
    selectBoxY: number,
    selectBoxWidth: number,
    selectBoxHeight: number
  ) => {
    const menuWidth = document.getElementById('multipleBlockToolbar')?.offsetWidth;
    const menuHeight = document.getElementById('multipleBlockToolbar')?.offsetHeight;
    let menuPos: { x: number; y: number } = {};
    if (selectBoxX + menuWidth + 10 >= width) {
      menuPos.x = selectBoxX - menuWidth + selectBoxWidth + 10;
    } else {
      menuPos.x = selectBoxX - 10;
    }
    if (selectBoxY + selectBoxHeight + menuHeight + 14 >= height) {
      // This is a temp fix to account for the add button on checkbox groups. This can be changed when that is resolved with UX
      menuPos.y = selectBoxY - menuHeight - (isGroup ? 18 : 14);
    } else {
      // This is a temp fix to account for the add button on checkbox groups. This can be changed when that is resolved with UX
      menuPos.y = selectBoxY + selectBoxHeight + (isGroup ? 40 : 14);
    }

    return menuPos;
  };

  const [boxPosition, setBoxPosition] = React.useState(buildSelectBoxPosition);
  React.useEffect(() => {
    const selectBoxPos = buildSelectBoxPosition();
    setBoxPosition(selectBoxPos);
    setPdfAction(PdfActions.drag);
    const blockSignerMenuPos = getSignerBlockToolBarPos(
      selectBoxPos.x,
      selectBoxPos.y,
      selectBoxPos.width,
      selectBoxPos.height
    );
    setBlockMenuPos({
      x: blockSignerMenuPos.x,
      y: blockSignerMenuPos.y,
      position: blockMenuPos.position,
    });
  }, [props.pageBlocks, props.blocksIndices, zoom]);

  React.useEffect(() => {
    setDisableAddBlock(props.dontRenderBlocks);
  }, [props.dontRenderBlocks]);

  const selectedBlocks = props.blocksIndices.map((index) => props.pageBlocks[index]);
  const isGroup = selectedBlocks.every((b) => b.groupId && b.groupId === selectedBlocks[0].groupId);
  const sameSigner = selectedBlocks.every((b) => b.assignedTo && b.assignedTo === selectedBlocks[0].assignedTo);

  React.useEffect(() => {
    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key.indexOf('Arrow') === 0) {
        const inc = ARROW_KEY_MOVEMENT_INCREMENT;
        let { x, y, width, height } = boxPosition;
        if (e.key === 'ArrowLeft') {
          x = x >= inc ? x - inc : 0;
        } else if (e.key === 'ArrowRight') {
          if (x + width + inc <= pageWidth * zoom) {
            x += inc;
          } else {
            x = pageWidth * zoom - width;
          }
        } else if (e.key === 'ArrowUp') {
          y = y >= inc ? y - inc : 0;
        } else if (e.key === 'ArrowDown') {
          if (y + height + inc <= pageHeight * zoom) {
            y += inc;
          } else {
            y = pageHeight * zoom - height;
          }
        }
        setBoxPosition({ ...boxPosition, x, y });
        updateBlocks(x, y);
        setPdfAction(PdfActions.click);
      }
    };
    document.addEventListener('keyup', keyboardHandler, false);
    return () => document.removeEventListener('keyup', keyboardHandler, false);
  }, [boxPosition]);

  React.useEffect(() => {
    if (!isAutoSaving) {
      setDisableAddBlock(false);
    } else {
      setDisableAddBlock(true);
    }
  }, [isAutoSaving]);

  const [isPasteButtonDisabled, setIsPasteButtonDisabled] = React.useState(true);
  React.useEffect(() => {
    if (copyCache.blocks.length > 0) {
      setIsPasteButtonDisabled(false);
      return;
    }
    setIsPasteButtonDisabled(true);
  }, [copyCache.blocks]);

  const updateBlocks = (x: number, y: number) => {
    props.blocksIndices.forEach((index, i) => {
      const block = props.pageBlocks[index];
      const updatedX = block.x! + (x - boxPosition.x) / zoom;
      const updatedY = block.y! + (y - boxPosition.y) / zoom;
      if ((block.x && block.y && updatedX !== block.x!) || updatedY !== block.y!) {
        dispatch(
          actions.editBlock(props.documentId, props.pageIndex, index, {
            x: block.x! + (x - boxPosition.x) / zoom,
            y: block.y! + (y - boxPosition.y) / zoom,
          })
        );
      }
    });
  };

  const onDragStop = (e: any, pos: any) => {
    const blockSignerMenuPos = getSignerBlockToolBarPos(pos.x, pos.y, boxPosition.width, boxPosition.height);

    setBlockMenuPos({
      x: blockSignerMenuPos.x,
      y: blockSignerMenuPos.y,
      position: blockMenuPos.position,
    });
    if (pos.x === boxPosition.x && pos.y === boxPosition.y) {
      setPdfAction(PdfActions.click);
    }
    updateBlocks(pos.x, pos.y);
  };

  const handleAddBlockToGroup = () => {
    if (!disableAddBlock) {
      dispatch(actions.setAutoSaving(true));
      setDisableAddBlock(true);
      const { assignedTo, pageNumber, groupId } = props.pageBlocks[props.blocksIndices[0]];
      const allSelectedBlocks = props.blocksIndices.map((index) => props.pageBlocks[index]);
      const largestX = Math.max(...allSelectedBlocks.map((b) => b.x!));
      const largestY = Math.max(...allSelectedBlocks.map((b) => b.y!));
      const blockHeight = allSelectedBlocks[0].height!;
      const blockWidth = allSelectedBlocks[0].width!;
      const blockFontSize = Math.min(...allSelectedBlocks.map((b) => b.fontSize!));
      if (largestY! + blockHeight * 2 + 6 >= pageHeight) {
        return;
      }

      const block: IBlock = {
        blockId: createRandomId(),
        pageNumber: pageNumber,
        blockType: 'Checkbox',
        x: largestX,
        y: largestY! + blockHeight + 6,
        width: blockWidth,
        height: blockHeight,
        required: false,
        assignedTo: assignedTo,
        groupId: groupId,
        isEditingDisabled: false,
        fontSize: blockFontSize,
      };

      const group = groups[props.documentId][props.pageIndex][groupId!];
      dispatch(actions.addNewBlocksToGroup(group!, [block]));
    }
  };

  const selectThroughBox = (index: number) => {
    props.selectBlocks(props.documentId, props.pageIndex, [index], false, true);
  };

  const handleSelectBlocks = (e: any) => {
    if (props.fullSelectedBlocks.length !== props.blocksIndices.length) {
      props.selectBlocks(props.documentId, props.pageIndex, props.blocksIndices, true, false);
    }
  };

  const handleShiftDeselect = (shiftKey: boolean, blockIndex: number, selectThroughBox: boolean) => {
    const index = props.blocksIndices.indexOf(blockIndex);
    if (shiftKey) {
      props.blocksIndices.splice(index!, 1);
      props.selectBlocks(props.documentId, props.pageIndex, props.blocksIndices, true, true);
    }
  };

  const handleOnMouseUp = (block: IBlock, x: number, y: number, shiftKey: boolean) => {
    if (pdfAction !== PdfActions.click) {
      setPdfAction(PdfActions.click);
    } else {
      if (shiftKey) {
        const index = props.pageBlocks.findIndex((b) => b.blockId === block.blockId);
        handleShiftDeselect(true, index, false);
      } else {
        props.selectBlocks(
          props.documentId,
          props.pageIndex,
          [props.pageBlocks.findIndex((b) => b.blockId === block.blockId)],
          shiftKey,
          true
        );
      }
    }
  };

  const changeProperty = (key: string, value: any) => {
    props.blocksIndices.forEach((index, i) => {
      const block = selectedBlocks[i];
      const update = {
        [key]: value,
      };
      if (key === readOnlyKey) {
        update.assignedTo = value ? null : block.previouslyAssignedTo || activeSigner;
        if (value) {
          update.required = false;
          update.previouslyAssignedTo = block.assignedTo;
        }
      }
      dispatch(
        actions.editBlock(
          selectedBlockState.documentId!,
          selectedBlockState.pageIndex!,
          selectedBlockState.blockIndices[i],
          update
        )
      );
    });
  };

  const blockOptions = () => {
    const multipleBlockProperties = getMultipleBlockProperties(selectedBlocks);
    return (
      <div className="flex flex-col w-full">
        <BlockActions isBlockToolbar isButtonDisabled={isPasteButtonDisabled} />
        {!!multipleBlockProperties.length && (
          <BlockProperties
            data-spec="blockProperties"
            isBlockToolbar
            selectedBlocks={selectedBlocks}
            blockTypeProperties={multipleBlockProperties}
            changeProperty={changeProperty}
          />
        )}
      </div>
    );
  };

  const multipleBlockToolbar = () => {
    if (
      selectedBlocks.every(
        (block) =>
          [BLOCK_TYPE_KEYS.STRIKE, BLOCK_TYPE_KEYS.RECTANGLE].includes(block.blockType) || isSmallScreen || isMobileApp
      )
    ) {
      return '';
    }
    return (
      <Rnd
        id="multipleBlockToolbar"
        position={{
          x: blockMenuPos.x,
          y: blockMenuPos.y,
        }}
        bounds={'.PdfViewer'}
        onDrag={dragMenu}
        onDragStop={dragMenu}
        style={{ zIndex: 9999 }}
        enableResizing={blockMenuResize}
      >
        <SignerBlockToolbar
          blocks={selectedBlocks}
          isDisabled={props.isBlockSignerToolbarDisabled}
          selectSigner={props.selectSigner}
          signers={filteredSigners}
          blockOptions={blockOptions()}
        />
      </Rnd>
    );
  };

  return (
    <>
      <Rnd
        id="selectedBlocksBox"
        bounds="parent"
        className={classes.rnd}
        size={{
          width: boxPosition.width,
          height: boxPosition.height,
        }}
        position={{
          x: boxPosition.x,
          y: boxPosition.y,
        }}
        onDragStop={onDragStop}
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        disableDragging={props.dontRenderBlocks}
        onMouseDown={handleSelectBlocks}
      >
        <div
          className={classes.border}
          style={{
            backgroundColor: hex2rgba(colors.blue[100], 0.15),
            outline: `3px dashed ${colors.blue[100]}`,
          }}
        >
          {!props.dontRenderBlocks &&
            selectedBlocks.map((block, index) => {
              const signer = signers.find((signer) => signer.signerId === block.assignedTo);
              const blockType = BLOCK_TYPES.find((b) => b.key === block.blockType);
              const realBlockIndex = props.pageBlocks.findIndex((b) => b.blockId === block.blockId);
              const blockWithXY = { ...block, x: block.x! - boxPosition.x / zoom, y: block.y! - boxPosition.y / zoom };
              return (
                <BlockStatic
                  key={block.blockId}
                  block={blockWithXY}
                  blocks={props.pageBlocks}
                  blockType={blockType}
                  blockIndex={realBlockIndex}
                  zoom={zoom}
                  signer={signer!}
                  startEditing={handleShiftDeselect}
                  doubleClickSelect={block.groupId ? selectThroughBox : undefined}
                  onMouseUp={blockWithXY.blockType === BLOCK_TYPE_KEYS.CHECKBOX ? handleOnMouseUp : null}
                  pageDimensions={currentPageDimensions}
                  currentSelectedBlockIndices={props.fullSelectedBlocks}
                />
              );
            })}
        </div>
        {!props.dontRenderBlocks && (
          <BlockTag
            signer={sameSigner ? signers.find((s) => s.signerId === selectedBlocks[0].assignedTo) : undefined}
            blockType={selectedBlocks[0].blockType}
            multiSelect
            text={`${selectedBlocks.length}`}
          />
        )}
        {isGroup && (
          <div
            id="addCheckbox"
            className={disableAddBlock ? cx(classes.groupPlus, classes.disabled) : classes.groupPlus}
            onClick={handleAddBlockToGroup}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <AddIcon fontSize="small" />
          </div>
        )}
      </Rnd>
      {multipleBlockToolbar()}
    </>
  );
};

export default SelectBox;
