export enum RecipientRole {
  Signer = 'Signer',
  CarbonCopy = 'CarbonCopy',
}

export interface IRecipient {
  id: string;
  role: RecipientRole;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  group?: IGroup;
  color?: string;
  isSender?: boolean;
  title?: string;
  isPlaceholder?: boolean;
  isActive: boolean;
  recipientColorCounter: number;
}

export interface IGroup {
  id: string;
  name: string;
  order: number;
}

export interface ISigner {
  signerId: string;
  email: string;
  firstName: string;
  lastName?: string;
  signingStatus: string;
  color?: string;
  signingGroup?: string;
  role?: string;
  isActive: boolean;
  isPlaceholder: boolean;
  recipientColorCounter: number;
  title?: string;
}
export interface IRecipientBody {
  id: string;
  role: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  order?: number;
  title?: string;
}

export interface IContact {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  city: string;
  fax: string;
  isCompany: boolean;
  isShared: boolean;
  phone: string;
  state: string;
  streetName: string;
  streetNumber: string;
  zip: string;
}
