import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FileStatus, IFile } from '../../types';
import { colors } from '@skyslope/mache';

const useStyles = makeStyles((theme: Theme) => ({
  status: {
    width: 'fit-content',
    borderRadius: '4px',
  },
  statusText: {
    color: '#FFFFFF',
    padding: '0 8px',
  },
}));

interface IProps {
  file: IFile;
}

const STATUS_COLORS = {
  [FileStatus.Active]: colors.grey[800],
  [FileStatus.Expired]: '#D20004',
  [FileStatus.Incomplete]: '#6E2EB9',
  [FileStatus.Pending]: '#FFAB00',
};

export const PropertyStatus = (props: IProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.status}
      style={{ backgroundColor: STATUS_COLORS[props.file.status] }}
      data-spec="file-status"
    >
      <Typography variant="overline" className={classes.statusText}>
        {props.file.status}
      </Typography>
    </div>
  );
};

export default PropertyStatus;
